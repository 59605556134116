.setup-box .appf .column-form.column-form-compact {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  >li {
    border: none;
    width: calc(20% - 10px);
    margin-right: 10px;
    display: block;
    >label {
      border: 0px none;
      line-height: 1.2em;
      font-size: 14px;
      padding: 4px 0 2px;
      background: none;
    }
    >div {
      width: auto;
      display: block;
      
    }
    >div>div {
      background: none;
      border: none;
      margin-left: 0;
      padding: 0;
      select, input[type="text"], textarea {
        width: 100%;
        border: 1px solid #b2b2b2;
        border-radius: 0;
        padding: 0 10px;
      }
      label {
        float: none;
        margin: 0;
        width: auto;
        display: block;
        font-size: 16px;
        text-align: left;
      }
    }
    div.combo-select {
      padding: 0;
    }
  }
  >li.double {
    width: calc(40% - 10px);
  }
  >li.triple {
    width: calc(60% - 10px);
  }
  >li.quadruple {
    width: calc(80% - 10px);
  }
  >li.quintuple {
    width: calc(100% - 10px);
  }
  >li:only-child {
    width: calc(100% - 10px);
  }
  &.column-form-compact-3>li {
    width: calc(33.3333% - 10px);
    &.double {
      width: calc(66.6666% - 10px);
    }
  }
}

