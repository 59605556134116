.popup-detail {
  visibility: hidden;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 35px;
  background: rgba(0,0,0,.7);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}
.popup-detail.active {
  visibility: visible;
}
.pd-inner {
  width: 100%;
  box-shadow: 0px 0px 27px rgba(0,0,0,.18);
}
.popup-detail .title {
  background: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  padding: 30px;
}
.popup-detail .title .close {
  display: block;
  float: right;
  width: 24px;
  height: 24px;
  background: #b6b6b6;
  border-radius: 12px;
  font-size: 16px;
  color: white;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.popup-detail .table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}
.popup-detail .tables {
  padding: 20px 16px;
  background: #cecece;
}

.popup-detail .tables table {
  width: 100%;
}
.popup-detail .tables table th {
  background: black;
  font-size: 14px;
  text-align: center;
  color: white;
  padding: 20px 10px;
}
.popup-detail .tables table td {
  height: 53px;
  padding: 0 10px;
  white-space: nowrap;
  background: white;
  text-align: center;
}
.popup-detail .tables table td a {
  color: #4e8bff;
  text-decoration: underline;
}
.popup-detail .tables .table-actions {
  padding-top: 22px;
  line-height: 32px;
  padding-left: 24px;
  font-size: 16px;
}
.popup-detail .tables .table-actions .ta-btn {
  display: inline-block;
  vertical-align: middle;
  width: 84px;
  height: 32px;
  text-align: center;
  color: white;
  background: #4e8bff;
  font-weight: bold;
  font-size: 18px;
  margin-right: 2px;
  cursor: pointer;
}
.popup-detail .tables .table-actions span {
  margin-right: 30px;
}
.popup-detail .notes {
  padding: 20px 37px 35px;
  background: #b6b6b6;
  font-size: 16px;
}
.popup-detail .notes label {
  display: block;
  margin-bottom: 10px;
}
.popup-detail .notes textarea {
  background: white;
  border: 1px solid #a0a0a0;
  -webkit-apperance: none;
  margin: 10px 0;
  width: 55%;
  padding: 12px;
  min-height: 100px;
}
.popup-detail .notes button {
  -webkit-apperance: none;
  border: 0;
  height: 32px;
  text-transform: uppercase;
  padding: 0 22px;
  background: #4e8bff;
  color: white;
  cursor: pointer;
}
