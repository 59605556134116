.e403 {
  text-align: center;
  padding: 70px 50px;
  box-sizing: border-box;
}
.e403 h2 {
  font-size: 28px;
  margin-bottom: 24px;
}
.e403 p {
  font-size: 16px;
  margin-bottom: 36px;
}
.e403 .btn403 {
  font-size: 21px;
  display: inline-block;
  width: 216px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: white;
  background: #1f9400;
}

.blogo {
  text-align: center;
  margin-bottom: 12px;
}
.bback {
  display: inline-block;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  line-height: 44px;
  height: 48px;
  color: #3e3e3e;
  background: #fff;
  padding: 0 18px;
  border-radius: 5px 5px 0 0;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: -4px;
}
.bback.active {
  color: white;
  background: #3e3e3e;
}
.bback::before {
  content: "V";
  font-size: 7.5px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  transform-origin: 50% 50%;
  transform: rotate(90deg);
  margin-top: -2px;
  display: none;
}
.biller-list {
  display: flex;
  background: #e1e1e1;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  padding: 32px 38px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.biller-list>div {
  margin-right: 45px;
}
.biller-list .title {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid #c2c2c2;
  padding-bottom: 10px;
  margin-bottom: 12px;
  padding-right: 20px;
  width: 100%;  
}
.biller-list ul {
  color: #4e8bff;
  font-size: 12px;
  font-weight: 500;
  padding-left: 24px;
}
.biller-list ul li {
  margin-bottom: 8px;
}
.biller-list ul li.active a {
  display: inline-block;
  color: #1e9304;
  position: relative;
}
.biller-list ul li.active a::before {
  position: absolute;
  top: calc(50% - 5px);
  content: "";
  border-left: 4px solid #1e9304;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  left: -22px;
}
.linkcolor .acc-list table td a,
.acc-list table td a[href^="https:"] {
  color: #4e8bff;
  text-decoration: none;
}
.acc-list table td a {
  color: #4e8bff;
  text-decoration: none;
}
.acc-list table td a.btn-like {
  text-decoration: none !important;
}
.acc-list table td a.btn-like.btn-il {
  display: inline-block;
  margin: 0 1px;
  padding: 0 3px;
}
.toolbarx {
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 12px;
}
.ter-info {
  font-size: 18px;
  line-height: 26px;
}
.ter-info strong {
  font-weight: bold;
}
.f2hx>div {
  margin-right: 25%;
}
.f2h>div {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.f2h>div .field {
  width: 23%;
  display: block;
}
.f2h>div .field label {
  display: block;
  width: 100%;
  margin-bottom: 8px;
}
.f2h>div .field input,
.f2h>div .field select {
  display: block;
  width: 100%;
}
.ncontent h3 label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}
.ncontent h3 label input {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.search-form .field textarea {
  width: 66%;
  min-height: 120px;
}
.dashboard .value.emph {
  font-size: 1.1em;
  color: #1f9400;
}
.spacep {
  margin-bottom: 24px;
  margin-top: -12px;
}
.custom-width {width: 1300px}