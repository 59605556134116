.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7);
  visibility: hidden;
  z-index: 9999;
  opacity: 0;
  transition: .2s;
}
.loading.visible {
  visibility: visible;
  opacity: 1;
}

.loading .progress {
  visibility: hidden;
  color: white;
  position: absolute;
  top: 10%;
  right: 50%;
  width: 400px;
  margin-right: -200px;
  text-align: center;
}
.loading.progress .progress {
  visibility: visible;
}
.loading .progress-bar {
  background: #333;
  width: 388px;
  margin-top: 30px;
  height: 30px;
  border-radius: 22px;
  padding: 5px;
  border: 2px solid #aaa;
  
}
.loading .progress-bar .bar {
  height: 30px;
  width: 0;
  background: #357bba;
  border-radius: 15px 0 0 15px;
}
