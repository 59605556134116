.biller-list .title-set-box {
  background: #d2d2d2;
  flex-grow: 1;
  margin-top: -32px;
  margin-bottom: -32px;
  margin-right: -38px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.title-set-box .tsb-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-set-box .tsb-sep-main {
  flex-grow: 1;
}
.title-set-box .tsb-sep-sec {
  flex-shrink: 0;
}
.title-set-box .title {
  border-bottom: 0;
  padding-bottom: 0;
}
.title-set-box .btn {
  display: block;
  font-weight: 500;
  text-align: center;
  color: white;
  font-size: 14px;
  width: 190px;
  height: 34px;
  line-height: 34px;
  background: #1f9400;
  border-radius: 4px;
}
.title-set-box label {
  font-size: 12px;
  display: inline-block;
}
.title-set-box label input[type="radio"], .title-set-box label input[type="checkbox"] {
  display: none;
}
.title-set-box label.main {
  background: transparent;
  font-weight: bold;
  margin-right: 4px;
}
.title-set-box label input[type="radio"]+span, .title-set-box label input[type="checkbox"]+span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: white;
  border: 1px solid #337dff;
  position: relative;
  margin-top: -2px;
  cursor: pointer;
}
.title-set-box label input[type="radio"]:checked+span::after, .title-set-box label input[type="checkbox"]:checked+span::after {
  content: "";
  position: absolute;
  border-radius: 2px;
  background: #337dff;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
}
.title-set-box select {
  height: 22px;
  vertical-align: middle;
  font-size: 12px;
}
.title-set-box .tsb-form .row {
  margin-bottom: 5px;
}
.title-set-box .tsb-sep-main.split {
  display: flex;
  align-items: center;
}
.title-set-box .tsb-sep-main.split>div {
  width: 59%;
  margin-right: 1%;
}
.title-set-box .tsb-sep-main.split>div:first-of-type {
  width: 41%;
}
.title-set-box .info {
  font-size: 11px;
  margin-top: -8px;
}
.title-set-box .info.alert {
  font-size: 12px;
  font-weight: bold;
  color: red;
  margin-top: 24px;
}
.title-set-box label input {
  width: 160px;
  height: 22px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
}


.scart-container {
  margin: 0px auto;
  max-width: 1140px;
  min-height: 30px;
  background: white;
}
.scart-head {
  height: 140px;
  background: #e9e9e9;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  box-sizing: border-box;
}
.scart-head .companylogo {
  display: block;
  margin-right: 70px;
  max-width: 280px;
}
.scart-head .cinfo {
  width: 320px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 24px;
}
.scart-head .cinfo li {
  width: 49%;
  margin-right: 1%;
}
.scart-head .cinfo li.name {
  font-weight: bold;
}
.scart-head .cinfo li.email::before, .scart-head .cinfo li.web::before {
  display: inline-block;
  content: "";
  width: 11px;
  height: 11px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
}
.scart-head .cinfo li.email::before {
  background-image: url("../img/ico-ema.png");
}
.scart-head .cinfo li.web::before {
  background-image: url("../img/ico-web.png");
}
.scart-head .backstripe {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  background: #0065e5;
  width: 240px;
  height: 62px;
  color: white;
  text-align: center;
  line-height: 62px;
  position: absolute;
  top: 16px;
  right: -10px;
}
.scart-head .backstripe::after {
  border-top: 5px solid #002451;
  border-left: 5px solid #002451;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
}
.scart-head .backstripe::before {
  border-top: 31px solid transparent;
  border-right: 22px solid #0065e5;
  border-bottom: 31px solid transparent;
  content: "";
  position: absolute;
  left: -22px;
  top: 0px;
}
.scart-foot {
  padding: 55px 64px;
  background: #0065e5;
  color: white;
}
.scart-foot .copy {
  text-align: right;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
}
.scart-content {
  padding: 64px 44px;
  background: white;
}
.scart-content h1 {
  font-size: 52px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.scart-content .addmore {
  display: block;
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  color: #363636;
  height: 50px;
  line-height: 50px;
  padding: 0 60px;
  border: 3px solid #a7a7a7;
  border-radius: 5px;
}
.scart-content .addmore::after {
  content: "+";
  font-weight: bold;
  margin-left: 16px;
}
.scart-table {
  margin-bottom: 20px;
}
.scart-table .title {
  background: black;
  color: white;
  border-radius: 8px 8px 0 0;
  height: 80px;
  display: flex;
}
.scart-table .title>div {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 25px 30px;
  line-height: 30px;
  text-align: center;
  box-sizing: border-box;
}
.scart-table .row>div:nth-of-type(1),
.scart-table .title>div:nth-of-type(1) {
  width: 36%;
  text-align: left;
}
.scart-table .row>div:nth-of-type(2),
.scart-table .title>div:nth-of-type(2) {
  width: 19%;
}
.scart-table .row>div:nth-of-type(3),
.scart-table .title>div:nth-of-type(3) {
  width: 19%;
}
.scart-table .row>div:nth-of-type(4),
.scart-table .title>div:nth-of-type(4) {
  width: 26%;
}
.scart-table .subtitle {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  line-height: 25px;
  background: #0065e5;
  color: white;
}
.scart-table .row {
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
  color: #3c3c3c;
  overflow: hidden;
}
.scart-table .row:last-child {
  border-radius: 0px 0px 8px 8px;
}
.scart-table .row>div {
  font-size: 26px;
  padding: 25px 30px;
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
}
.scart-table .row>div label {
  display: none;
}
.scart-table .row input {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  text-align: center;
  margin-right: 4px;
}
.scart-table .row>div:nth-of-type(2),
.scart-table .row>div:nth-of-type(3),
.scart-table .row>div:nth-of-type(4) {
  font-size: 22px;
  font-weight: 600;
}

.scart-table .row>div:nth-of-type(1),
.scart-table .row>div:nth-of-type(3) {
  background: #ebebeb;
}
.scart-table .row .inc, .scart-table .row .dec {
  width: 32px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
}
.scart-table .row .inc {
  border-radius: 0px 8px 8px 0;
  background: #c6c6c6;
}
.scart-table .row .dec {
  border-radius: 8px 0px 0px 8px;
  background: #d2d2d2;
}
.scart-table .row span {
  width: 80px;
  display: block;
  float: right;
  margin: -25px -31px -25px 0;
  height: 90px;
  background: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scart-table .row span a {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  background: #b6b6b6;
  border-radius: 5px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  font-size: 15px;
}
.scart-table .row span a::before {
  content: "x";
}
.scart-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
.scart-promo {
  
}
.scart-promo label {
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}
.scart-promo div {
  padding: 1px;
  border: 1px solid #d2d2d2;
  color: #575757;
  font-size: 14px;
}
.scart-promo input {
  border: 0;
  -webkit-apperance: none;
  outline: none;
  padding: 0;
  padding: 0 4px;
  height: 38px;
  background: white;
  width: 258px;
}
.scart-promo button {
  border: 0;
  -webkit-apperance: none;
  outline: none;
  padding: 0;
  height: 38px;
  width: 120px;
  background: #d0d0d0;
  text-transform: uppercase;
}
.scart-total {
  width: 304px;
}
.scart-total .items {
  background: #e9e9e9;
  overflow: hidden;
  border-radius: 5px;
  padding-top: 8px;
  margin-bottom: 8px;
}
.scart-total .items li {
  display: flex;
  text-align: right;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 16px;
}
.scart-total .items li.total {
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 12px;
  background: #e0e0e0;
}
.scart-total .items li.total span {
  font-weight: bold;
}
.scart-total .items li label {
  width: 56%;
  display: block;
}
.scart-total .items li span {
  width: 44%;
  display: block;
  text-transform: uppercase;
}
.review .checkout,
.scart-total .checkout {
  border: 0;
  -webkit-apperance: none;
  outline: none;
  padding: 0;
  height: 55px;
  width: 100%;
  display: block;
  background: #0064e5;
  text-transform: uppercase;
  font-size: 21px;
  border-radius: 5px;
  margin-bottom: 8px;
  color: white;
  cursor: pointer;
  position: relative;
}
.review .checkout {
  margin-bottom: 32px;
}
.scart-total .addmore {
  padding: 0;
  text-align: center;
}
.paypg-container .scart-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}
.paypg .express-checkout {
  padding: 50px 40px 140px;
}
.paypg .express-checkout .ex-title {
  text-align: center;
  margin-bottom: 30px;
}
.paypg .express-checkout .ex-title h1 {
  display: block;
  margin-bottom: 0;
}
.paypg .express-checkout .ex-title div {
  font-size: 16px;
  color: #8d8d8d;
}
.exco-trisplit {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.exco-trisplit>div {
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  width: 33.33333%;
  padding-top: 33px;
  padding-bottom: 33px;
  flex-grow: 0;
}
.exco-trisplit>div.address, .exco-trisplit>div.review {
  overflow: hidden;
  border: 1px solid #d2d2d2;
  background: #efefef;
  border-radius: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.paypg h2 {
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.paypg h2 span {
  color: #357bbd;
  margin-right: 8px;
}
.exco-form .row {
  margin-bottom: 8px;
}
.exco-form .row.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exco-form .row.split>div {
  width: calc(50% - 5px);
}
.exco-form .row.split2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exco-form .row.split2>div:first-of-type {
  width: calc(70% - 5px);
}
.exco-form .row.split2>div:last-of-type {
  width: calc(30% - 5px);
}
.exco-form label {
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  color: #6e6e6e;
}
.exco-form label.req::after {
  content: "*";
  margin-left: 4px;
  color: red;
}
.exco-form select,
.exco-form input {
  min-width: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  height: 43px;
  padding: 0 8px;
  font-size: 14px;
  background: white;
  border: 1px solid #d2d2d2;
}
.exco-form .split select {
  height: 28px;
}
.exco-form label.check {
  font-weight: 600;
  margin: 16px 0;
}
.exco-form label input {
  display: none;
}
.exco-form label input+span {
  display: inline-block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #b7b7b7;
  background: white;
  margin-right: 6px;
  position: relative;
}
.exco-form label input:checked+span::before {
  content: "";
  width: 6px;
  height: 6px;
  background: black;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 3px;
}
.exco-form label.srad {
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  background: #e6e6e6;
  border-radius: 5px;
  margin-bottom: 2px;
}
.exco-form label.srad .spr {
  float: right;
  font-weight: bold;
}
.exco-form .link-right {
  text-align: right;
  color: #2260b7;
  font-size: 12px;
  font-weight: bold;
}
.review .order-list {
  margin: 20px 0;
}
.review .order-list li {
  margin-bottom: 12px;
}
.review .order-list li .name {
  font-size: 16px;
  margin-bottom: 5px;
}
.review .order-list li .ol-row {
  display: flex;
  justify-content: space-between;
}
.review .order-list li .quantity {
  overflow: hidden;
}
.review .order-list li .quantity input {
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
  float: left;
  margin-right: 3px;
}
.review .order-list li .quantity a {
  display: inline-block;
  width: 20px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #3c3c3c;
  display: block;
  float: left;
}
.review .order-list li .quantity a.dec {
  background: #d2d2d2;
  border-radius: 4px 0 0 4px;
}
.review .order-list li .quantity a.inc {
  background: #c6c6c6;
  border-radius: 0 4px 4px 0;
}
.review .order-list li .price {
  color: #3c3c3c;
  font-weight: 600;
}
.review .order-list li .price .rem {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #b6b6b6;
  color: white;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 18px;
  margin-top: -1px;
}
.review .trans-statement {
  font-size: 12px;
  text-align: center;
  max-width: 260px;
  margin: 20px auto;
  line-height: 18px;
  color: #3c3c3c;
}
.review .trans-statement strong {
  font-weight: 600;
}
.review .order-total {
  border-radius: 6px;
  overflow: hidden;
  margin: 24px 0;
  position: relative;
}
.review .order-total li {
  height: 32px;
  color: white;
  display: flex;
  text-align: right;
  background: #494949;
  font-size: 12px;
  line-height: 32px;
  padding: 0 14px;
}
.review .order-total li label {
  display: block;
  width: 52%;
}
.review .order-total li span {
  display: block;
  width: 52%;
}
.review .order-total li.total {
  padding: 10px 14px;
  background: black;
  text-transform: uppercase;
  font-size: 16px;
}
.review .order-total li.total span {
  font-weight: 600;
}
.review .order-box {
  border-bottom: 1px solid #d3d3d3;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.review .order-box::before {
  content: "";
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e0e0e0+0,e0e0e0+100&0+0,1+100 */
background: -moz-linear-gradient(top,  rgba(224,224,224,0) 0%, rgba(224,224,224,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(224,224,224,0) 0%,rgba(224,224,224,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(224,224,224,0) 0%,rgba(224,224,224,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e0e0e0', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-9 */

}
.supp-box {
  margin: 24px 0;
}
.paypg-container h3 {
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 3px;
  font-weight: bold;
  border-bottom: 2px solid #d3d3d3;
  margin-bottom: 12px;
}
.supp-box .supp-phone {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background: #d4d4d4;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 18px;
}
.supp-box .supp-phone .suppt {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
}
.supp-box .supp-phone a {
  color: #0065e5;
}
.supp-box .supp-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.supp-box .supp-info li {
  margin-right: 20px; 
}
.supp-box .supp-info label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
}
.policy-box {
  color: #0065e5;
  font-size: 12px;
  background: white;
  text-align: center;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -32px;
  padding: 24px 0;
}
.policy-box a {
  text-decoration: underline;
  display: inline-block;
  margin: 0 6px;
}
