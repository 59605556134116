.pricing-template {
  h2 {
    font-size: 12px;
    background: black;
    border: 0;
    color: white;
    padding: 10px 22px;
    height: auto;
    line-height: 1.2;
    label {
      vertical-align: middle;
      input {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  .pricing-list {
    margin: 5px 0 15px;
    display: flex;
    flex-wrap: wrap;
    >li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      /*width: 25%;*/
      &:last-child {
        margin-bottom: 0;
      }
      >label {
        font-size: 12px;
        font-weight: bold;
        min-width: 16.6666%;
        flex-grow: 0;
        >input[type=checkbox],
        >input[type=radio] {
          vertical-align: middle;
          margin-right: 6px;
          height: 18px;
        }
      }
      >.details {
        margin-top: 10px;
        margin-left: 16.6666%;
      }
      >.details>ul,
      >ul {
        flex-grow: 1;
        display: flex;
        >li {
          width: 100%;
          display: flex;
          font-size: 14px;
          width: 25%;
          >label {
            font-size: 12px;
            margin-right: 6px;
            min-width: 95px;
            >input[type=checkbox],
            >input[type=radio] {
              vertical-align: middle;
              margin-right: 6px;
              height: 18px;
            }
          }
          input[type=text] {
            width: 70px;
            vertical-align: middle;
            border: 1px solid #ccc;
            border-right-width: 0px;
            border-radius: 0;
            padding: 2px 0;
            height: 20px;
            text-align: right;
            padding-right: 4px;
            padding-left: 4px;
          }
          .prefix {
            height: 18px;
            line-height: 18px;
            border: 1px solid #ccc;
            border-right-width: 0;
            border-radius: 4px 0 0 4px;
          }
          .prefix+input[type=text] {
            border-left: 0;
          }
          input[type=text]:first-child,
          label+input[type=text] {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          input[type=text]:last-child {
            border-right-width: 1px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          .suffix {
            height: 18px;
            line-height: 18px;
            border: 1px solid #ccc;
            border-left-width: 0;
            border-radius: 0 4px 4px 0;
          }
          .suffix-label {
            height: 20px;
            line-height: 20px;
            border: 0px solid #ccc;
            border-left-width: 1px;
            padding-left: 4px;
          }
/*           margin-left: 10px; */
        }
      }
      >div.details {
        width: 100%;
      }
    }
    &.pl2>li {
      width: 50%;
      >label {
        min-width: 33.3333%;
      }
    }
    &.pl3>li {
      width: 33.3333%;
      >label {
        min-width: 50%;
      }
    }
    &.madon {
      li > ul > li {
        width: auto;
        margin-right: auto;
      }
      li > ul > li > label {
        width: auto;
        min-width: 0;
      }
    }
  }
  li {
    border: 0px none;
    margin: 0;
  }
  .fee-list {
    margin: 5px 0 15px;
    display: flex;
    flex-wrap: wrap;
    &.fl3>li {
      width: 33.3333%;
      >label {
        font-size: 12px;
        min-width: 50%;
      }
    }
    >li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      font-size: 14px;
      >label {
        font-size: 16px;
        font-weight: bold;
        min-width: 20%;
        flex-grow: 0;
        >input[type=checkbox],
        >input[type=radio] {
          vertical-align: middle;
          margin-right: 6px;
          height: 18px;
        }
      }
      input[type=text] {
        width: 70px;
        vertical-align: middle;
        border: 1px solid #ccc;
        border-right-width: 0;
        border-radius: 0;
        border-left-width: 0;
        padding: 2px 0;
        height: 20px;
        text-align: right;
        padding-right: 4px;
        padding-left: 4px;
      }
      .prefix {
        height: 18px;
        line-height: 18px;
        border: 1px solid #ccc;
        border-right-width: 0;
        border-radius: 4px 0 0 4px;
      }
      input[type=text]:last-child {
        border-radius: 0 4px 4px 0;
        border-right-width: 1px;
      }
      .suffix {
        height: 18px;
        line-height: 18px;
        border: 1px solid #ccc;
        border-left-width: 0;
        border-radius: 0 4px 4px 0;
        background: #eee;
        padding: 0 4px;
      }
    }
  }
  .fee-types {
    display: flex;
    >div {
      width: 33.33333%;
      >h3 {
        font-weight: bold;
        font-size: 16px;
        margin-top: 10px;
        height: auto;
        background: transparent;
        color: #303030;
        line-height: initial;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 15px;
      }
      >ul>li>label {
        font-size: 12px;
        min-width: 50%;
/*         margin-right: 6px; */
      }
    }
  }
}

.appf .form-step section .pricing-template h2 {
  font-size: 12px;
  background: #eee;
  border: 0;
  color: black;
  padding: 10px 22px;
  height: auto;
  line-height: 1.2;
  label {
    vertical-align: middle;
    input {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}
