.ncontent.manage-head {
  margin: 20px auto 15px;
}

.company-select {
  position: relative;
  box-sizing: border-box;
  margin: 90px auto -50px;
  max-width: calc(100% - 50px);
  width: 1100px;
  text-align: center;
}

.company-branding h1 {
  margin-bottom: 20px;
  color: white;
  line-height: 32px;
  padding: 6px 15px 4px;
  position: relative;
  background: #4e8bff;
  background: #555555;
  font-size: 18px;
  font-weight: bold;
}
