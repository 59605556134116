.merchant-panel {
  display: flex;
  
}
.merchant-panel .data-content {
  background: #f0f0f0;
  border-top: 8px solid #e1e1e1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.merchant-panel .data-content .tabs {
  display: flex;
  padding: 20px 20px 15px;
}
.merchant-panel .data-content .tabs-smaller {
  display: flex;
  padding: 0px 20px 15px;
}
.merchant-panel .data-content .tabs+.tabs {
  padding-top: 0;
  margin-top: -6px;
}
.merchant-panel .data-content .tabs li {
  cursor: pointer;
  margin-right: 8px;
  color: black;
  font-size: 16px;
  display: block;
  padding: 6px 20px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e4e4e4+0,cfcfcf+100 */
background: rgb(228,228,228); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(228,228,228,1) 0%, rgba(207,207,207,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#cfcfcf',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .tabs-smaller li.disabled {
  cursor: default !important;
}
.merchant-panel .data-content .tabs-smaller li.disabled:hover,
.merchant-panel .data-content .tabs-smaller li {
  cursor: pointer;
  margin-right: 6px;
  color: black;
  font-size: 14px;
  display: block;
  padding: 6px 10px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e4e4e4+0,cfcfcf+100 */
background: rgb(228,228,228); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(228,228,228,1) 0%, rgba(207,207,207,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#cfcfcf',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .tabs li:hover {
  background: #e1e1e1;  
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f2ff+0,deebff+100 */
background: rgb(233,242,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(233,242,255,1) 0%, rgba(222,235,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f2ff', endColorstr='#deebff',GradientType=0 ); /* IE6-9 */
  border-color: #6192fc;
  color: #6192fc;
}
.merchant-panel .data-content .tabs-smaller li:hover {
  background: #e1e1e1;  
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f2ff+0,deebff+100 */
background: rgb(233,242,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(233,242,255,1) 0%, rgba(222,235,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f2ff', endColorstr='#deebff',GradientType=0 ); /* IE6-9 */
  border-color: #6192fc;
  color: #6192fc;
}
.merchant-panel .data-content .tabs li.active:hover,
.merchant-panel .data-content .tabs li.active {
  background: #357bba;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3774ff+0,4c8cff+100 */
background: rgb(55,116,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(55,116,255,1) 0%, rgba(76,140,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(55,116,255,1) 0%,rgba(76,140,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(55,116,255,1) 0%,rgba(76,140,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3774ff', endColorstr='#4c8cff',GradientType=0 ); /* IE6-9 */
  border-color: transparent;
}
.merchant-panel .data-content .tabs-smaller li.active:hover,
.merchant-panel .data-content .tabs-smaller li.active {
  background: #357bba;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3774ff+0,4c8cff+100 */
background: rgb(55,116,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(55,116,255,1) 0%, rgba(76,140,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(55,116,255,1) 0%,rgba(76,140,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(55,116,255,1) 0%,rgba(76,140,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3774ff', endColorstr='#4c8cff',GradientType=0 ); /* IE6-9 */
  border-color: transparent;
}
.merchant-panel .data-content .main {
  flex-grow: 1;
  background: #e7e7e7;
  margin: 0 20px 15px;
  min-height: 100px;
}
.merchant-panel .data-content .main .status {
  margin: 10px;
  background: white;
  padding: 15px 0px;
  text-align: center;
  position: relative;
}
.merchant-panel .data-content .main .status>span {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 110px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a6a6a6+0,838383+100 */
background: rgb(166,166,166); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(166,166,166,1) 0%, rgba(131,131,131,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(166,166,166,1) 0%,rgba(131,131,131,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(166,166,166,1) 0%,rgba(131,131,131,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6a6a6', endColorstr='#838383',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .main .status>span:first-of-type {
  right: 110px;
}
.merchant-panel .data-content .main .status>span.ago12 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4fff4f+0,3aff3a+100 */
background: rgb(79,255,79); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(79,255,79,1) 0%, rgba(58,255,58,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(79,255,79,1) 0%,rgba(58,255,58,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(79,255,79,1) 0%,rgba(58,255,58,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fff4f', endColorstr='#3aff3a',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .main .status>span.ago24 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffff4f+0,ffff3a+100 */
background: rgb(255,255,79); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,79,1) 0%, rgba(255,255,58,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,79,1) 0%,rgba(255,255,58,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,79,1) 0%,rgba(255,255,58,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffff4f', endColorstr='#ffff3a',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .main .status>span.ago {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff4d4d+0,ff3a3a+100 */
background: rgb(255,77,77); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,77,77,1) 0%, rgba(255,58,58,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,77,77,1) 0%,rgba(255,58,58,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,77,77,1) 0%,rgba(255,58,58,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff4d4d', endColorstr='#ff3a3a',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .data-content .main .status>em {
  position: absolute;
  height: 45px;
  top: 0;
  left: 0;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a6a6a6+0,838383+100 */
background: rgb(166,166,166); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(166,166,166,1) 0%, rgba(131,131,131,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(166,166,166,1) 0%,rgba(131,131,131,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(166,166,166,1) 0%,rgba(131,131,131,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6a6a6', endColorstr='#838383',GradientType=0 ); /* IE6-9 */
  width: 150px;
  text-align: center;
  font-size: 14px;
}
.merchant-panel .data-content .main .status>em i {
  font-style: normal;
  display: block;
  font-size: 10px;
  padding-top: 8px;
}
.merchant-panel .data-content .main .status strong {
  font-weight: bold;
}
.merchant-panel .data-content .main form {
  margin: 10px;
}
.merchant-panel .data-content .main form h2 {
  margin: 15px 16px 5px;
  font-weight: bold;
}
.merchant-panel .actions {
  width: 40%;
  background: #d8d8d8;
  flex-shrink: 0;
}
.merchant-panel .actions .tabs {
  background: black;
  color: white;
  padding: 12px 12px 5px;
  justify-content: flex-start;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 5px solid #357bba;
  position: relative;
}
.merchant-panel .actions .tabs::after {
  position: absolute;
  content: "";
  display: block;
  border-top: 8px solid black;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: 100%;
  left: calc(50% - 8px);
}
.merchant-panel .actions .tabs li {
  cursor: pointer;
  padding: 10px 6px;
  margin-top: -2px;
  margin-left: -1px;
  border-radius: 4px;
  border: 1px solid #999;
  background: #151515;
  box-sizing: border-box;
  width: calc(25% - 5px);
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  position: relative;
}
.merchant-panel .actions .tabs li:hover {
  background: #393939;
  border-color: #727272;
}
.merchant-panel .actions .tabs li.active:hover,
.merchant-panel .actions .tabs li.active {
  border: 3px solid #568cff;
  background: #1362ff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.merchant-panel .actions .tabs li.active::before {
  content: "";
  border-top: 3px solid white;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  display: block;
  left: calc(50% - 3px);
  bottom: 4px;
}

.merchant-panel .actions .main {
  margin: 15px 20px;
}
.merchant-panel .actions .action-buttons {
  display: flex;
  flex-wrap: wrap;
  background: #357bba;
  margin: -15px -20px 15px;
  padding: 22px 25px 10px;
  font-size: 11px;
}
.merchant-panel .actions .action-sub-buttons a,
.merchant-panel .actions .action-buttons a {
  display: block;
/*   background: #357aba; */
  padding: 0px 0px;
  text-decoration: underline;
  color: white;
  margin-right: 8px;
  margin-bottom: 12px;
  transition: .3s;
}
.merchant-panel .actions .action-sub-buttons a {
  text-decoration: none;
  padding: 8px 20px;
  border: 1px solid black;
  border-radius: 3px;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#323232+0,050505+100 */
background: rgb(50,50,50); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(50,50,50,1) 0%, rgba(5,5,5,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323232', endColorstr='#050505',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .actions .action-sub-buttons a:hover {
  border-color: #357bba;
  color: #357bba;
  background: #d1d1d1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,d1d1d1+100 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(209,209,209,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(209,209,209,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(209,209,209,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d1d1d1',GradientType=0 ); /* IE6-9 */

}
.merchant-panel .actions .action-sub-buttons a:hover,
.merchant-panel .actions .action-buttons a:hover {
/*   background: rgba(0,0,0,.2); */
/*   box-shadow: 0px 0px 0px 1px black; */
}
.merchant-panel .actions .action-sub-buttons a.disabled:hover,
.merchant-panel .actions .action-buttons a.disabled:hover,
.merchant-panel .actions .action-sub-buttons a.disabled,
.merchant-panel .actions .action-buttons a.disabled {
  cursor: normal;
  pointer-events: none;
/*   background: rgba(0,0,0,.2); */
/*   background: #80b9ed; */
/*   box-shadow: 0px 0px 0px 1px white; */
  color: #6fa3d2;
}
.merchant-panel .actions .action-sub-buttons {
  display: flex;
  flex-wrap: wrap;
  background: rgba(0,0,0,0.1);
  margin: 0 -20px 15px;
  padding-left: 5px;
  padding-top: 5px;
}
.merchant-panel .actions .action-buttons + div>.action-sub-buttons {
  margin-top: -15px;
}
.merchant-panel .actions .action-sub-buttons a {
/*  background: transparent;
  color: black;
  outline-color: #000;
  margin-top: 4px;*/
}
.merchant-panel .actions .file-field {
  padding: 5px 10px;
  background: white;
}
.merchant-panel .actions .file-field a {
  font-weight: bold;
}
.action-sub-buttons + .content-table.file-list {
  margin: 0 -20px;
  width: calc(100% + 40px);
}
.content-table.file-list .close {
  color: red;
  font-size: 18px;
}
.content-table.file-list input {
  width: 16px;
}
.content-table.file-list th {
  height: 24px;
  font-size: 13px;
  text-align: left;
  padding: 4px 5px;
}
.content-table.file-list td {
  text-align: left;
  padding: 4px 5px;
/*   font-size: 13px; */
  height: auto;
}

.content-table.file-list td a[href] {
  color: #1d4366;
}
.merchant-panel .actions .main {
  font-size: 12px;
}
.merchant-panel .actions .content-table-container {
  margin: -15px -20px;
}
.merchant-panel .actions .content-table-container.file-list-container {
  border: 0px none;
}
.merchant-panel .actions .content-table-container.file-list-container td:first-of-type {
  border-left: 0px none;
}
.merchant-panel .actions .content-table-container.file-list-container td:last-of-type {
  border-right: 0px none;
}
.merchant-panel .data-content .main {
  font-size: 12px;
}
.merchant-panel .preq-form li .validator {
  line-height: 26px;
}


.merchant-panel .columns {
  display: flex;
  margin: 0 -8px;
}
.merchant-panel .column {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 8px;
}
.merchant-panel .preq-form  .column li > label {
  padding-left: 5px;
}


.notes-list {
  padding: 20px 0 0;
}
.notes-list .toolbar a {
  display: inline-block;
  margin-left: 10px;
  line-height: 20px;
  padding: 0 5px;
}
.notes-list .toolbar a.current {
  color: white;
  background: #357bba;
}
.notes-list h4 {
  font-weight: bold;
}
.notes-list ul {
  margin-top: 15px;
}
.notes-list ul li {
  display: block;
  overflow: hidden;
  padding: 4px 0;
}
.notes-list ul li:hover {
  background: rgba(0,0,0,0.1);
}
.notes-list ul li .link {
  color: #004eff;
}
.notes-list ul li a {
  float: right;
  margin: 0 0 5px 15px;
  width: 20px;
  text-align: center;
  color: red;
}
.notes-list .new-note textarea {
  width: 100%;
  padding: 10px;
  resize: vertical;
  display: block;
  margin-bottom: 8px;
}
.notes-list .new-note button {
  border: 0px none;
  display: inline-block;
  margin: 2px 0;
  color: white;
  text-transform: uppercase;
  background: #367abb;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  font-size: 12px;
}

.form-actions {
  margin: 20px 0 15px;
}

.preq-form.preq-view li {
  flex-direction: column;
}
.preq-form.preq-view li:first-of-type label,
.preq-form.preq-view li:last-of-type label,
.preq-form.preq-view li label {
  width: 100%;
  line-height: 16px;
  min-height: 18px;
  font-weight: bold;
  background: white;
  border-bottom: 0px none;
}
.preq-form.preq-view li:first-of-type>div,
.preq-form.preq-view li>div {
  width: 100%;
  border-top: 0px none;
  line-height: 18px;
  padding-left: 5px;
  background: white;
}
.preq-form .column-form .head>div {
  display: flex;
}
.preq-form .column-form .head>div>div {
  width: 238px;
  width: calc(100% - 5px);
  margin-left: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  background: black;
  color: white;
  border-color: black;
}
.preq-form .column-form label {
  flex-shrink: 0;
}
.preq-form .column-form .field.cols {
  display: flex;
  flex-grow: 1;
}
.preq-form .column-form .field.cols>div {
  width: 238px;
  width: calc(100% - 5px);
  margin-left: 5px;
}
.preq-form .column-form .business-length {
  display: flex;
}
.merchant-panel .preq-form li.title {
  background: black;
  color: white;
  padding: 2px 5px;
}
.merchant-panel .preq-form li.title label,
.merchant-panel .preq-form li.title .validator {
  background: none;
  line-height: 1em;
  padding-top: 0;
  padding-bottom: 0;
  border: 0px none;
  min-height: 0;
}
.merchant-panel .preq-form li.title a.remove {
  float: right;
  background: red;
  width: 20px;
  margin: -2px -5px;
  padding: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.merchant-panel .preq-form a.new {
  display: block;
  cursor: pointer;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 30px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  color: #1b4f00;
  background: #91e863;
  line-height: 34px;
  text-align: center;
}

.t2col {
  display: flex;
  justify-content: space-between;
}
.t2col>div {
  width: 47.5%;
}
.t2col div.field {
  opacity: 0;
}
.t2col div.field.visible {
  opacity: 1;
  transition: .5s;
}
.t2col div.field .empty {
  background: yellow;
}
.t2col div.field .duplicate {
  background: #FAB75B;
  background: #FA8072;
}
.t2col label {
  color: rgba(0,0,0,0.5);
}


.twocol {
  display: flex;
  justify-content: space-between;
}
.twocol>div {
  width: 47.5%;
}
