.biller-list > div.buttons.tools {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 45px);
  margin-bottom: -10px;
  margin-top: 20px;
}
.biller-list > div.buttons.tools a {
  display: block;
  background: #357aba;
  color: white;
  padding: 8px 20px;
  font-size: 18px;  
}
.biller-list > div.buttons.tools a.active {
  background: #1a3d5d;
}
.vterm-container {
  border-radius: 6px;
  background: white;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  box-shadow: 0px 4px 6px rgba(0,0,0,.2);
  border: 1px solid #d6d6d6;
}
.tvt-head {
  padding: 40px 35px;
  font-family: 'Open Sans', sans-serif;
}
.tvt-head h2 {
  font-weight: 300;
  font-size: 38px;
  margin-bottom: 12px;
}
.tvt-head h3 {
  font-weight: 700;
  font-size: 18px;
}
.tools-acc-container {
  background: #e0e0e0;
  padding: 20px;
}
.preq-form .tools-acc li {
  display: block;
}
.preq-form .tools-acc li ul li {
  display: flex;
}
.tools-acc {
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 3px 4px rgba(0,0,0,.2);
}
.tools-acc .head {
  display: flex;
  padding: 10px 26px;
  background: #505050;
  color: white;
  line-height: 38px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0px 3px 4px rgba(0,0,0,.2);
  margin-bottom: 20px;
  justify-content: flex-start;
  position: relative;
}
.tools-acc li:first-of-type .head {
  border-radius: 0px 0px 4px 4px;
}
.tools-acc .head .toggle {
  display: block;
  position: absolute;
  right: 26px;
  top: 10px;
  font-size: 30px;
  font-weight: 700;
}
.tools-acc .head .toggle em {
  display: none;
}
.tools-acc .open .head .toggle em {
  display: inline-block;
}
.tools-acc .head .toggle strong {
  display: inline-block;
}
.tools-acc .open .head .toggle strong {
  display: none;
}
.tools-acc .head>span {
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.tools-acc .head>span em {
  font-weight: normal;
  font-style: italic;
}
.preform .tools-acc > li ul {
  padding: 0px 30px 10px;
}
.preq-form .tools-acc li:last-of-type label, .preq-form .tools-acc li:last-of-type .validator {
  border: none;
}
.preq-form .tools-acc li .head label {
  margin-left: 24px;
}
.preq-form .tools-acc li ul label em {
  color: #9d9d9d;
}
.preq-form .tools-acc li .head label input {
  display: none;
}
.preq-form .tools-acc li .head label input+em {
  display: inline-block;
  margin-right: 16px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #aaaaaa;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  border-radius: 6px;
  position: relative;
}
.preq-form .tools-acc li .head label input:checked+em::before {
  content: "";
  position: absolute;
  background: #2c2c2c;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  top: 2px;
  left: 2px;
}
.preform .tools-acc ul.preform-sp3.single-item {
  
}
.preform .tools-acc ul.preform-sp3.single-item li {
  max-width: 320px;
}
.preform .tools-acc ul.preform-sp3.single-item li .validator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preform .tools-acc .addp {
  display: block;
  margin-left: 12px;
  overflow: hidden;
  text-indent: -10000px;
  width: 32px;
  height: 28px;
  border-radius: 16px;
  background: #61964b;
  position: relative;
}
.preform .tools-acc .addp::before, .preform .tools-acc .addp::after {
  position: absolute;
  content: "";
  background: white;
}
.preform .tools-acc .addp::before {
  width: 16px;
  height: 4px;
  left: calc(50% - 8px);
  top: calc(50% - 2px);
}
.preform .tools-acc .addp::after {
  height: 16px;
  width: 4px;
  top: calc(50% - 8px);
  left: calc(50% - 2px);
}
.vt-prod-table {
  margin: 24px;
  width: calc(100% - 48px);
  font-size: 14px;
  border: 1px solid #e3e3e3;
}
.vt-prod-table th {
  background: #e3e3e3;
  font-size: 12px;
  padding: 4px 16px;
}
.vt-prod-table td {
  line-height: 24px;
  padding: 2px 16px;
}
.vt-prod-table th:last-of-type,
.vt-prod-table td:last-of-type {
  text-align: right;
  width: 140px;
}
.vt-prod-table .btn.rem {
  display: inline-block;
  height: 24px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: 108px;
  background: #5e9d44;
}
.vt-prod-table tr:nth-of-type(2n+1) td {
  background: #ededed;
}
.vt-prod-table tr.spacer td {
  height: 3px;
  background: white;
}
.preform .preq-form .preform-sp3.vt-fixed-w-row {
  justify-content: flex-start;
}
.preform .preq-form .preform-sp3.vt-fixed-w-row>li {
  width: 190px;
  flex-grow: 0;
  margin-right: 24px;
  margin-left: 0;
}
.tools-acc-container .btn-lg {
  width: 150px;
  height: 42px;
  border: 0;
  border-radius: 3px;
  color: white;
  background: #1f9400;
  margin-top: 28px;
  margin-bottom: 28px;
  cursor: pointer;
  font-size: 18px;
}
.preq-form .tools-acc li ul li.long {
  width: 30%;
  margin: 0 1%;
}
.preq-form .tools-acc li ul li.long:first-of-type {
  margin-left: 0;
}
.preq-form .tools-acc li ul li.norm {
  width: 15%;
  margin: 0 1%;
}
.preq-form .tools-acc li ul li.small {
  width: 10%;
  margin-left: 1%;
}
.preq-form .tools-acc li ul li.mselect {
  width: 17%;
  margin: 0 1%;
}
.preq-form .tools-acc li ul li.mselect .validator {
  display: flex;
}
.preq-form .tools-acc li ul li.mselect .validator select {
  margin-right: 1%;
}
.preq-form .tools-acc li ul li.long label em {
  font-size: 11px;
  font-weight: normal;
}
.preq-form .tools-acc li ul li.long label input {
  display: none;
}
.preq-form .tools-acc li ul li.long label input+span {
  display: inline-block;
  margin-right: 8px;
  margin-left: 10px;
  margin-top: -1px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #aaaaaa;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  border-radius: 6px;
  position: relative;
}
.preq-form .tools-acc li ul li.long label input:checked+span::before {
  content: "";
  position: absolute;
  background: #2c2c2c;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  top: 2px;
  left: 2px;
}
.biller-list.nof {
  display: block;
}
.biller-list .terminal-id {
  font-size: 14px;
  font-weight: 500;
}
