.main-sidebar-container {
  
}
.main-sidebar-container .logo {
  position: relative;
  margin: 0 auto 25px;
  width: 230px;
  height: 97px;
}
.main-sidebar-container .logo a {
  display: block;
  width: 230px;
  height: 97px;
  overflow: hidden;
  text-indent: -10000px;
  background: url("../img/logo.png") no-repeat 50% 50%;
  background-size: contain;
}
.main-sidebar-container .userinfo {
  font-size: 28px;
  padding: 20px 10px;
  text-align: center;
  background: #ebebeb;
  margin: 25px 0px;
}
.main-sidebar-container .userinfo>span {
  color: #1f9400;
  display: inline-block;
}
.main-sidebar-container .userinfo ul {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.main-sidebar-container .userinfo ul label {
  font-weight: bold;
}
.main-sidebar-container .userinfo .profile-actions {
  font-size: 18px;
  color: #c6c6c6;
}
.main-sidebar-container .userinfo .profile-actions a {
  position: relative;
  margin-left: 10px;
}
.main-sidebar-container .userinfo .profile-actions a::before {
  content: "|";
  color: #c6c6c6;
  pointer-events: none;
  position: absolute;
  left: -10px;
  top: -3px;
}
.main-sidebar-container .userinfo .profile-actions a:first-of-type {
  margin-left: 0;
}
.main-sidebar-container .userinfo .profile-actions a.active {
  color: #4e8bff;
}
.main-sidebar-container .userinfo .profile-actions a:first-of-type::before {
  display: none;
}
.side-menu>li {
  margin-bottom: 15px;
}
.side-menu>li>a {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  padding-left: 30px;
  position: relative;
}
.side-menu>li>a::after {
  content: "▾";
  position: absolute;
  top: 0;
  right: 15px;  
  color: #ccc;
  transition: .3s;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
}
.side-menu>li.open>a::after {
  transform: rotate(180deg);
}
.side-menu>li>ul {
  font-size: 14px;
  display: none;
}
.side-menu>li>ul>li {
/*   height: 29px; */
  line-height: 29px;
  position: relative;
}
.side-menu>li>ul>li:nth-of-type(2n+1) {
  background: #eee;
}
.side-menu>li ul li a {
  display: block;
  padding-left: 65px;
  position: relative;
}
.side-menu>li ul li a::before {
  position: absolute;
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  top: 9px;
  left: 30px;
  background: url("../img/arrowr.png") no-repeat;
}
.side-menu>li ul li.active a {
  background: #4e8bff;
  color: #fff;
}
.side-menu>li ul li.active a::before {
  left: auto;
  right: 20px;
  filter: brightness(0) invert(1);
}
.side-menu>li>ul>li>ul {
  display: none;
}
.side-menu>li>ul>li>ul>li>a {
  padding-left: 80px;
}
.side-menu>li>ul>li>ul>li>a::before {
  left: 46px;
}
.version-info {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.main-sidebar .manage a {
  display: block;
  margin: 10px auto;
  width: 35px;
  height: 35px;
  background: url("../img/plug-dark.png") no-repeat 50% 50% / contain;
}
