.updates-table {
  margin: 30px 0px 10px;
}
.updates-table table {
  border-collapse: collapse;
}
.updates-table table tr td:last-of-type,
.updates-table table tr td {
  width: auto;
  border-bottom: 1px dotted black;
  padding: 2px 5px;
  height: 24px;
  font-size: 14px;
  border-radius: 0;
}
.updates-table table tr td em {
  font-weight: bold;
  color: #444;
}
.updates-table table tr td strong {
  font-weight: bold;
}
.updates-table table tr:last-of-type td {
  border-bottom: 0px none;
}

.table-btn em {
  display: inline-block;
  margin-left: 5px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  background: white;
  color: red;
}
