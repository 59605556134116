.multi-dropdown {
  border: 1px solid #ccc;
  background: white;
  border-radius: 4px;
  position: relative;
  .value {
    cursor: default;
    display: block;
    // width: 280px;
    box-sizing: border-box;
    padding: 2px 4px;
    position: relative;
    height: 24px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50% - 10px);
      width: 13px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
      background-image: url("../img/arrow-down.png");
      background-repeat: no-repeat;
      background-position: center right;
    }
  }
  >ul {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -1px;
    border: 1px solid #ccc;
    background: white;
    overflow-y: scroll;
    min-width: 280px;
    max-height: 500px;
    
    li label {
      padding: 2px 4px;
      display: flex;
      align-items: center;
      input {
        //position: absolute;
        left: 0;
        top: 0;
        //opacity: 0;
        height: 20px;
      }
      input + span {
        width: 0;
      }
      input + span + span {
        margin-left: 5px;
        width: 0;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.pricing-template .multi-dropdown {
  flex-grow: 1;
  .value {
    width: auto;
  }
}
