.opt-cont {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 35px;
}
.opt-cont .opt-head {
  padding-bottom: 1px;
}
.opt-head h3 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.opt-setboxes {
  display: flex;
  padding: 0 30px;
}
.opt-setboxes li {
  border: 1px solid #c4c4c4;
  width: 50%;
  margin-left: 14px;
  background: #e6e6e6;
  box-sizing: border-box;
}
.opt-setboxes li:first-of-type {
  margin-left: 0;
}
.opt-setboxes li .osb-head {
  height: 70px;
  line-height: 70px;
  background: black;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.opt-setboxes li .osb-head span {
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
}
.opt-setboxes li .osb-body {
  padding: 28px;
  max-width: 180px;
  margin: 0 auto;
  min-height: 200px;
}
.osb-body .value,
.osb-body label {
  display: block;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}
.osb-body .value {
  font-size: 14px;
}
.osb-body .value.emph {
  font-size: 32px;
  margin: 12px 0;
  color: #31a000;
}
.osb-body strong {
  font-weight: bold;
}
.osb-body .current {
  font-size: 12px;
  font-weight: normal;
}
.osb-body form {
  margin-top: 28px;
}
.osb-body form.opts-f2 {
  margin-top: 12px;
}
.osb-body form label {
  color: #848484;
}
.osb-body form.opts-f2 label {
  margin-top: 8px;
}
.osb-body .select {
  position: relative;
  font-size: 14px;
  height: 24px;
  box-sizing: border-box;
  background: #f4f4f4;
  border: 1px solid #b5b5b5;
  margin: 6px 0 28px;
}
.osb-body .select select {
  opacity: 0;
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 22px;
  width: 100%;
}
.osb-body .select span {
  display: block;
  line-height: 22px;
  height: 22px;
  padding: 0 32px 0 12px;
  position: relative;
}
.osb-body .select span::before,
.osb-body .select span::after {
  position: absolute;
  content: "";
}
.osb-body .select span::before {
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 20px;
  background: #aeaeae;
  transition: .3s;
}
.osb-body .select span::after {
  top: calc(50% - 2px);
  right: 7px;
  border-top: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.osb-body .select:hover span::before {
  background: #888;
}
.osb-btns {
  text-align: center;
}
h4.osb-btns {
  text-align: left;
}
.osb-btns a,
.osb-btns select,
.osb-btns button {
  -webkit-apperance: none;
  outline: none;
  border: 1px solid #bcbcbc;
  display: inline-block;
  width: 80px;
  height: 26px;
  box-sizing: border-box;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fefefe+0,dfdfdf+100 */
background: rgb(254,254,254); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(254,254,254,1) 0%, rgba(223,223,223,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dfdfdf',GradientType=0 ); /* IE6-9 */
  cursor: pointer;
}
.ncontent.opt-flat {
  background: transparent;
/*   box-shadow: none; */
  border: none;
}
.ncontent.opt-flat .content {
  padding: 0;
}
.opt-flat .acc-results li h4::after {
  display: none;
}
.opt-flat .acc-results li h4 {
  border-radius: 4px;
}
.opt-flat .acc-results li h4 span {
  font-size: 14px;
  font-weight: 500;
}
.opt-flat .acc-list table th {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.opt-flat .no {
  color: #d30000;
}
.opt-flat .yes {
  color: #00d300;
}
td.osb-btns a {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  color: black !important;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 8px;
}
.osb-activate {
  height: 120px;
  display: flex;
  text-align: center;
  align-items: center;
}
.osb-activate span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}
