.tags {
  display: inline-block;
  vertical-align: top;  
}

.tags li {
  display: inline-block;
  vertical-align: middle;
  background-color: #4299e1;
  color: #fff;
  padding: 0 10px;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  border-radius: 25px;
}
.tags li .close {
  display: inline-block;
  vertical-align: top;
  margin: 4px 0 4px 10px;
  width: 18px;
  height: 17px;
  line-height: 1;
  text-align: center;
  background-color: #2e70a7;
  border-radius: 10px;
  cursor: pointer;
}
.tags li .close:hover {
  background-color: #1f415d;
}
.tags.small li {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 0 5px;
}
.tags.small li .close {
  width: 14px;
  height: 14px;
  margin: 3px 0 3px 5px;
}