@import "node_modules/vue-vanilla-datetime-picker/dist/DateTimePicker";
.content-filters {
  .tsm-handle {
    display: none;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: bold;
    background: #1f2d4e;
    color: white;
    min-width: 120px;
    text-align: center;
    line-height: 29px;
    @media screen and (max-width: 760px) {
      display: block;
      float: right;
    }
  }
  .cof-form {
    @media screen and (max-width: 760px) {
      display: none;
      position: fixed;
      overflow-y: auto;
      background: white;
      padding: 20px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &.open {
        display: block;
      }
      .cof-close {
        position: absolute;
        top: 22px;
        right: 20px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        &::before, &::after {
          width: 18px;
          height: 1px;
          top: 50%;
          transform-origin: 50% 50%;
          left: calc(50% - 9px);
          content: "";
          position: absolute;
          background: #616f7b;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .cof-box {
    padding: 16px;
    margin: 15px 30px;
    background: #f8f9fb;
    border-radius: 16px;
    @media screen and (max-width: 1200px) {
      margin: 15px 10px;
    }
  }
  .cof-row {
    display: flex;
    margin-top: 10px;
    /*@media screen and (max-width: 760px) {
      flex-wrap: wrap;
    }*/
    @media screen and (max-width: 1300px) {
      flex-wrap: wrap;
    }
  }
  .cof-field {
    margin-right: 16px;
    @media screen and (max-width: 760px) {
    
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      &.mobinl {
        width: auto;
        margin-right: 16px;
      }
    }
    @media screen and (max-width: 1300px) {
      margin-bottom: 10px;
    }
    >input {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .cof-multi {
    span {
      font-size: 13px;
      font-weight: bold;
      color: #768594;
      margin: 0 8px 0 4px;
    }

    &.flex {
      display: flex;
      align-items: center;
    }
  }
  h4 {
    color: #1f2d4e;
    font-size: 16px;
    margin-bottom: 10px;
    @media screen and (max-width: 760px) {
      line-height: 29px;
    }
  }
  h6 {
    display: none;
    color: #1f2d4e;
    @media screen and (max-width: 760px) {
      display: block;
      padding-bottom: 14px;
      border-bottom: 1px solid #99aabb;
    }
  }
  label {
    font-weight: bold;
    font-size: 12px;
    color: #768594;
    margin-bottom: 4px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    outline: none;
    border: 1px solid #99aabb;
    height: 29px;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    background: white;
    &.datepicker {
      width: 120px;
    }
  }
  select {
    display: block;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    padding: 0 28px 0 8px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #99aabb;
    border-radius: 6px;
    height: 29px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;

    background-image: url("../img/arrowd1.svg");
    background-position: right .7em top 50%, 0 0;
    background-repeat: no-repeat;
    /* icon size, then gradient */
    background-size: .65em auto, 100%;
    
    &::-ms-expand {
        display: none;
    }
    &:hover {
        border-color: #888;
    }
    &:focus {
        border-color: #aaa;
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222; 
        outline: none;
    }
  }
  .error {
    input, select {
      border-color: red;
    }
  }
}

.drawer {
  .cof-field {
    label {
      display: block;
      margin-bottom: 5px
    }
    input, select {
      width: 100%;
    }
  }
  .cof-field + .cof-field {
    margin-top: 20px;
  }
}