.risk-calculator {
  
}

.risk-calculator>h3 {
  border: 1px solid #ccc;
  background: #e7e7e7;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 10px;
  font-weight: bold;
  font-family: inherit;
  line-height: 25px;
  color: black;
}

.risk-calculator>p {
  border: 1px solid #ccc;
  background: #e7e7e7;  
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 10px;
  line-height: 25px;
  font-weight: bold;
}

.risk-calculator>ul {
}

.risk-calculator>ul>li {
  border: 1px solid #ccc;
  background: #e7e7e7;
  margin-bottom: 10px;
  font-size: 14px;
}

.risk-calculator>ul>li>h4 {
  line-height: 25px;
  margin-left: 10px;
  font-weight: bold;
}

.risk-calculator>ul>li>ul {
  border-top: 1px solid #ccc;
}

.risk-calculator>ul>li>ul:first-child {
  border-top: 0px none;
}

.risk-calculator>ul>li>ul>li {
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.risk-calculator>ul>li>ul>li:first-child {
  border-top: 0px none;
}

.risk-calculator>ul>li>ul>li label {
  width: 75%;
}

.risk-calculator>ul>li>ul>li input {
  text-align: right;
  width: 25%;
  border: 0;
  border-left: 1px solid #ccc;
  border-radius: 0;
  background: white;
  color: black;
}
