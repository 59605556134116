.newappsp {

}
.newappsp .new-app-title {
  padding: 40px 20px;
  text-align: center;
}
.newappsp .new-app-title h1 {
  font-weight: bold;
  font-size: 54px;
/*   margin-bottom: 10px; */
  line-height: 60px;
}
.newappsp .new-app-title p {
  font-weight: 300;
  font-size: 27px;
}
.newappsp .new-app-split {
  background: #f0f0f0;
  display: flex;
  padding: 40px 8.64% 1px;
  justify-content: space-between;
  align-items: stretch;
}
.newappsp .new-app-split>div {
  background: white;
  box-sizing: border-box;
  width: calc(33% - 15px);
  padding: 32px 30px 35px;
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
  border-radius: 6px;
  border: 1px solid rgba(107, 107, 107, .23);
  display: flex;
  flex-direction: column;
}
.newappsp .new-app-split.two-way-split>div {
  width: calc(50% - 15px);
}
.newappsp .new-app-split>div .btn.btn-large {
  min-width: 100%;
  box-sizing: border-box;
}
.newappsp .new-app-split>div>div {
  flex-grow: 1;
}
.newappsp .new-application-note {
  background: #f0f0f0;
  padding: 40px 20px 44px;
  text-align: center;
  font-size: 14px;
  color: #8d8d8d;
}
.newappsp .new-app-split h2 {
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 8px;
}
.newappsp .new-app-split p {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  margin-bottom: 16px;
}
.merchant-app-desc {text-align: center!important;}
.newappsp .new-app-split .btn-large {
  display: block;
  text-align: center;
  height: 55px;
  line-height: 55px;
  font-size: 18px;
}
.newappsp .new-app-split>div:last-of-type .btn-large {
  background: #ca8545;
}

.newappsp .new-app-split select {
  display: block;
  margin: 15px auto 20px;
  background: transparent;
  border: 1px solid #ccc;
}

.solution-select {
  margin-top: 30px;
}
.solution-select label {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
}

