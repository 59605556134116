.dash-row {
  display: flex;
  margin: 0 -15px;
}
.dash-row + .dash-row {
  margin-top: 30px;
}
.dash-row .col {
  padding: 0 15px;
  flex-grow: 1;
  width: calc(33.3333% - 30px);
  flex-shrink: 1;
  flex-grow: 0;
}
.dash-row .col table + table {
  margin-top: 30px;
}
.dashboard {
  margin: auto;
  padding: 20px;
  max-width: 1080px;
  background: white;
  margin-top: 90px;
  box-sizing: border-box;
  box-shadow: 3px 3px 9px rgba(0,0,0,.1);
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px 50px 50px;
}
.dashboard h2 {
  font-size: 38px;
  margin: 10px 0 24px;
}
.dashboard .breakdown-table th {
  height: 42px;
  font-size: 18px;
  font-weight: bold;
  line-height: 42px;
  text-transform: uppercase;
}
.dashboard .breakdown-table th.spacer {
  height: 10px;
}
