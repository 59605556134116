.custom-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.custom-form .input-group {
  width: calc((100% - 110px) / 3);
  margin: 10px 0;
}
.custom-form .input-group.w25 {
  width: calc((100% - 90px) / 4);
}
.custom-form .input-group.w20 {
  width: calc((100% - 120px) / 4);
}
.custom-form .input-group.small {
  width: 80px;
}
.custom-form .input-group.medium {
  width: 180px;
}
.custom-form .input-group.large {
  width: 300px;
}

.custom-form .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  width: 100%;
}

.custom-form h3.subtitle {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  margin-bottom: 10px;
}

.custom-form .phone-input {
  display: flex;
}
.custom-form .phone-input .ext {
  width: 70px;
  margin-left: 5px;
  flex-shrink: 0;
}
.custom-form.inline-form {
  flex-wrap: nowrap;
  flex-direction: column;
}
.custom-form .input-group input, .custom-form .input-group select {
  display: block;
  width: 100%;
}
.custom-form.inline-form .input-group {
  display: block;
}
.custom-form.inline-form .input-group label, .custom-form.inline-form .input-group input, .custom-form.inline-form .input-group select {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
.custom-form.inline-form .input-group label {
  margin-right: 20px;
}

.clear-after::after {
  display: block;
  content: '';
  clear: both;
}

input, select {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 10px;
  border: 1px solid #b2b2b2;
  height: 30px;
  outline: none;
}
input:focus, select:focus {
  border-color: #3774ff;
}