.nc-flat {
  border: none;
  box-shadow: none;
  background: transparent;
}
.up-acc {
  font-family: "Montserrat", sans-serif;
}
.up-acc>li {
  overflow: hidden;
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-bottom: 20px;
}
.up-acc .title {
  line-height: 38px;
  padding: 10px 28px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  background: #505050;
  cursor: pointer;
}
.up-acc>li .content {
  background: white;
  display: none;
  padding-top: 0;
  padding-bottom: 1px;
}
.up-acc>.open {
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
}
.up-acc>.open .content {
  display: block;
}
.up-acc>.open .title {
  font-size: 18px;
  background: #4f85e4;
  padding-top: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.up-acc>.open .acc-list {
  display: block;
}

.up-acc .general {
  background: #4f85e4;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 0 -30px 55px;
  padding: 0 28px 26px;
}
.up-acc .ui-prop {
  display: flex;
  margin-bottom: 10px;
}
.up-acc .ui-prop li {
  margin-right: 50px;
}
.up-acc .ui-prop li label {
  font-weight: 500;
  font-size: 11px;
}
.up-acc .ui-prop li div {
  font-weight: bold;
  font-size: 21px;
}
.tool-btn {
  display: inline-block;
  border: 1px solid #577bbb;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fefefe+0,dfdfdf+100 */
background: rgb(254,254,254); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(254,254,254,1) 0%, rgba(223,223,223,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dfdfdf',GradientType=0 ); /* IE6-9 */

  color: #4f85e4;
  font-size: 11px;
  font-weight: 500;
  height: 27px;
  line-height: 27px;
  padding: 0 12px;
}
.up-acc .general aside {
  font-size: 14px;
  text-align: right;
}
.up-acc .general aside label {
  font-weight: bold;
}
.up-acc .general aside div {
  margin-bottom: 6px;
}
.mo-info {
  
}
.mo-info>li {
  margin-bottom: 60px;
  font-size: 14px;
}
.mo-info>li>ul {
  display: flex;
  flex-wrap: wrap;
}
.mo-info>li>ul>li {
  width: 50%;
  line-height: 24px;
  margin-bottom: 6px;
  display: flex;
}
.mo-info>li>ul>li label {
  font-weight: bold;
  width: 38%;
  display: block;
}
.mo-tabs {
  display: flex;
  margin-bottom: 48px;
}
.mo-tabs li {
  margin-right: 6px;
}
.mo-tabs li a {
  display: block;
  width: 133px;
  height: 44px;
  box-sizing: border-box;
  border: 1px solid #bbbbbb;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6e6e6+0,cfcfcf+100 */
background: rgb(230,230,230); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(230,230,230,1) 0%, rgba(207,207,207,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(230,230,230,1) 0%,rgba(207,207,207,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(230,230,230,1) 0%,rgba(207,207,207,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#cfcfcf',GradientType=0 ); /* IE6-9 */
  color: black;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 42px;
  border-radius: 10px;
/*   transition: .2s; */
}
.mo-tabs li a:hover {
  color: #4f85e4;
  border-color: #4f85e4;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f3f3f3+0,e9e9e9+100 */
background: rgb(243,243,243); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(243,243,243,1) 0%, rgba(233,233,233,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(243,243,243,1) 0%,rgba(233,233,233,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(243,243,243,1) 0%,rgba(233,233,233,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#e9e9e9',GradientType=0 ); /* IE6-9 */
}
.mo-tabs li.active a {
  border-color: transparent;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3774ff+0,4c8dff+100 */
background: rgb(55,116,255); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(55,116,255,1) 0%, rgba(76,141,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(55,116,255,1) 0%,rgba(76,141,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(55,116,255,1) 0%,rgba(76,141,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3774ff', endColorstr='#4c8dff',GradientType=0 ); /* IE6-9 */
  color: white;
}
