.page-header { padding: 30px 30px 0 30px; box-sizing: border-box; }
.page-header h1 { font-size: 25px; color: #236aec; margin-bottom: 5px; }
.page-header ul.breadcrumbs { list-style: none;}
.page-header ul.breadcrumbs li { font-size: 14px; line-height: 17px; text-transform: uppercase; margin-right: 10px; font-weight: normal; }
.page-header ul.breadcrumbs li::before { display: none; }
.page-header ul.breadcrumbs li a { color: #111; }
.page-header ul.breadcrumbs li a:hover, .page-header ul.breadcrumbs li a:focus { color: #236aec; }
.page-header ul.breadcrumbs li span { color: #000; }
.page-header ul.breadcrumbs i { margin-right: 5px; width: 10px; height: 17px; display: inline-block; vertical-align: top; background-image: url("../img/breadcrumb-arrow.png"); background-repeat: no-repeat; background-position: center; }

.submenu { padding: 0 0px; box-sizing: border-box; display: flex; justify-content: center; width: 100%; margin-bottom: 30px; background: white; }

.submenu .back { display: inline-block; vertical-align: top; padding: 0 15px; height: 50px; background-color: #fff; border-radius: 0px; border: none; border-right: 1px solid #f0f0ef; /*border-left: 1px solid #f0f0ef;*/ outline: none; font-size: 13px; line-height: 50px; text-transform: uppercase; color: #a0a0a0; margin-right: 0px; color: #000; }
.submenu .back i { margin-right: 5px; width: 13px; height: 50px; display: inline-block; vertical-align: top; background-image: url("../img/back-button-icon.png"); background-repeat: no-repeat; background-position: center left; }
.submenu .back:hover, .submenu .back:focus { color: #236aec; cursor: pointer; }
.submenu .back:hover i, .submenu .back:focus i { background-position: center right; }

.submenu .menu { display: inline-block; vertical-align: top; background-color: #fff; height: 50px; border-radius: 0px 5px 5px 0px; }
.submenu .menu li { display: inline-block; border-right: 1px solid #f0f0ef; position: relative; }
.submenu .menu li ul { max-height: 0; overflow: hidden; display: block; position: absolute; top: 50px; right: 0; z-index: 99; transition: all ease .4s; }
.submenu .menu li span, .submenu .menu li a { font-size: 16px; display: inline-block; color: #111; height: 50px; line-height: 50px; padding: 0 20px; }
.submenu .menu li span:hover { color: #000; cursor: pointer; }
.submenu .menu li a:hover { color: #236aec; cursor: pointer; }
.submenu .menu li span i { margin-left: 10px; width: 13px; height: 50px; display: inline-block; vertical-align: top; background-image: url("../img/arrow-down.png"); background-repeat: no-repeat; background-position: center left; }
.submenu .menu li:hover span i { background-position: center right; }

.submenu .menu li:hover ul { max-height: 500px; padding: 5px 0;  }
.submenu .menu li .arrow-up { display: none; width: 15px; height: 9px; background-image: url("../img/dropdown-arrow-up.png"); position: absolute; z-index: 100; top: 47px; right: 15px; }
.submenu .menu li:hover .arrow-up { display: block; }
.submenu .menu li ul li { display: block; background-color: #fff; border: 1px solid #f0f0ef; border-top: none; text-align: right; }
.submenu .menu li ul li:first-of-type { border-top: 1px solid #f0f0ef; border-top-left-radius: 5px; border-top-right-radius: 5px; }
.submenu .menu li ul li:last-of-type { border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }
.submenu .menu li ul li a { height: 35px; line-height: 35px; white-space: nowrap; }
