.whitebackground {
  background: #fff;
}
.leftrightcontent {
  width:320px;
  height:13px;
  display:flex;
  flex-wrap: wrap;
}
.leftrightcontent:nth-of-type(2) {
  padding-left: 20px;
}
.leftrightcontent>label {
  font-weight: 900;
  padding-right: 32px;
  font-size: 12px;
}
.preq-form .light-grey {
  background: #f0f0f0;
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
}

.merchant-panel .preq-form .appview-list li.title {
  padding: 10px 5px;
}
.appview-list {
  box-sizing: border-box;
  padding-top: 22px;
  background:white;
  display: flex;
  flex-wrap: wrap;
  margin:0;
  padding:0;
  padding-top: 12px;
}
.main {
  background: #f0f0f0;
  padding-bottom:;
}
.appview-list:last-of-type {
  padding-bottom: 20px;
}
.appview-list li.title {
  width: 100%;
  margin-top: -12px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.appview-list li {
  padding-left: 12px;
  width: 20%;
  box-sizing: border-box;
  margin-bottom:20px;
  display: block;
}
.appview-list.appl4 li {
  width: 25%;
}
.appview-list li .validator {
  width: 100%;
  background: white;
  border: 0;
  line-height: auto;
}
.appview-list li label {
  float: none;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  border: 0;
  padding: 0;
  background: transparent;
  line-height: 15px;
  margin-bottom: 3px;
  min-height: auto;
}
.appview-list li:first-of-type .validator,
.appview-list li:last-of-type .validator,
.appview-list li:first-of-type label,
.appview-list li:last-of-type label {
  border: 0;
}
.merchant-panel .preq-form .appview-list li .validator {
  line-height: normal;
  padding-left: 0;
}
.greyborder {

  padding:8px;
  background: #e7e7e7;
}
.column ul li {
  display: flex;
  justify-content:;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.column  {
  width:252px;
  padding-left:;
  display:flex;
  margin-top:10px;
}
.column ul {
    background:#ffff ;
    width:100%;
}
.column h4 {
  font-weight: 900;
}
.column ul li label {
  display:flex;
}

.preq-form preq-view section .columns {
  padding:0;
}
.grbackground {
  padding:10px;
  background: #e7e7e7;
}


.single-field .datesandtime ul li label,
.single-field .datesandtime ul li time,
.single-field .datesandtime ul li span  {
  font-weight: 900;
  font-size: ;
}
.single-field .datesandtime ul li span {
  color:#004eff;
}
.single-field .datesandtime ul li {
  margin-bottom: 5px;

}
.toolbar-three-row {
  float:right;
  margin-right: 15px;
  min-width:168px;
  height:30;
  border:1px solid grey;
  border-radius: 6px;
  display:flex;
  justify-content: center;
  overflow: hidden;
}
.toolbar-three-row .btnclick {
  width:56px;
  height:100%;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}



.border {
	border:1px solid #dedede;
}
.firstsection {
	display:flex;
	height:50px;
	background: #f0f0f0;
}
.firstsection .leftright {
	width:320px;
	display: flex;
	align-items: center;
	font-size: 12px;

}
.leftright label {
	font-weight: 900;
}
.leftright div {

}
.whitepage {
  background: #ffff;
	display: flex;
	flex-direction: row;
	padding-top: 20px;
}
.longrow-inwhite-section {
	width:155x;
	background: yellow;
	margin-left: 25px;
}
.longrow-inwhite-section h4 {
	font-weight: 900;
	font-size: 12px;
}

.merchant-panel .data-content .main form h2.list-heading {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0px;
  margin-top: 12px;
  background: black;
  color: white;
  padding: 4px 12px;
}
.preq-form .ext-cform {
  margin-top: 12px;
}
.preq-form .ext-cform li {
  background: white;
}
.preq-form .ext-cform li > label {
  width: 25%;
  background: white;
}
.preq-form .ext-cform li.head {
  font-size: 12px;
}
.preq-form .ext-cform li.head > label {
  background: black;
  color: white;
  font-weight: normal;
  padding-top: 5px;
}
.preq-form .ext-cform li.head > div {
  flex-grow: 1;
}
.preq-form .ext-cform li.head > div > div {
  margin-left: 0;
}
.preq-form .ext-cform li > label {
  border: 0 !important;
}
.preq-form .ext-cform li .field.cols > div {
  background: white;
  margin-left: 0;
  border: 0;
  border-bottom: 1px solid #ccc;
}
.preq-form .ext-cform li > label {
  font-weight: bold;
  padding-left: 12px;
}
.preq-form .ext-cform li .field.cols > div:nth-of-type(2n+1) {
  background: #eaeaea;
}


.preq-form .appl-notes {
  background: white;
  position: relative;
  border: 1px solid #ddd;
}
.preq-form .notes-list.appl-notes {
  margin-top: 12px;
  padding-top: 0;
}
.preq-form .appl-notes .toolbar {
  position: absolute;
  top: 35px;
  right: 35px;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 3px 3px 4px rgba(0,0,0,0.2);
}
.preq-form .appl-notes .toolbar a { 
  padding: 5px 10px;
  margin-left: 0;
  margin-right: 0;
  
}
.preq-form .appl-notes .toolbar a.current {
  background: #417dff;
  color: white;
}
.preq-form .appl-notes ul {
  min-height: 60px;
  max-height: 300px;
  overflow: auto;
  margin: 2px;
}
.preq-form .appl-notes ul li {
  padding: 2px 10px;
}
.preq-form .appl-notes {
  margin-top: 12px;
}
.preq-form .appl-notes .notes-props {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: white;
}
.preq-form .appl-notes .notes-props em {
  font-weight: bold;
}
.preq-form .appl-notes h4 {
  background: black;
  color: white;
  padding: 5px 5px;
}
.preq-form .appl-notes .note-text,
.preq-form .appl-notes textarea {
  min-height: 100px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  border: 0px none;
  padding: 20px;
}
.preq-form .appl-notes textarea {
  min-height: 200px;
}

.preq-form .appl-notes .new-note {
  background: #e7e7e7;
  margin: 0 -1px -1px;
  border-top: 1px solid #ddd;
  padding-top: 5px;
}
.preq-form .appl-notes .new-note textarea {
  margin: 0 0 10px;
  border: 1px solid #ddd;
  padding: 15px;
  height: 100px;
  min-height: 0;
}
.preq-form.preq-view li > .validator,
.preq-form.preq-view li > label {
  border: 0;
}
.appview-list .multiselect li {
  width: 100%;
}
.appview-list select {
  width: 100%;
  border: 1px solid rgba(0,0,0,.2);
  background: white;
}
.merchant-panel .preq-form li.title a.remove {
  margin-right: 5px;
}
.merchant-panel .data-content .tabs li {
  text-align: center;
}
.preq-form.preq-view li .f2split {
  border-color: transparent;
}
.appl-nd {
  display: flex;
  flex-wrap: wrap;
  background: white;
}
.appl-nd .f2split {
/*   display: block; */
  width: 50%;
}
.appl-nd .f2split>div {
  width: 50%;
  display: block;
  padding: 5px 8px;
  margin-bottom: 12px;
}
.appl-nd .f2split>div label {
  width: 100%;
  background: transparent;
  border-color: transparent;
  padding: 0;
  line-height: auto;
  margin-bottom: 6px;
  font-weight: bold;
  text-transform: uppercase;
}
.appl-nd .f2split>div label span {
  display: block;
  font-weight: normal;
  text-transform: none;
  margin-top: -3px;
}
.preq-form.preq-view li .element-toggle label {
  flex-wrap: wrap;
}
.preq-form .appl4 li .element-toggle label span {
  margin-left: 0;
}
.preq-form .appl4 li .element-toggle label input[type="text"] {
  margin-top: 4px;
  margin-left: 0;
  margin-right: 0;
  width: calc(100% - 10px);
}
.merchant-panel .preq-form .appl4 li .f2split {
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 5px;
}
.merchant-panel .preq-form .appl4 li .f2split .validator {
  width: 100%;
}
.preq-form .appl-notes ul li strong {
  font-weight: bold;
}
.preq-form .appl-notes ul li em {
  color: #004eff;
}
.merchant-panel .preq-view .column>ul {
  padding-top: 8px;
}
.merchant-panel .preq-view .column>ul label {
  padding-bottom: 0;
}
.merchant-panel .preq-view .column>ul .validator {
  padding-top: 0;
  line-height: normal;
}
.appf.pfx .column-form.type2 li > div > div .mcc-edit,
.merchant-panel .preq-view .column>ul .validator .mcc-edit {
  display: flex;
  padding-right: 5px;
  align-items: center;
}
.appf.pfx .column-form.type2 li > div > div .mcc-edit .autocomplete,
.merchant-panel .preq-view .column>ul .validator .mcc-edit .autocomplete {
  display: block;
  flex-grow: 1;
}
.appf.pfx .column-form.type2 li > div > div .mcc-edit .browse,
.merchant-panel .preq-view .column>ul .validator .mcc-edit .browse {
  flex-shrink: 0;
  margin-left: 4px;
  display: block;
  background: #357aba;
  padding: 0 3px;
  color: white;
  font-weight: bold;
  height: 16px;
  line-height: 12px;
}
.merchant-panel .preq-view .column>ul .validator .mcc-edit.in-form {
  position: relative;
}
.merchant-panel .preq-view .column>ul .validator .mcc-edit.in-form .browse {
  color: inherit;
  background: transparent;
  position: absolute;
  top: -14px;
  left: 30px;
}
.mcc-browser {
  height: calc(100vh - 100px);
  overflow: auto;
  width: calc(100vw - 200px);
  overflow-x: hidden;
  font-size: 14px;
}
.mcc-browser table {
  width: 100%;
}
.mcc-browser table thead {
  position: sticky;
  top: 0px;
  color: #fff;
}
.mcc-browser table th {
  background: #666;
  padding: 0 3px;
  font-size: 16px;
  box-sizing: border-box;
}
.popup .mcc-browser .close {
  top: 30px;
  right: 30px;
}
.preq-form li .mcc-browser input[type="text"] {
  color: white;
  height: 28px;
  border: 1px solid #aaa;
}
.preq-form li .mcc-browser input[type="text"]::placeholder {
  color: white;
  opacity: 1;
}
.mcc-browser .content-table select {
  border: 1px solid #aaa;
  color: white;
}
.mcc-browser .content-table select option {
  color: #000;
  font-weight: normal;
}
.mcc-browser .content-table .select {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  background: #357aba;
  color: white;
  padding: 0 4px;
  text-transform: uppercase;
  font-size: 12px;
}
.solution-header {
  font-size: 20px;
  font-weight: bold;
}

.solution-list {
  margin-left: 10px;
}
