.messages {
  min-width: 400px;
  max-width: 100%;
}
.messages h2 {
  color: #292929;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.messages ul li {
  padding: 7px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.messages ul li .date {
  display: block;
  font-size: 12px;
  color: #8f979e;
  margin-right: 10px;
}
.messages ul li .message {
  width: 100%;
}
.messages ul li .new {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  background: #d60000;
  font-weight: 600;
  border-radius: 3px;
  margin-right: 5px;
}
