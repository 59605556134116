.account-settings {
  margin: 30px 30px 0;
}

.account-settings h2 {
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin: 30px 0;
}
.account-settings .module {
  display: flex;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  height: 70px;
  margin: 10px 0;
}
.account-settings .module .mod-head {
  color: white;
  display: flex;
  background: black;
  width: 250px;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;  
}
.account-settings .module .mod-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  flex-grow: 1;
  position: relative;
}
.account-settings .module .mod-body .addons {
  position: absolute;
  right: 30px;
  top: 11px;
  height: 50px;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
}
.account-settings .module .mod-body .addons .button {
  -webkit-apperance: none;
  outline: none;
  border: 1px solid #bcbcbc;
  display: inline-block;
  padding: 0 10px;
  height: 26px;
  box-sizing: border-box;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  background: rgb(254,254,254);
  background: -moz-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(223,223,223,1) 100%);
  background: -webkit-linear-gradient(top, rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%);
  background: linear-gradient(to bottom, rgba(254,254,254,1) 0%,rgba(223,223,223,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#dfdfdf',GradientType=0 );
  cursor: pointer;
}
.account-settings .module.osb-module {
  height: auto;
}
.account-settings .module.osb-module .mod-body {
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-around;
}
.osb-module .osb-body :first-child {
  margin-left: auto;
  margin-right: auto;
}
.osb-module .osb-body.cash-advance form {
  display: block;
  flex-grow: 0;
  width: auto;
}
.osb-module .osb-body.cash-advance form label {
  line-height: 1.2em;
}
.osb-module .osb-body .emph {
  font-size: 24px;
/*   margin: 12px 0; */
  color: #31a000;
}
.account-settings .module.osb-module .opts-f2 {
  margin: 0 30px 0 0;
}
.osb-module .osb-body form {
  display: flex;
  width: 70%;
  margin-right: 30px;
  margin-top: 0;
}
.osb-module .osb-body form label {
  line-height: 24px;
}
.osb-module .osb-body .select {
  margin: 0 15px;
  flex-grow: 1;
}

.active-toggle {
  width: 110px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  background: #b7b7b7;
  cursor: pointer;
  position: relative;
  text-align: center;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
}
.active-toggle::after {
  content: '';
  background: #aeaeae;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 6px;
  right: 6px;
  border-radius: 13px;
}
.active-toggle.checked {
  background: #1fce79;
}
.active-toggle.checked::after {
  background: #161616;
}
.active-toggle input {
  display: none;
}
.enroll-button a {
  display: block;
  background: #5e5e5e;
  width: 138px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
}
