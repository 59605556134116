.appf {
  box-sizing: border-box;
  max-width: 1140px;
  padding: 30px 40px;
/*   zoom: 0.6666; */
}
.appf h1 {
  margin: 20px 0;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 70px;
}
.appf p.form-intro,
.appf .wcontent>p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  color: #9b9b9b;
  padding: 0 70px;
  margin: 30 0px;
  text-align: center;
}
.appf form h2 {
  height: 50px;
  background: black;
  color: white;
  font-size: 18px;
  line-height: 50px;
/*   padding-left: 26px; */
  margin-top: 40px;
  margin-bottom: 8px;
}
.appf form h2>em {
  margin-left: 26px;
}
.appf form h2>span {
  font-size: 14px;
  color: #9b9b9b;
}
.appf form h2 .checkbox {
  display: block;
  float: right;
  width: 57.6%;
  height: 50px;
  background: #ffd962;
  border-left: 6px solid white;
  text-align: center;
  color: black;
}
.appf form h2 .checkbox input {
  display: none;
}
.appf form h2 .checkbox span {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a69f8d+0,c4beb7+100 */
background: rgb(166,159,141); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(166,159,141,1) 0%, rgba(196,190,183,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a69f8d', endColorstr='#c4beb7',GradientType=0 ); /* IE6-9 */
  margin-right: 22px;
  position: relative;
}
.appf form h2 .checkbox span::after {
  content:"";
  width: 30px;
  height: 35px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: url("../img/check.png") no-repeat;
  position: absolute;
  bottom: -3px;
  left: 0;
  opacity: 0;
}
.appf form h2 .checkbox.checked span::after {
  opacity: 1;
}
.appf form h2 .checkbox span::before {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  content: "";
  background: white;
}
.appf form h3 {
  height: 35px;
  background: #7a7a7a;
  color: white;
  font-size: 14px;
  line-height: 35px;
  padding-left: 26px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.appf form h3.list-heading {
  color: white;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  font-weight: 400;
}
.appf form h3.list-heading label {
  float: right;
  margin-right: 15px;
  vertical-align: middle;
}
.appf form h3.list-heading label input[type=checkbox] {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-right: 6px;
}
.appf form p {
  margin: 15px;
}
.preq-form li {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.preq-form li>label {
  display: block;
  width: calc(42.4% - 6px);
  box-sizing: border-box;
  min-height: 30px;
  padding: 2px 30px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  line-height: 24px;
  background: #eaeaea;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.preq-form li label strong {
  font-weight: 600;
}
.preq-form li .f2split,
.preq-form li .validator {
  background: #eaeaea;
  width: 57.6%;
  box-sizing: border-box;
  min-height: 30px;
  padding: 2px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.preq-form li .f2split .validator {
  width: 50%;
  border: none;
}
.preq-form li:nth-of-type(2n+1) label,
.preq-form li:nth-of-type(2n+1) .validator {
  background: white;
}
.preq-form li:first-of-type label,
.preq-form li:first-of-type .validator {
  border-top: 1px solid #dedede;
}
.preq-form li:last-of-type label,
.preq-form li:last-of-type .validator {
  border-bottom: 1px solid #dedede;
}
.preq-form li input[type=text] {
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 2px;
  background: transparent;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 24px;
}
.preq-form li input[type=text][readonly] {
  border-color: transparent;
  cursor: default;
}
.preq-form li .validator textarea {
  margin: 0;
}
.preq-form .new-ubo {
  
}
.preq-form .new-ubo a {
  display: block;
  border: 3px solid #e0e0e0;
  margin-top: 8px;
  text-align: center;
  position: relative;
  padding-top: 48px;
  padding-bottom: 12px;
  line-height: 22px;
  font-size: 14px;
}
.preq-form .new-ubo a::before {
  content: "+";
  font-size: 30px;
  font-weight: bold;
  display: block;
  width: 34px;
  height: 34px;
  left: calc(50% - 17px);
  top: 11px;
  color: #1b4f00;
  background: #91e863;
  border-radius: 17px;
  line-height: 36px;
  text-align: center;
  position: absolute;
}
.preq-form li.remove {
  display: block;
  text-align: right;
  color: #e00000;
  overflow: hidden;
  margin-top: 4px;
}
.preq-form li.remove a {
  display: inline-block;
  margin-right: 8px;
  width: 13px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  box-sizing: border-box;
/*   padding-left: 2px; */
  background: #e00000;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
}
.preq-form .new  {
  text-align: right;
  margin-top: 4px;
}
.preq-form .new a {
  display: inline-block;
  margin-right: 8px;
  width: 13px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  box-sizing: border-box;
/*   padding-left: 2px; */
  background: #00c000;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
}
.preq-form li .element-toggle {
  
}
.preq-form li .element-toggle.slin {
  display: flex;
}
.preq-form li .element-toggle.slin label {
  margin-right: 16px;
}
.preq-form li .element-toggle label {
  display: flex;
}
.preq-form li .element-toggle label input[type=text] {
  max-width: 200px;
  margin-left: 16px;
  background: white;
}
.preq-form li .element-toggle label input[type=radio] {
  display: none;
}
.preq-form li .element-toggle label span {
  display: block;
  box-sizing: border-box;
  margin-left: 12px;
  border: 1px solid #dcdcdc;
  background: white;
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.preq-form li .element-toggle label span::before {
  display: block;
  box-sizing: border-box;
/*   background: yellow; */
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  content: "";
}
.preq-form li .element-toggle label.checked span::before {
  background: black;
}
.preq-form .f2split>div {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
}
.preq-form .f2split label {
  display: block;
  width: calc(52.4% - 6px);
  box-sizing: border-box;
  min-height: 30px;
  padding: 2px 15px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  line-height: 24px;
  background: #eaeaea;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.preq-form .f2split label span {
  font-size: 12px;
  color: #9b9b9b;
}
.preq-form .f2split input {
  background: #eaeaea;
  width: 47.6%;
  box-sizing: border-box;
  min-height: 30px;
  padding: 2px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.preq-form .percentSplit>div:nth-of-type(2n+1) label,
.preq-form .percentSplit>div:nth-of-type(2n+1) input {
  background: white;
}
.preq-form .percentSplit>div:first-of-type label,
.preq-form .percentSplit>div:first-of-type input {
  border-top: 1px solid #dedede;
}
.preq-form .percentSplit>div:last-of-type label,
.preq-form .percentSplit>div:last-of-type input {
  border-bottom: 1px solid #dedede;
}
.preq-form .single-field {
  border: 1px solid #dedede;
  padding: 16px;
}
.preq-form .single-field textarea {
  margin: 0;
  border: 1px solid #b5b5b5;
}
.preq-form .notes {
  margin: 30px 24px 50px;
}
.preq-form .notes li {
  display: block;
  color: #9b9b9b;
  font-size: 18px;
  line-height: 30px;
}
.preq-form .notes li::before {
  content: "• ";
}
.preq-form .consent {
  font-size: 16px;
  line-height: 26px;
  max-width: 560px;
  margin: 20px auto;
  text-align: center;
}
.appf .buttons,
.preq-form .buttons {
  justify-content: center;
}
.appf .buttons .btn-large,
.preq-form .buttons .btn-large {
  min-width: 200px;
  height: 70px;
  background: #dcbf11;
  font-size: 22px;
  text-transform: none;
  color: black;
}
.appf .notes {
  color: #7b7b7b;
  font-size: 18px;
  line-height: 30px;
  margin: 40px 0;
}
/*
.appf .notes li {
  margin-bottom: 8px;
}*/
.appf .notes li::before {
  content: "• ";
}
.pre-done p,
.appf .consent p {
  font-weight: 300;
  color: #9b9b9b;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  margin: 40px 15px;
}

.pre-done {
  
}
.pre-done .pd-title {
  font-weight: 500;
  font-size: 29px;
  color: #2cea00;
/*   margin-bottom: 12px; */
  margin-top: 40px;
  text-align: center;
}
.pre-done h1 {
  font-size: 58px;
  letter-spacing: -0.4px;
}





.huge-checkbox i {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a69f8d+0,c4beb7+100 */
background: rgb(166,159,141); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(166,159,141,1) 0%, rgba(196,190,183,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a69f8d', endColorstr='#c4beb7',GradientType=0 ); /* IE6-9 */
  margin-right: 6px;
  position: relative;
}
/*.huge-checkbox i::before {
  content:"";
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: white;
  position: absolute;
  top: 1px;
  left: 1px;
}
.huge-checkbox .checked i::before {
  content: "✓";
}*/
.huge-checkbox input[type="checkbox"] {
  display: none;
}

.huge-checkbox i::after {
  content:"";
  width: 30px;
  height: 35px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: url("../img/check.png") no-repeat;
  position: absolute;
  bottom: -3px;
  left: 0;
  opacity: 0;
}
.huge-checkbox .checked i::after {
  opacity: 1;
}
.huge-checkbox i::before {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  content: "";
  background: white;
}

.appnat .form-step section h2 {
  background: transparent;
  color: black;
}
.acc-list table td:last-of-type a.btn-like {
  color: white;
  background: #4181f8;
  display: block;
  text-align: center;
  border-top: 2px solid white;
  cursor: pointer;
}
.acc-list table td:last-of-type a.file {
  color: #5c5c5c;
  font-size: inherit;
}
