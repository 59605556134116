
.idv-widget {

    .loader {
      border: 5vw solid #f3f3f3;
      border-radius: 50%;
      border-top: 5vw solid #3498db;
      width: 20vw;
      height: 20vw;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
    /* The Modal (background) */
    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }
    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
    }
    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
    }
    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
    .detection-state {
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 100;
      font-size: x-large;
      margin-top: 50;
    }
    .face-camera-container {
      display: none;
      width: 100%;
      height: 100%;
    }
    .fit-screen {
      max-width: 100%;
      height: auto;
    }

  max-width: 1000px;
  width: 90%;
  margin: 200px auto 100px;
  h1 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 18px;
  }
  text-align: center;
  p {
    margin: 1em 0;
  }
  .field {
    margin-bottom: 1em;
  }
  .ch-button {
    padding: 3px 10px;
    margin-right: 8px;
  }
  select {
    max-width: 100%;
  }
  .camera {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background: rgba(0,0,0,0.75);
    flex-direction: column;
    @media screen and (orientation: landscape) {
      flex-direction: row;
    }
    .cancel {
      display: block;
      background: white;
      margin: 20px auto 0;
      @media screen and (orientation: landscape) {
        margin: auto 20px auto;
      }
      width: 150px;
      max-width: 90%;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
    }
    .capture {
      display: block;
      background: white;
      margin: 20px auto 0;
      @media screen and (orientation: landscape) {
        margin: auto 20px auto;
      }
      width: 150px;
      max-width: 90%;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
    }
    &.simple-camera-container {
      display: flex;
    }
  }
  .simple-camera {
    background: black;
    display: flex;
    width: 640px;
    height: 360px;
    @media screen and (orientation: landscape) {

    }
    video {
      object-fit: contain;
      width: 640px;
      height: 360px;
    }

  }
  #result-id-img {
    margin-top: 40px;
  }
}
