.header.nsplit h3,
.header.split h3 {
  font-size: 18px;
  font-weight: bold;
}
.header.split {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.header.split>div {
  box-sizing: border-box;
  width: calc(100% - 350px);
}
.header.split.fullw>div {
  width: 100%;
}
.full-width {width: 100%!important}
.header.split>aside {
  width: 350px;
  box-sizing: border-box;
  padding: 30px 35px;
  margin: -30px -30px -30px 0;
  background: #f3f3f3;
}
.f3split,
.f2split {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.f2split>div {
  width: calc(50% - 15px);
}
.f3split>div {
  width: calc(33% - 15px);
}
.search-form .field {
  display: flex;
  margin-bottom: 8px;
  margin-right: 15px;
  align-items: center;
  justify-content: space-between;
}
.search-form label {
  font-size: 12px;
  font-weight: bold;
}
.search-form select,
.search-form input {
  height: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0 8px;
  background: white;
  box-sizing: border-box;
}
.search-form input:disabled {
  background-color: #eee;
}
.search-form .field .field-group,
.search-form .field>select,
.search-form .field>input {
  width: 66%;
}
.search-form .field.shortlab .field-group,
.search-form .field.shortlab>select,
.search-form .field.shortlab>input {
  width: calc(82.5% + 10px);
}
.search-form .field>label {
  width: calc(33% - 5px);
}
.search-form .field.shortlab>label {
  width: calc(16.5% - 15px);
}

.search-form .field .field-group {
  display: flex;
  justify-content: space-between;
}
.search-form .field .field-group input, .search-form .field .field-group .w-half {
  width: calc(50% - 2px);
}
.search-form .buttons {
  margin-top: 40px;
}
.search-form .multiselect {
  width: calc(82.5% + 10px);
  display: flex;
}
.search-form .multiselect li {
  vertical-align: middle;
  margin-right: 30px;
}
.search-form .multiselect li input {
  vertical-align: middle;
}

.hr {
  position: relative;
  background: #cecece;
  height: 7px;
}
.hr::after {
  content: "";
  position: absolute;
  border-top: 16px solid #4e8bff;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -3px;
  left: calc(50% - 16px);
}
.breakdown-table {
  width: 100%;
  border: 1px solid black;
}
.breakdown-table th {
  background: black;
  color: white;
  height: 30px;
  font-weight: bold;
  text-align: center;
}
.breakdown-table th.spacer {
  background: white;
  height: 10px;
}
.breakdown-table th:first-of-type {
  text-align: left;
  padding-left: 16px;
}
.breakdown-table td {
  height: 30px;
  font-weight: bold;
  text-align: center;
  background: #e5e5e5;
}
.breakdown-table td:first-of-type {
  text-align: left;
  padding-left: 16px;
  font-size: 12px;
  font-weight: normal;
  margin-left: 1px;
}
.breakdown-table td:last-of-type {
  margin-right: 1px;
}
/*.breakdown-table tr:first-of-type td {
  border-top: 10px solid white;
}*/
.breakdown-table tr:last-of-type td {
  border-bottom: 3px solid black;
}
.breakdown-table tr:nth-of-type(2n) td {
  background: white;
}
.acc-list {
  display: none;
  width: 100%;
}
.result-count {
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 30px;
}
.acc-results {
  
}
.acc-results li {
  margin-bottom: 7px;
  border: 1px solid #cecece;
}
.acc-results li h4 {
  background: #555555;
  color: white;
  line-height: 32px;
  padding: 6px 15px 4px;
  cursor: pointer;
  position: relative;
}
.acc-results li h4::after {
  content: "+";
  color: white;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  text-align: center;
  width: 20px;
  display: block;
  line-height: 32px;
}
.acc-results li.active h4 {
  background: #4e8bff;
}
.acc-results li.active h4::after {
  content: "-";
}
.acc-results li h4 .label {
  font-size: 18px;
  font-weight: bold;
  min-width: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: baseline;
}
.acc-results li h4 .label.rw {
  min-width: 100px;
  margin-right: 40px;
}
.acc-results li h4 .count {
  font-size: 12px;
}
.acc-results li h4 .count strong {
  font-weight: bold;
  margin-left: 30px;
}
.acc-list {
  background: #fff;
}
.acc-list table {
  width: 100%;
}
.acc-list table th {
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  background: #e3e3e3;
  text-align: center;
}
.acc-list table th:first-of-type {
  padding-left: 15px;
  text-align: left;
}
.acc-list table th:last-of-type {
  padding-right: 15px;
  text-align: right;
}
.acc-list table td {
  font-size: 14px;
  height: 25px;
  line-height: 26px;
/*   background: #e3e3e3; */
  text-align: center;
  color: #565656;
}
.acc-list table td:first-of-type {
  padding-left: 11px;
  text-align: left;
  margin-left: 4px;
}
.acc-list table td:last-of-type {
  padding-right: 11px;
  text-align: right;
  margin-right: 4px;
}
.acc-list table tr:nth-of-type(2n) {
  background: #ededed;
}
.acc-list table tr:last-of-type td {
  border-bottom: 4px solid #fff;
}
.acc-list table td:last-of-type a {
  color: inherit;
  font-size: 12px;
  margin-left: 6px;
}
.acc-list table .new-row td {
  text-align: center;
}
.acc-list table .new-row td .add {
  display: inline-block;
  background: #61964b;
  width: 32px;
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  line-height: 32px;
  margin-top: 8px;
  margin-bottom: 6px;
  border-radius: 16px;
}
.acc-list table td .link {
  color: #4e8bff;
  cursor: pointer;
}
.acc-list table td:last-of-type a.button {
  display: inline-block;
  padding: 0 4px;
  background: #357aba;
  color: white;
}
a.link {
  color: #4e8bff;
  cursor: pointer;
}
.edit-system .field label {
  margin: 8px 0;
  display: block;
}
.edit-system .field .file-obj {
  max-width: 328px;
  margin: 8px 0;
}
.ph-pr-in>div {
  border-radius: 3px;
  padding: 10px;
  background: white;
  margin: 0px 0 8px;
  max-width: 328px;
  box-sizing: border-box;
  display: inline-block;
}
.ph-pr-in>div.org {
  border: 2px solid #ada;
}
.ph-pr-in>div.chg {
  border: 2px solid #daa;
}
.ph-pr-in img {
  max-width: 120px;
  max-height: 120px;
}
.edit-system .ph-prev {
  overflow: hidden;
}
.edit-system .ph-prev .ph-pr-in {
  float: left;
}
.edit-system .ph-prev .ph-pr-in>div {
  margin: 0 16px 0 0;
}
.usermgmt .buttons {
  display: flex;
  justify-content: center;
}

.user-contacts {
  margin-top: 15px;
}
.user-contacts li {
  border: 1px solid #ddd;
  padding: 8px 4px 6px;
  border-bottom: 3px solid #bbb;
  margin-bottom: 6px;
  margin-left: -5px;
  margin-right: -5px;
}
.user-contacts .remcon {
  float: right;
  color: #e00000;
  border: 1px solid #ddd;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 8px;
  margin-top: -9px;
  margin-right: -5px;
  margin-bottom: 5px;
}
.user-contacts li>div {
  clear: both;
}
.user-contacts li:last-of-type {
  margin: 0;
  padding: 0;
  border: 0 none;
  margin-top: 10px;
}
.search-form .newcon,
.user-contacts li .newcon {
  background: #357aba;
  background: #1f9400;
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 2px 15px;
}
.doc-up>div {
  margin-bottom: 20px;
}
.doc-up button {
  border: 0;
  color: white;
  background: #1e9304;
  font-size: 12px;
  text-transform: uppercase;
  width: 90px;
  height: 30px;
  cursor: pointer;
}

textarea {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0 8px;
  background: white;
  box-sizing: border-box;
  resize: none;
}
