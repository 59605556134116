.popup {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.85);
  z-index: 500;
  visibility: hidden;
}
.popup.shown {
  visibility: visible;
}
.popup .pop-med {
  margin: auto;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 1200px;
}
.popup .pop-inner {
  background: white;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.popup h2 {
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 20px;
}
.popup p {
  margin: 20px 0;
  line-height: 20px;
}
.popup .close {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  right: -12px;
  top: -12px;
  background: #4e8bff;
  cursor: pointer;
}
.popup .close::before,
.popup .close::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 2px;
  background: white;
  top: 15px;
  left: 8px;
  transform-origin: 50% 50%;
}
.popup .close::before {
  transform: rotate(-45deg);
}
.popup .close::after {
  transform: rotate(45deg);
}
.popup.usermgmt .pop-med {
  max-width: 750px;
}

.popup .text-to-copy {
  text-align: center;
  cursor: pointer;
  margin: -20px;
  padding: 20px;
}
.popup .text-to-copy button {
  cursor: pointer;
  display: block;
  width: calc(100% + 40px);
  background: #367abb;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 15px;
  margin: 20px -20px -22px;
  height: 40px;
  border-radius: 0 0 4px 4px;
  border: 0 none;
  color: white;
}
