.ncontent {
  position: relative;
  box-sizing: border-box;
  margin: 90px auto;
  background: white;
  max-width: calc(100% - 50px);
  /*width: 1100px;*/
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
  border-radius: 4px;
  border: 1px solid rgba(107, 107, 107, .23);
}
.ncontent.marginless {
  margin: 0;
}
.ncontent.wide {
  width: auto;
}
.ncontent .header {
  padding: 30px;
}
.ncontent .content {
  padding: 30px;
  background: #e6e6e6;
}
.touch-it {margin:0px auto;}
.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 20px;
}
.breadcrumbs li {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.breadcrumbs li::before {
  content: "> ";
}
.breadcrumbs li:first-of-type {
  font-size: 38px;
  font-weight: 300;
  margin-right: 20px;
}
.breadcrumbs li:first-of-type::before {
  display: none;
}
.toolbar {
  float: right;
}
form.system-messages label {
  display: block;
  font-size: 16.5px;
  margin-bottom: 6px;
}
form textarea {
  display: block;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  min-height: 100px;
  padding: 6px 10px;
  border: 1px solid #d6d6d6;
  margin: 6px 0 16px;
  border-radius: 3px;
}
form .buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.btn.btn-large {
  text-align: center;
  line-height: 54px;
  font-size: 18px;
  color: white;
  background: #357aba;
  border: 0;
  height: 54px;
  min-width: 330px;
  padding: 0 10px;
  text-transform: uppercase;
  margin-right: 40px;
}
.btn.btn-small {
  text-align: center;
  color: white;
  line-height: 40px;
  font-size: 18px;
  background: #357aba;
  border: 0;
  height: 54px;
  min-width: 180px;
  padding: 0 20px;
  text-transform: uppercase;
  margin-right: 40px;
}
.btn.btn-small:disabled, .btn.btn-xs:disabled {
  cursor: auto;
  background: #7faeda;
}

.btn.btn-xs {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  color: white;
  background: #4e8bff;
  border: 0;
  padding: 0 20px;
  margin-right: 20px;
  white-space: nowrap;
}

.btn.btn-xxs {
  color: white !important;
  background: #4181f8;
  text-align: center;
  text-decoration: none !important;
  padding: 2px 4px;
  line-height: 1.5;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.btn.btn-danger {
  background-color: #f1445c;
}

form .checkbox {
  margin: 0px;
  margin-bottom: 0 !important;
  color: #878787;
}
form .checkbox input {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 3px;
}

.ncontent h3 {
  color: #303030;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 15px;
}
.message-history {
  
}
.message-history li {
  background: white;
  margin: 15px 0 25px;
  position: relative;
  padding: 25px 30px;
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
  padding-right: 260px;
}
.message-history .head {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}
.message-history .head time {
  font-weight: 400;
}
.message-history .head a {
  font-weight: bold;
  color: #357aba;
}
.message-history .message {
  font-size: 18px;
  margin-bottom: 5px;
}
.message-history .dashboard-flag {
  font-size: 12px;
  display: inline-block;
  line-height: 20px;
  padding: 5px 30px;
  color: #7c7c7c;
  background: #e5e5e5;

}
.danger {
  color: red;
}
.success {
  color: #00a000;
}
.message-history li button {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  font-size: 10px;
  text-transform: uppercase;
  height: 30px;
  width: 190px;
}
.btn {
  border: 0;
  cursor: pointer;
}
.btn.btn-danger {
  background: #d33c46;
  color: white;
}

.back-button {
  position: absolute;
  top: -15px;
  right: 20px;
  background: white;
  color: black;
  font-weight: 300px;
  padding: 4px 10px 4px 38px;
  border: 1px solid rgba(107, 107, 107, .23);
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
  border-radius: 4px;
  overflow: hidden;
}
.back-button::before {
  content: "<";
  display: block;
  position: absolute;
  padding: 4px 10px;
  top: 0;
  left: 0;
  background: #f3f3f3;
}

.version {
  text-align: center;
  margin-top: 20px;
  color: #9b9b9b;
}

.header.new-app-header {
  display: block;
  background: #f0f0f0;
  margin-bottom: -10px;
  padding: 30px 0 0;
}

.header.new-app-header .toolbar {
  float: none;
  width: auto;
  padding: 0 8.64% 0;
  justify-content: space-between;
}
.header.new-app-header .toolbar>div {
  width: calc(33% - 15px);
}
.header.new-app-header .toolbar.two-way-split>div {
  width: calc(50% - 15px);
}
.header.new-app-header .form-link-btn {
  background: white;
/*   width: calc(50% - 15px); */
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}
.header.new-app-header .form-link-btn div {
  visibility: hidden;
  pointer-events: none;
  background: white;
  margin-top: -15px;
}
.header.new-app-header .form-link-btn.generated div {
  visibility: visible;
  pointer-events: all;
}
.header.new-app-header .form-link-btn div input {
  border: 0px none;
  background: white;
  pointer-events: none;
  width: calc(100% - 67px);
  font-size: 12px;
  height: 24px;
}
.header.new-app-header .form-link-btn div button {
  background: #357aba;
  border: 0px none;
  height: 24px;
  padding: 0 10px;
  color: white;
  text-transform: uppercase;
  
}
.header.new-app-header .form-link-btn:first-child {
}
.header.new-app-header .form-link-btn:last-child {
}

.ncontent.specpad {
  box-sizing: border-box;
  padding: 24px;
}
.ncontent.nc-flat + .ncontent.specpad {
  margin-top: -90px;
}

.ncontent.specpad .tabs {
  font-size: 12px;
  margin-bottom: 20px;
}
.ncontent.specpad .tabs .btn {
  text-decoration: none;
  padding: 8px 20px;
  border: 1px solid black;
  border-radius: 3px;
  color: white;
  background: rgb(50,50,50);
  background: linear-gradient(to bottom, rgba(50,50,50,1) 0%,rgba(5,5,5,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323232', endColorstr='#050505',GradientType=0 );
}

.doc-template {
  padding: 100px;
}
.doc-title {
  font-size: 14px;
  color: rgb(54, 120, 243);
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}
.doc-template p {
  margin: 15px 0;
}
.doc-template .signature-placeholder {
  display: flex;
}
.doc-template .signature-placeholder span {
  white-space: nowrap;
  margin-right: 10px;
}
.doc-template .signature-placeholder .signature-line {
  width: 100%;
  height: auto;
  flex-shrink: 10;
}

.doc-template .signature-line {
  width: 66%;
  display: inline-block;
  height: 27px;
  border-bottom: 1px solid #000;
}
.doc-field {
  margin: 5px 0;
}
.doc-field label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.doc-field input {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.preform .preq-form .new-ubo a {
  height: 25px;
  cursor: pointer;
  margin: 10px 0;
}

.text-bg-black {
  padding: 2px;
  background-color: #000;
  color: #fff;
}

.custom-doc-field {
  margin: 10px 0;
}
.custom-doc-field .label {
  display: inline-block;
  vertical-align: top;
  width: 55%;
}
.custom-doc-field .field-wrapper .amount-currency input {
  width: 120px;
}
.custom-doc-field .field-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 200px;
}

.yes-or-no {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.yes-or-no div {
  display: inline-block;
  vertical-align: middle;
}
.yes-or-no div label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.yes-or-no div input {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.small-remove-item-button {
  display: inline-block;
  margin-right: 8px;
  width: 13px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  box-sizing: border-box;
  background: #e00000;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 5px;
}

.form-link-ext {
  margin: 10px 0 10px;
  background: white;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 4px 6px rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 10px 10px 14px;
  text-align: center;
}
.form-link-ext::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background: #eaeaea;
}
.form-link-ext form {
  display: flex;
}
.form-link-ext form input,
.form-link-ext form select {
  display: block;
  margin-right: 10px;
  background: transparent;
  flex-shrink: 1;
  flex-grow: 1;
  border: 1px solid #ccc;
  width: 20px;
}
.form-link-ext form input {
/*   flex-grow: 2; */
}
.form-link-ext form button {
  display: block;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: white;
  background: #357aba;
  border: 0;
  height: 24px;
  padding: 0 10px;
  text-transform: uppercase;
}

.table-add-button {
  width: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-weight: 900;
  border: none;
  outline: none;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  border-radius: 0;
  cursor: pointer;
}

.data-transfer-validation {
  padding: 20px;
}
.data-transfer-validation h3.error {
  color: #d33c46;
  text-align: center;
}
.data-transfer-validation .table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin: 30px 0;
}
.data-transfer-validation .info-text {
  font-size: 18px;
  color: #444;
}
.data-transfer-validation ul {
  font-size: 14px;
  color: #555;
  margin: 10px 0 20px 0;
}

.selectable-table {
  background-color: #a8deec !important;
}
