.toolbar {
  display: flex;
}
.form-link-btn {
  margin: 0 10px;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 4px 6px rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 15px 25px 20px;
  text-align: center;
  cursor: pointer;
}
.form-link-btn::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 6px;
  background: #eaeaea;
}
.form-link-btn strong {
  font-weight: bold;
  display: block;
}
.form-link-btn em {
  display: block;
  font-size: 12px;
  margin-top: 4px;
}


