.appf .progress-bar {
  display: flex;
  align-items: space-between;
  margin: 20px 60px 50px;
}
.appf .progress-bar li {
  text-align: center;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  padding: 34px 0 24px;
}
.appf .progress-bar li::before {
  position: absolute;
  left: calc(50% - 12px);
  content: "";
  width: 24px;
  height: 24px;
  top: 0;
}
.appf .progress-bar li::after {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 10px;
  bottom: 0;
  background: #e9e9e9;
}
.appf .progress-bar li:first-of-type::after {
  border-radius: 5px 0 0 5px;
}
.appf .progress-bar li:last-of-type::after {
  border-radius: 0 5px 5px 0;
}
.appf .progress-bar li.completed::after {
  background: #91e863;
}
.appf .progress-bar li.completed::before {
  background: url("../img/checkmark.png") no-repeat 50% 50%;
}
.appf .progress-bar li.current::after {
  background: url("../img/prodash.png") repeat-x 50% 50%;
}
.appf .huge-checkbox {
  margin: 0 -80px;
  background: #ffd962;
  text-align: center;
  padding: 15px 0;
  line-height: 50px;
  height: 50px;
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 70px;
}
.appf .huge-checkbox.website-checkbox {
  text-align: left;
  margin: 0;
  background: transparent;
  font-size: 18px;
  
}
.appf .huge-checkbox.website-checkbox label {
  text-align: left;
  background: transparent;
  width: 26%;
}
.appf .huge-checkbox strong {
  font-weight: bold;
}
.appf .huge-checkbox span {
  font-size: 16px;
  margin-left: 20px;
}
.appf .form-step {
  padding: 0 40px;
}
.appf .form-step section h2 { /* TODO */
  margin: 10px 0;
  font-size: 2em;
  font-weight: bold;
}
.appf .element-checkbox-line { /* TODO */
  background: #f3f3f3;
  display: flex;
  margin-bottom: 5px;
}
.appf .element-checkbox-line>label { /* TODO */
  width: 75px;
  min-height: 75px;
  background: #d5d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.appf .element-checkbox-line > label input[type=checkbox],
.appf .element-checkbox-line > label input[type=radio] {
  display: none;
}
.appf .element-checkbox-line>label.checked {
  background-color: #c9ffb9;
}
.appf .element-checkbox-line>.title {
  flex-grow: 1;
  padding: 20px 25px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  font-weight: 300;
  font-size: 22px;
}
.appf .element-checkbox-line>.title .more {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
}
.appf .element-checkbox-line>.title .file-obj {
  width: 200px;
}
.appf .element-checkbox-line>.toggleMore {
  padding: 20px;
  width: 34px;
  height: 34px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  background: #91e863;
  padding: 0;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 18px;
  color: #1b4f00;
  font-weight: bold;
}
.appf .element-checkbox-line>.toggleMore.opened {
  background: #a5a5a5;
  color: #646464;
}
.appf .element-checkbox-line>.toggleMore a::before {
  content: "+";
}
.appf .element-checkbox-line>.toggleMore.opened a::before {
  content: "-";
}
.appf .element-checkbox-line>label span {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a69f8d+0,c4beb7+100 */
background: rgb(166,159,141); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(166,159,141,1) 0%, rgba(196,190,183,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(166,159,141,1) 0%,rgba(196,190,183,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a69f8d', endColorstr='#c4beb7',GradientType=0 ); /* IE6-9 */
  position: relative;
}
/*.appf .element-checkbox-line>label span::before {
  content:"";
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: white;
  position: absolute;
  top: 1px;
  left: 1px;
}
.appf .element-checkbox-line>label.checked span::before {
  content: "✓";
}*/

.appf .element-checkbox-line>label span::after {
  content:"";
  width: 30px;
  height: 35px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: url("../img/check.png") no-repeat;
  position: absolute;
  bottom: -3px;
  left: 0;
  opacity: 0;
}
.appf .element-checkbox-line>label.checked span::after {
  opacity: 1;
}
.appf .element-checkbox-line>label span::before {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  content: "";
  background: white;
}



.appf .element-checkbox-line>.title .element-toggle {
  font-size: 13px;
  margin-left: 40px;
}
.appf .element-checkbox-line>.title .element-toggle input[type="radio"] {
  display: none;
}
.appf .element-checkbox-line>.title .element-toggle label {
  margin-right: 10px;
}
.appf .element-checkbox-line>.title .element-toggle span {
  display: inline-block;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background: white;
  vertical-align: middle;
  margin-right: 4px;
  border: 1px solid #d1d1d1;
  position: relative;
}
.appf .element-checkbox-line>.title .element-toggle .checked span::before {
  content: "";
  width: 9px;
  height: 9px;
  top: 3px;
  left: 3px;
  display: block;
  position: absolute;
  background: black;
}

.validator.valid.disabled::after {
  display: none;
}
.appf .disabled .element-checkbox-line {
  opacity: 0.5;
}
.appf .disabled .element-checkbox-line label {
  pointer-events: none;
  
}

.appf form p.intro {
  margin-left: 0;
  margin-right: 0;
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 30px;
}
.appf .toggle-main label {
  font-size: 18px;
  font-weight: 300;
  color: #989898;
  line-height: 24px;
  margin-bottom: 16px;
  display: block;
}
.appf .toggle-main label strong {
  color: black;
}
.appf .toggle-main label i {
  display: inline-block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  margin-right: 20px;
  background: white;
  border: 1px solid #c5c5c5;
}
.appf .toggle-main label i::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
}
.appf .toggle-main label.checked i::before {
  background: black;
}
.appf .toggle-main label input {
  display: none;
}
.appf .column-form {
  
}
.appf .column-form li {
  display: flex;
}
.appf .column-form li label {
  display: block;
  flex-grow: 1;
}
.appf .column-form li>div {
  flex-shrink: 0;
  display: flex;
  width: 486px;
}

.appf .column-form .above-head>div {
  
}
.appf .column-form .head>div {
  flex-shrink: 0;
  display: flex;
}
.appf .column-form li>div>div {
/*   width: 238px; */
  width: calc(100% - 5px);
  margin-left: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  background: white;
  color: black;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.appf .column-form li.head + li>div>div {
  border-top: 1px solid #dedede;
}
.appf .column-form li.head + li>div>div::before {
  content: "";
  display: block;
  background: white;
  height: 18px;
  margin-top: -5px;
}
.appf .column-form li.above-head:nth-of-type(1)>label {
  border-color: transparent;
}
.appf .column-form li.above-head:nth-of-type(1)>div>div {
  border-color: transparent;
}
.appf .column-form li:nth-of-type(2n)>div>div {
  background: #eaeaea;
}
.appf .column-form li:last-of-type>div>div {
  border-bottom: 1px solid #dedede;
}
.appf .column-form li:first-of-type>div>div {
  border-top: 1px solid #dedede;
  position: relative;
}
.appf .column-form li>div>div a.remove {
  position: absolute;
  left: 8px;
  top: 8px;
  background: red;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 18px;
  font-size: 20px;
}
.appf .column-form li>div>div label.copy {
  float: none;
  font-size: 10px;
  margin: 0;
  width: auto;
}
.appf .column-form li>div>div label.copy input {
  vertical-align: middle;
  display: inline-block;
  margin-right: 4px;
}
.appf .column-form .head:nth-of-type(2n)>div>div,
.appf .column-form .head>div>div {
  width: 238px;
  width: calc(100% - 5px);
  margin-left: 5px;
  box-sizing: border-box;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  background: black;
  color: white;
  border-color: black;
}
.appf .column-form select,
.appf .column-form input[type="text"] {
  border: 0;
  width: 100%;
  font-size: 12px;
  padding: 0 12px;
  background: transparent;
  font-size: 14px;
}
.appf .column-form li>label {
  display: block;
  box-sizing: border-box;
  line-height: 20px;
  padding: 7px 30px 5px;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-weight: 500;
}
.appf .column-form li>label span {
  color: #9c9c9c;
  font-weight: 400;
}
.appf .column-form li:nth-of-type(1)>label {
  border-top: 1px solid #dedede;
}
.appf .column-form li.head>label {
  border-color: transparent;
}
.appf .column-form li:nth-of-type(2n)>label {
  background: #eaeaea;
}
.appf .column-form li.head + li>label::before {
  content: "";
  display: block;
  background: white;
  height: 18px;
  margin-top: -7px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 4px;
}
.appf .column-form li.head + li>label {
  border-top: 1px solid #dedede;
  padding-bottom: 0;
}
.appf .column-form li:last-of-type>label {
  border-bottom: 1px solid #dedede;
}
.appf .column-form li div label input {
  display: none;
}
.appf .column-form li div label>span {
  display: inline-block;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background: white;
  vertical-align: middle;
  margin-right: 4px;
  border: 1px solid #d1d1d1;
  position: relative;
}
.appf .column-form li div label.checked>span::before {
  content: "";
  width: 9px;
  height: 9px;
  top: 3px;
  left: 3px;
  display: block;
  position: absolute;
  background: black;
}
.appf .column-form li div label {
  float: left;
  width: 30%;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 18px;
}
.appf .column-form li div label:first-of-type {
  margin-left: 20%;
}
.appf .column-form li div {
/*   overflow: hidden; */
}
.appf .column-form li div .amount-currency {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
.appf .column-form li div .amount-currency input {
  border: 0;
  background: transparent;
  width: calc(100% - 80px);
  font-size: 14px;
}
.appf .column-form li div .amount-currency select {
  border: 0;
  background: transparent;
  font-size: 14px;
  width: 60px;
  border-left: 1px solid #a0a0a0;
}
.appf .footnotes {
  margin: 30px 0 40px;
  font-size: 14px;
  color: #9b9b9b;
}
.appf .footnotes li {
  margin-bottom: 8px;
}
.appf .footnotes li sup {
  display: inline-block;
  font-weight: 600;
  vertical-align: top;
  margin-right: 5px;
  font-size: .8em;
}
.appf .toggle-main {
  margin: 25px 0 40px;
}
appf .toggle-main label i {
  display: inline-block;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background: white;
  vertical-align: middle;
  margin-right: 4px;
  border: 1px solid #d1d1d1;
  position: relative;
}
appf .toggle-main label.checked i::before {
  content: "";
  width: 9px;
  height: 9px;
  top: 3px;
  left: 3px;
  display: block;
  position: absolute;
  background: black;
}
.appf .buttons {
  margin-top: 70px;
}
.appf .buttons .btn:last-of-type {
  margin-right: 0;
}
.appf .buttons a.btn {
  text-align: center;
}
.appf .buttons a.btn.btn-large {
  line-height: 70px;
  border-radius: 5px;
}
.appf .buttons .btn.btn-plain {
  background: #d7d7d7;
}
.appf .buttons .btn.btn-success {
  background: #64ae3f;
  color: white;
}
.appf .buttons .btn.btn-back::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 15px;
  width: 5px;
  height: 10px;
  box-sizing: border-box;
  border-right: 5px solid black;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.appf .buttons .btn.btn-next::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 15px;
  width: 5px;
  height: 10px;
  box-sizing: border-box;
  border-left: 5px solid white;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}

.appf .column-form li>div>div.add-dir {
  background: white;
  position: relative;
}
.appf .column-form li>div>div.add-dir a {
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  top: 8px;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border-radius: 17px;
  color: #1b4f00;
  background: #91e863;
  line-height: 34px;
  text-align: center;
}
.appf form textarea {
  margin: 0;
  border: 0;
}
.appf .column-form li div.business-length {
  display: flex;
}
.appf .column-form li div.business-length input[type=text] {
  width: 29%;
  border: 1px solid #bebebe;
  margin-left: 15px;
  box-sizing: border-box;
}
.appf .column-form li div.business-length label:first-of-type {
  margin-left: 15px;
}
.appf .column-form li div.field.special>div {
  width: calc(50%);
  
}
.appf .column-form li div.field.special input[type=text] {
  border: 1px solid #bebebe;
  box-sizing: border-box;
  margin: 0 15px;
  width: calc(100% - 30px);
}
.appf .column-form li div.field.special>div:last-of-type {
  margin-left: 0;
  border-left-color: transparent;
}
.appf .column-form li div.field.special>div:first-of-type {
  border-right-color: transparent;
}
.appf .column-form li>div.ve {
  display: block;
}
.appf .column-form li>div.ve.ve2 {
  display: flex;
}
.appf .column-form li>div.ve.ve2 textarea {
  margin-right: 5px;
  width: calc(100% - 5px);
  box-sizing: border-box;
  border: #bebebe;
}
.appf .column-form li>div.ve.ve2>div:last-of-type {
  margin-left: 0;
  border-left-color: transparent;
}
.appf .column-form li>div.ve.ve2>div:first-of-type {
  border-right-color: transparent;
}
.appf .column-form li div.ve label {
  float: none;
  text-align: left;
  margin-left: 15px;
  width: auto;
}
.appf .column-form li div.ve label .validator {
  display: inline-block;
  vertical-align: middle;
}
.appf .column-form li div.ve label .validator input {
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  border: 1px solid #bebebe;
}
.appf .ext-h4 {
  margin: 50px 0 25px;
  overflow: hidden;
}
.appf h4 {
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 8px;
}
.appf .subtitle {
  font-weight: 300;
  font-size: 18px;
}
.appf .currency-select {
  width: 20%;
}
.appf .currency-select select {
  display: block;
  border: 1px solid #dedede;
  width: 100%;
  background: white;
  height: 30px;
  margin-bottom: 8px;
}
.appf .currency-select label {
  display: block;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  text-align: right;
  margin-left: 10%;
}
.appf .column-form li .multipercent {
  display: flex;
  flex-wrap: wrap;
}
.appf .column-form li .multipercent>div {
/*   display: flex; */
  margin-bottom: 5px;
  width: calc(25% - 10px);
  margin-right: 5px;
  margin-left: 5px;
  font-size: 12px;
  text-align: left;
}
.appf .column-form li .multipercent>div:last-of-type {
  margin-bottom: 0;
}
.appf .column-form li .multipercent>div input[type=text] {
  width: calc(100% - 30px);
  box-sizing: border-box;
  background: white;
  border: 1px solid #bebebe;
  display: inline-block;
  height: 20px;
  margin-left: 0;
}
.appf .column-form li .multipercent>div>span {
  display: inline-block;
  width: 15px;
}
.appf .column-form li .multipercent>div label:first-of-type {
  margin-left: 0%;
  text-align: left;
  font-size: 12px;
  width: 100%;
  font-weight: 500;
  line-height: 16px;
}
.appf .column-form li .multipercent>div label span {
  display: block;
  background: transparent;
  border: 0;
  width: auto;
  color: #9b9b9b;
  height: auto;
}
.appf .column-form li label>em {
  float: right;
  display: block;
  font-weight: 300;
  font-size: 22px;
  background: #575757;
  color: white;
  height: 40px;
  line-height: 40px;
  width: 56px;
  text-align: center;
  margin-right: -22px;
}
.appf .column-form li label>span {
  display: block;
  color: #9b9b9b;
  margin-top: 2px;
}
.appf .column-form li .multiselect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.appf .column-form li .multiselect li {
/*   width: calc(25% - 6px); */
  margin-left: 5px;
  margin-bottom: 3px;
  align-items: center;
/*   overflow: hidden; */
}
.appf .column-form li .multiselect.autowidth li {
  width: auto;
}
/*.appf .column-form li .multiselect li:nth-of-type(4n+1) {
  margin-left: 0;
}*/
.appf .column-form li .multiselect li>label:first-of-type {
  margin-left: 0;
}
.appf .column-form li .multiselect li>label {
  border: 0 none;
  background: transparent;
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}
.appf .column-form li .multiselect li>label span {
  margin-right: 2px;
}
.appf .column-form li .multiselect li .validator input {
  height: 20px;
  margin-left: 8px;
}
.appf .bank-account-box {
  position: relative;
}
.appf .bank-account-box>a.remove {
  position: absolute;
  right: 12px;
  top: 12px;
  background: red;
  border-radius: 15px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 28px;
  font-size: 20px;
  color: white;
}
.appf .currency-large-box {
  background: black;
  color: white;
  width: calc(50% - 2px);
  height: 74px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 3px;
}
.appf .currency-large-box label {
  font-size: 22px;
  margin-right: 22px;
}
.appf .currency-large-box select {
  background: white;
  border: 0;
  height: 32px;
  width: 180px;
  box-sizing: border-box;
}
.appf .currency-large-box + .f2split {
  clear: both;
}
.f2split>ul {
  width: calc(50% - 2px);
}
.appf .f2split .column-form li > div {
  width: calc(50% - 2px);
}
.appf .new-large {
  display: block;
  margin: 40px 0;
  box-sizing: border-box;
  border: 2px solid #e0e0e0;
  position: relative;
  text-align: center;
  padding: 54px 0 16px;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
}
.appf .new-large::before {
  content: "+";
  font-size: 30px;
  font-weight: bold;
  display: block;
  width: 34px;
  height: 34px;
  left: calc(50% - 17px);
  top: 16px;
  color: #1b4f00;
  background: #91e863;
  border-radius: 17px;
  line-height: 36px;
  text-align: center;
  position: absolute;
}
.appf .h3lg .consent p {
  font-weight: 400;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin: 22px 0px;
  margin-right: 40%;
}
.appf .last-step .element-checkbox-line > .title {
  justify-content: space-between;
}
.last-step .file-obj a.btn-remove {
  color: white;
  background: #e00000;
  border-radius: 3px;
  font-weight: bold;
}
.last-step .file-obj {
  border: 2px solid rgba(0,0,0,.2);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 2px;
}
.appf .last-step,
.appf .note-title {
  margin-top: 40px;
}
.appf .temp-link {
  margin: 10px 40px 30px;
}
.appf .temp-link label {
  font-size: 18px;
  font-weight: 300;
  margin-right: 12px;
}
.appf .temp-link a {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  color: #4e8bff;
  position: relative;
}
.appf .temp-link a::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #4e8bff;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}
.appf .temp-link a::after {
  content: "Click to copy link to clipboard";
  position: absolute;
  top: -25px;
  height: 20px;
  background: #4e8bff;
  color: white;
  font-size: 12px;
  line-height: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  wihite-space: nowrap;
  padding: 0 15px;
  width: 200px;
  text-align: center;
  font-weight: 500;
}
.appf .temp-link a:hover::before,
.appf .temp-link a:hover::after {
  visibility: visible;
  opacity: 1;
}
.appf .temp-link button {
  background: #4e8bff;
  height: 32px;
  padding: 0 24px;
  color: white;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: .3s;
}
.appf .temp-link button:hover {
  background: #3963b2;
}
.appf label sup {
  position: relative;
  top: -8px;
  font-size: 0.6em;
}

.appf .column-form li div.combo-select {
  display: flex;
  padding: 0 5px;
}
.appf .column-form li div.combo-select select {
  width: 25%;
  flex-grow: 1;
  border: 0px none;
  background: transparent;
}
.appf .column-form li div.combo-select input {
  width: 75%;
  border: 1px solid #ddd;
  margin-left: 5px;
}





.setup-details {
  display: flex;
  justify-content: space-between;
  margin-left: 60px;
  margin-right: 60px;
}
.setup-details>div>label {
  display: block;
}
.setup-details>div>label:last-of-type {
  margin-top: 15px;
}
.setup-details>div>span {
  display: block;
  font-weight: bold;
}

.setup-box .appf .buttons a.btn.btn-large {
  min-width: 120px;

}
.setup-box .appf .buttons a.btn.btn-disabled {
  background: #eee !important;
  color: #ccc !important;
  pointer-events: none;
}
.setup-box .appf .buttons a.btn.btn-disabled::before {
  border-right-color: #ccc;
}
.setup-box .appf .column-form.readonly {
  pointer-events: none;
}
.setup-box .start-build {
}
.setup-box .start-build div {
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}
.setup-box .start-build.visible>div {
  visibility: visible;
  opacity: 1;
}
.setup-box .start-build ul {
  position: relative;
  z-index: 10;
  background: white;
  padding: 20px;
  border-radius: 10px;
  top: 0;
}
.setup-box .start-build.visible ul {
  position: fixed;
  left: calc(50% - 300px);
  top: calc(50% - 175px);
  width: 600px;
}
.setup-box .start-build ul li {
  visibility: hidden;
  opacity: 0;
  margin-top: 10px;
  overflow: hidden;
}
.setup-box .start-build ul li.visible {
  opacity: 1;
  visibility: visible;
  transition: .3s;
}
.setup-box .start-build ul li span {
  float: right;
  padding: 2px 10px;
  border-radius: 4px;
  height: 20px;
  color: transparent;
}
.setup-box .start-build ul li span.done {
  background: #91e863;
  color: black;
}
.setup-box .implementation-note {
  margin: 20px 40px;
  padding: 20px;
  border: 1px solid #ccc;
}
.pricing-template.intake-pricing {
  
}
.pricing-template.intake-pricing h2 {
  height: 35px !important;
  background: #7a7a7a;
  color: white;
  font-size: 14px !important;
  line-height: 35px !important;
  padding-left: 26px;
  margin-top: 24px;
  margin: 20px 0 !important;
  font-weight: 300 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pricing-template.intake-pricing li input[type=checkbox] {
  display: none;
}
.pricing-template.intake-pricing li input[type=checkbox]+em {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  margin-top: -2px;
}
.pricing-template.intake-pricing li input[type=checkbox]:checked+em::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background: #337dff;
  content: "";
  position: absolute;
}
