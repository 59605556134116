.view-profile {
  >.tabs-header {
    padding: 12px 30px;
  }
  >.tabs-main {
    background: black;
    color: white;
    padding: 12px 30px;
    display: flex;
    position: relative;
    >ul {
      background: black;
      color: white;
      padding: 12px 12px 1px;
      justify-content: flex-start;
/*       justify-content: space-between; */
      display: flex;
      flex-wrap: wrap;
/*       border-bottom: 5px solid #357bba; */
      position: relative;
      >li {
        cursor: pointer;
        margin-right: 12px;
        margin-top: -2px;
        margin-left: -1px;
        border-radius: 4px;
        border: 1px solid #999;
        background: #151515;
        box-sizing: border-box;
        /*width: calc(25% - 5px);*/
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        &:hover {
          background: #393939;
          border-color: #727272;
        }
        &.active, &.active:hover {
          border: 3px solid #568cff;
          background: #1362ff;
/*           padding-top: 4px; */
/*           padding-bottom: 4px; */
        }
        a {
          display: block;
          padding: 10px 12px;
        }
      }

      >li.disabled {
        cursor: default !important;
        pointer-events: none;
        opacity: 0.5;
      }
    }
    .status {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      font-size: 14px;
    }
    .status2 {
      position: absolute;
      right: 10px;
      bottom: calc(100% + 10px);
      color: black;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  >.tabs-sub {
    padding: 12px 30px;
    background: #357bba;
    color: white;
    >ul {
      display: flex;
      flex-wrap: wrap;
      background: #357bba;
      /*margin: -15px -20px 15px;*/
      padding: 15px 25px 10px;
      padding-left: 0;
      font-size: 14px;
      >li {
        box-sizing: border-box;
        border: 1px solid #76acff;
        border-radius: 4px;
        margin-right: 12px;
        &.active {
          border-color: white;
        }
        a {
          padding: 10px 12px;
          display: block;
        /*   background: #357aba; */
          /*text-decoration: underline;*/
          color: white;
/*          margin-right: 8px;
          margin-bottom: 12px;*/
          transition: .3s;
        }
      }
    }
  }
  >.actions {
    padding: 12px 30px;
    ul {
      display: flex;
      flex-wrap: wrap;
      >li {
        cursor: pointer;
        margin-right: 8px;
        color: black;
        font-size: 16px;
        display: block;
        padding: 6px 20px;
        box-sizing: border-box;
        border: 1px solid #b3b3b3;
        border-radius: 6px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e4e4e4+0,cfcfcf+100 */
        background: rgb(228,228,228); /* Old browsers */
        background: -moz-linear-gradient(top,  rgba(228,228,228,1) 0%, rgba(207,207,207,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(228,228,228,1) 0%,rgba(207,207,207,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e4e4', endColorstr='#cfcfcf',GradientType=0 ); /* IE6-9 */
        &.disabled {
          cursor: default !important;
          pointer-events: none;
          opacity: .5;
        }
        &:hover, &.disabled:hover {
/*          
          border: 1px solid #b3b3b3;
          border-radius: 6px;
          background: #e1e1e1;  
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f2ff+0,deebff+100 */
          background: rgb(233,242,255); /* Old browsers */
          background: -moz-linear-gradient(top,  rgba(233,242,255,1) 0%, rgba(222,235,255,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(233,242,255,1) 0%,rgba(222,235,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f2ff', endColorstr='#deebff',GradientType=0 ); /* IE6-9 */
          border-color: #6192fc;
          color: #6192fc;
        }
        a {
          display: block;
          padding: 6px 20px;
          margin: -6px -20px;
        }
      }
    }
  }
  .merchant-panel .data-content .status,
  .merchant-panel .data-content .action-sub-buttons,
  .appview-list + div,
  .preq-form .appl-notes .new-note button {
    display: none;
  }
}
