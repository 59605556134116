.normal-table {
  background-color: #fff;
  border-collapse: collapse;
}
.normal-table thead tr {
  background-color: rgba(0,0,0,0.1);
}
.normal-table thead tr td, .normal-table thead tr th {
  padding: 7px 5px;
  white-space: nowrap;
}
.normal-table tr td {
  padding: 5px;
  border: 1px solid rgba(0,0,0,0.1);
  white-space: nowrap;
}
.normal-table tr td.error {
  background-color: rgb(253, 185, 185);
}

.content-width {
  margin: 0 auto;
  width: 1100px;
  max-width: calc(100% - 50px);
}

.matrix-table-wrapper {
  -webkit-box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.65);
  -moz-box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.65);
  box-shadow: 0px 5px 20px -5px rgba(0,0,0,0.65);
}

.matrix-table-wrapper .matrix-header {
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  background-color: #000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 20px;
}

.matrix-table-wrapper .matrix-header h2 span {
  width: 140px;
  float: right;
  text-align: center;
}
.matrix-table-wrapper .matrix-header h2 {
  font-weight: normal;
  font-size: 12px;
  color: #fff;
}
.matrix-table-wrapper .matrix-header h3 {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  margin-top: 20px;
}

.matrix-table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}
.matrix-table-wrapper table tr {
  vertical-align: text-top;
  color: #000;
}
.matrix-table-wrapper table tr:nth-child(odd) {
  background-color: #ececec;
}
.matrix-table-wrapper table tr.header {
  color: #fff;
  background-color: #4e8bff;
}
.matrix-table-wrapper table tr.header.text-left td {
  text-align: left;
}
.matrix-table-wrapper table tr.header.big td {
  padding: 35px;
}
.matrix-table-wrapper table tr td {
  font-size: 11px;
  padding: 5px 10px;
  height: 30px;
  width: 75px;
  box-sizing: border-box;
  vertical-align: text-top;
}
.matrix-table-wrapper table.small-padding tr td {
  padding: 5px 10px;
}
.matrix-table-wrapper table tr:not(.header,.bordered) td:last-child {
  border-left: 2px solid #4e8bff;
  border-right: 2px solid #4e8bff;
  text-align: center;
}

.matrix-table-wrapper table.bordered td {
  border: 1px solid #4e8bff;
}

.matrix-table-wrapper table tr td .checkbox {
  width: 15px;
  height: 15px;
}

.matrix-table-wrapper table tr td select {
  width: 100px;
  height: 25px;
}
.ver-allign {
  vertical-align: text-top;
}
.pen-checkbox {
  text-align: center;
}
.pen-checkbox i, .matrix-table-wrapper table tr td .checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid #4e8bff;
  border-radius: 2px;
  background-color: #fff;
  white-space: nowrap;
}
.pen-checkbox.checked i, .matrix-table-wrapper table tr td .checkbox.checked {
  background: black;
}
.pen-checkbox i:last-of-type {
  margin-right: 0;
}
.text-center {
  text-align: center;
}


.matrix-table-wrapper .element-toggle {
  
}
.matrix-table-wrapper .element-toggle.slin {
  display: flex;
  justify-content: center;
}
.matrix-table-wrapper .element-toggle.slin label {
  margin-right: 16px;
}
.matrix-table-wrapper .element-toggle label {
  display: flex;
}
.matrix-table-wrapper .element-toggle label input[type=text] {
  max-width: 200px;
  margin-left: 16px;
  background: white;
}
.matrix-table-wrapper .element-toggle label input[type=radio] {
  display: none;
}
.matrix-table-wrapper .element-toggle label span {
  display: block;
  box-sizing: border-box;
  margin-left: 12px;
  border: 1px solid #dcdcdc;
  background: white;
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.matrix-table-wrapper .element-toggle label span::before {
  display: block;
  box-sizing: border-box;
/*   background: yellow; */
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  content: "";
}
.matrix-table-wrapper .element-toggle label.checked span::before {
  background: black;
}

.matrix-table-wrapper.matrix-table-ext table tr td input:only-child,
.matrix-table-wrapper.matrix-table-ext table tr td select:only-child {
  width: 100%;
  box-sizing: border-box;
}
