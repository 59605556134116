.preform-outer {
  position: relative;
  padding-top: 120px;
  font-family: "Montserrat", sans-serif;
}
.preform-outer .appf h1 {
  font-family: "Montserrat", sans-serif;
}
.preform-outer .preformbg {
  height: 500px;
  position: absolute;
/*   z-index: -1; */
  top: 0;
  left: 0;
  right: 0;
  background: url("../img/preqformbg.jpg") 50% 50% no-repeat;
  background-size: cover;
}
.preform-outer .preformbg::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(67,130,252,.77);
}
.preform .cb-logo {
  float: right;
  width: 214px;
  height: 133px;
  background: url("../img/cardbiller-form.png") no-repeat 50% 50%;
  background-size: contain;
  overflow: hidden;
  text-indent: -1000px;
  margin-bottom: 24px;
}
.preform .cb-logo.intake {
  margin-top: -40px;
  margin-bottom: 0;
}
.preform h1 {
  color: #266dff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
}
.preform .form-info {
  font-size: 14px;
  line-height: 20px;
  margin: 12px 0;
}
.preform .form-info p:first-of-type {
  font-weight: bold;
}
.preform p.form-intro {
  line-height: 20px;
  font-size: 14px;
  color: black;
  font-weight: normal;
  text-align: left;
  margin: 12px 255px 24px 0;
  padding-left: 0;
}
.preform .pqf-progress {
  margin: 30px -40px 50px -40px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c7c7c7+0,d5d5d5+100 */
background: rgb(199,199,199); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(199,199,199,1) 0%, rgba(213,213,213,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(199,199,199,1) 0%,rgba(213,213,213,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(199,199,199,1) 0%,rgba(213,213,213,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7c7c7', endColorstr='#d5d5d5',GradientType=0 ); /* IE6-9 */
  display: flex;
  
}
.preform .pqf-progress.four li {
  width: 25%;
}
.preform .pqf-progress li {
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
  box-sizing: border-box;
  line-height: 22px;
/*   padding: 0 20px; */
  height: 70px;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  text-shadow: 1px 1px 1px #fff;
}
.preform .pqf-progress li:first-of-type {
  border-left: 0;
}
.preform .pqf-progress li:last-of-type {
  border-right: 0;
}
.preform .pqf-progress li .stat {
  display: none;
  text-shadow: none;
  color: white;
  font-size: 11px;
  font-weight: bold;
  background: #666;
  width: 100px;
  height: 22px;
  line-height: 22px;
}
.preform .pqf-progress li.current {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d8c333+0,b9a835+100 */
background: rgb(216,195,51); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(216,195,51,1) 0%, rgba(185,168,53,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(216,195,51,1) 0%,rgba(185,168,53,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(216,195,51,1) 0%,rgba(185,168,53,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8c333', endColorstr='#b9a835',GradientType=0 ); /* IE6-9 */
  color: black;
  font-weight: bold;
  text-shadow: none;
}
.preform .pqf-progress li.past {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#68ea4b+0,68ea4b+100 */
background: rgb(104,234,75); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(104,234,75,1) 0%, rgba(104,234,75,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(104,234,75,1) 0%,rgba(104,234,75,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(104,234,75,1) 0%,rgba(104,234,75,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68ea4b', endColorstr='#68ea4b',GradientType=0 ); /* IE6-9 */
  font-weight: bold;
  color: #45a330;
}
.preform .pqf-progress li.current .stat.ip {
  display: block;
  background: #cc5300;
}
.preform .pqf-progress li.past .stat.co {
  display: block;
  background: #45a330;
}
.preform .pqf-progress li.current:last-of-type {
  color: white;
  background: black;
}
.preform .pqf-progress li.current:last-of-type .stat.ip {
  display: none;
}


.pqcat-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: flex-start;
}
.pqcat-list>li {
  width: calc(25% - 12px);
  box-sizing: border-box;
  border: 2px solid #c7c7c7;
  border-radius: 8px;
  height: 158px;
  margin-bottom: 16px;
  position: relative;
  color: black;
  margin-right: 16px;
}
.pqcat-list>li:nth-of-type(4n+2) {
  margin-right: 0;
}

.pqcat-list>li>a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 100px;
  text-align: center;
  font-size: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
.pqcat-list>li.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.pqcat-list>li.selected,
.pqcat-list>li:hover {
  color: white;
  border-color: #4181f8;
  background: #4181f8;
}
.pqcat-list>li.selected>a::before,
.pqcat-list>li:hover>a::before {
  filter: invert(1);
}
.pqcat-list>li>a::before {
  position: absolute;
  width: 100%;
  top: 30px;
  height: 64px;
  left: 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: "";
}
.pqcat-list>li.ac01>a::before {
  background-image: url("../img/ico-af1.png");
}
.pqcat-list>li.ac02>a::before {
  background-image: url("../img/ico-af2.png");
}
.pqcat-list>li.ac03>a::before {
  background-image: url("../img/ico-af3.png");
}
.pqcat-list>li.ac04>a::before {
  background-image: url("../img/ico-af4.png");
}
.pqcat-list>li.pc01>a::before {
  background-image: url("../img/pcat01.png");
}
.pqcat-list>li.pc02>a::before {
  background-image: url("../img/pcat02.png");
}
.pqcat-list>li.pc03>a::before {
  background-image: url("../img/pcat03.png");
}
.pqcat-list>li.pc04>a::before {
  background-image: url("../img/pcat04.png");
}
.pqcat-list>li.pc05>a::before {
  background-image: url("../img/pcat05.png");
}
.pqcat-list>li.pc06>a::before {
  background-image: url("../img/pcat06.png");
}
.pqcat-list>li.pc07>a::before {
  background-image: url("../img/pcat07.png");
}
.pqcat-list>li.pc08>a::before {
  background-image: url("../img/pcat08.png");
}
.pqcat-list>li.pc09>a::before {
  background-image: url("../img/pcat09.png");
}
.pqcat-list>li.pc10>a::before {
  background-image: url("../img/pcat10.png");
}
.pqcat-list>li.pc11>a::before {
  background-image: url("../img/pcat11.png");
}
.pqcat-list>li.pc12>a::before {
  background-image: url("../img/pcat12.png");
}
.pqcat-list>li.facilitator,
.pqcat-list>li.other {
  width: calc(50% - 8px);
  width: 100%;
  height: 68px;
  line-height: 64px;
  margin-right: 0;
}
.pqcat-list>li.card-select {
  width: calc(50% - 8px);
  height: 68px;
  line-height: 64px;
}
.pqcat-list>li.plain-item {
  width: calc(51%);
  margin-left: auto;
  margin-right: auto;
  height: 68px;
  line-height: 64px;
}
.pqcat-list>li.facilitator {
}
.pqcat-list>li.facilitator>a,
.pqcat-list>li.other>a,
.pqcat-list>li.plain-item>a,
.pqcat-list>li.card-select>a {
  padding-top: 0;
}
.pqcat-list>li.other>a::before {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  vertical-align: middle;
  top: 0;
  left: 0;
  background-image: url("../img/pcq.png");
}
.preform .pqcat-select h3 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background: transparent;
  color: black;
  margin: 15px 0;
}
.preform .buttons {
  margin: 60px -40px -30px;
  background: #dddddd;
  justify-content: flex-end;
}
.preform .buttons .btn-large {
  height: 80px;
  line-height: 80px;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1ac000+0,149500+100 */
background: rgb(26,192,0); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(26,192,0,1) 0%, rgba(20,149,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(26,192,0,1) 0%,rgba(20,149,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(26,192,0,1) 0%,rgba(20,149,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ac000', endColorstr='#149500',GradientType=0 ); /* IE6-9 */
}
.preform .buttons .btn-back {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#606060+0,4a4a4a+100 */
background: rgb(96,96,96); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(96,96,96,1) 0%, rgba(74,74,74,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(96,96,96,1) 0%,rgba(74,74,74,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(96,96,96,1) 0%,rgba(74,74,74,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606060', endColorstr='#4a4a4a',GradientType=0 ); /* IE6-9 */
  margin-right: 0;
  margin-right: auto;
}
.preform .buttons .btn.btn-back::before {
  display: none;
}
.preform form h2 {
  background: transparent;
  color: black;
  border-bottom: 3px solid #dddddd;
  margin-bottom: 16px;
  display: inline-block;
}
.preform form h2 em {
  font-size: 16px;
  font-weight: bold;
  margin-left: 0;
}
.preform .preq-form ul.preform-sp3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.preform .preq-form ul.preform-sp3.tol {
  justify-content: flex-start;
}
.preform .preq-form .preform-sp3 li {
  display: block;
  width: calc(22%);
  flex-grow: 1;
  margin: 0 2%;
}
.preform .preq-form .preform-sp3 li.disabled {
  opacity: .5;
}
.preform .preq-form .preform-sp3 li.no-grow {
  flex-grow: 0;
}
.preform .preq-form .preform-sp3 li:first-of-type {
  margin-left: 0;
}
.preform .preq-form .preform-sp3 li:last-of-type {
  margin-right: 0;
}
.preform .preq-form li > label {
  border: 0;
  width: 100%;
  padding: 0;
}
.preform .preq-form li:nth-of-type(2n) .f2split,
.preform .preq-form li:nth-of-type(2n) .validator,
.preform .preq-form li:nth-of-type(2n) label {
  background: transparent;
}
.preform .preq-form li .validator {
  width: 100%;
  box-sizing: border-box;
  border: 0;
}
.preform .preq-form li select,
.preform .preq-form li input[type="password"],
.preform .preq-form li input[type="text"] {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 6px;
  border-color: #b2b2b2;
}
.preform .preq-form li select {
  border-width: 1px;
  background: white;
  width: 100%;
}
.preform .preq-form li label {
  font-weight: 700;
  font-size: 12px;
}
.preform .preq-form li label strong {
  font-weight: 700;
}
.preform .preq-form .new-ubo a {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: 10px auto;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
}
.preform .preq-form .new-ubo a::before {
  position: relative;
  display: block;
  left: 0;
  top: 0;
  margin-left: 12px;
  font-size: 18px;
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.preform .preq-form .preform-sp3 {
  position: relative;
}
.preform .preq-form .preform-sp3 li.remove {
  position: absolute;
  left: calc(100% + 6px);
  top: calc(50% - 6px);
  width: 13px;
}
.preform .maincheck {
  display: block;
  width: 100%;
  height: 94px;
  box-sizing: border-box;
  padding: 30px 0;
  line-height: 34px;
  background: #eeeeee;
  text-align: center;
  color: black;
  border-bottom: 3px solid #dddddd;
  margin-top: -16px;
  margin-bottom: 16px !important;
  font-weight: 500;
  font-size: 16px;
}
.preform .maincheck input {
  display: none;
}
.preform .maincheck span {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 10px;
  border: 1px solid #337dff;
  background: white;
  position: relative;
}
.preform .maincheck span::before {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 2px;
  content: "";
  display: block;
}
.preform .maincheck input:checked + span::before {
  background: #337dff;
}
.preform .reds .element-toggle {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.preform .reds .element-toggle input[type="text"] {
  width: 80px;
}
.preform .element-toggle label {
  border-color: transparent !important;
  margin-right: 12px;
}
.preform .preq-form li .element-toggle label span {
  margin-left: 0;
}
.preform .preq-form li.fullw1 {
  display: block;
}
.preform .preq-form li.fullw1 textarea {
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 6px;
  border: 1px solid #b2b2b2;
}
.preform .preq-form li .element-toggle label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 10px;
  border: 1px solid #337dff;
  background: white;
  position: relative;
}
.preform .preq-form li .element-toggle label span::before {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 2px;
  content: "";
  display: block;
}
.preform .preq-form li .element-toggle label.checked span::before {
  background: #337dff;
}
.preform .preq-form li .noinput .element-toggle {
  display: flex;
}
.preform .preq-form li .noinput .element-toggle label input[type="text"] {
  display: none;
}
.preform .preq-form .single-field {
  padding: 10px 0 0 0;
  border: 0;
}
.preform .forta {
  font-size: 12px;
  font-weight: 500;
}
.preform.appf form p.intro {
  font-weight: 500;
  font-size: 14px;
}
.preform.appf .element-checkbox-line > .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
}
.preform.appf .element-checkbox-line > .title .file-obj {
  margin-left: 16px;
}
.preform.appf .element-checkbox-line > .toggleMore {
  width: 25px;
  height: 25px;
  font-size: 22px;
  line-height: 25px;
  margin-top: 26px;
}
.preform.appf .notes,
.preform.appf .notes li {
  font-size: 12px;
  font-weight: 500;
  color: black;
  line-height: 28px;
}
.preform .pre-done {
  padding-top: 70px;
  padding-bottom: 135px;
}
.preform .pre-done h1 {
  text-align: center;
  text-transform: none;
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin-top: 70px;
}
.preform .pre-done p {
  color: #5e5e5e;
  margin: 24px 0;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
}
.preform .pre-done {
  text-align: center;
}
.preform .pre-done .btn.btn-large {
  height: 55px;
  line-height: 55px;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  padding: 0 24px;
  background: rgb(26,192,0);
  display: inline-block;
  background: rgb(96,96,96);
  background: -moz-linear-gradient(top, rgba(96,96,96,1) 0%, rgba(74,74,74,1) 100%);
  background: -webkit-linear-gradient(top, rgba(96,96,96,1) 0%,rgba(74,74,74,1) 100%);
  background: linear-gradient(to bottom, rgba(96,96,96,1) 0%, rgba(74,74,74,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606060', endColorstr='#4a4a4a',GradientType=0 );
  margin-right: 0;
  font-size: 12px;
  min-width: 200px;
}

.preform .pqf-progress li.current {
  position: relative;
}
.appf.pfx .progress-bar li.current::after,
.appf.pfx .pqf-progress li.current::after,
.preform .pqf-progress li.current::after {
  content: '';
  position: absolute;
  background: white;
  width: 10px;
  height: 10px;
  left: calc(50% - 5px);
  bottom: -5px;
  transform: rotate(45deg);
}
html .appf.pfx .progress-bar li.current::after {
  background: white;
  border-radius: 0;
}
.appf form h2>span.total-percent {
  color: white;
  background: black;
  display: inline-block;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 3px;
  margin-left: 5px;
  line-height: 20px;
}
.preform .preq-form li .zip-code {
  display: flex;
}
.preform .preq-form li .zip-code input[type="text"] {
  flex-shrink: 1;
  width: 0;
  flex-grow: 1;
}
.preform .preq-form li .zip-code span {
  margin: 0 4px;
}

.preform.appf .temp-link {
  margin: 20px -40px -30px -40px;
  clear: both;
  height: 50px;
  box-sizing: border-box;
  padding: 10px 40px;
  font-size: 14px;
  color: black;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d6af50+0,ffcc5e+50,ffcc5e+100 */
  background: #d6af50; /* Old browsers */
  background: -moz-linear-gradient(top,  #d6af50 0%, #ffcc5e 50%, #ffcc5e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #d6af50 0%,#ffcc5e 50%,#ffcc5e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #d6af50 0%,#ffcc5e 50%,#ffcc5e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6af50', endColorstr='#ffcc5e',GradientType=0 ); /* IE6-9 */
  text-align: center;
}
.preform.appf .temp-link label {
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
}
.preform.appf .temp-link a {
  color: black;
}
.preform.appf .temp-link a::before {
  display: none;
}
.preform.appf .temp-link a::after {
  content: '';
  background: none;
  display: inline-block;
  position: static;
  width: 16px;
  height: 16px;
  background: url("../img/copy-to-clipboard.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
  opacity: 1;
  visibility: visible;
  transform: none;
  vertical-align: middle;
  margin-left: 5px;
}
.preform.pfx {
  
}



.appf.pfx .progress-bar {
  margin: 30px -40px 50px -40px;
}

.preform.pfx .progress-bar {
  margin: 30px -40px 50px -40px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c7c7c7+0,d5d5d5+100 */
background: rgb(199,199,199); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(199,199,199,1) 0%, rgba(213,213,213,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(199,199,199,1) 0%,rgba(213,213,213,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(199,199,199,1) 0%,rgba(213,213,213,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c7c7c7', endColorstr='#d5d5d5',GradientType=0 ); /* IE6-9 */
  display: flex;
  
}
.preform.pfx .progress-bar li {
  padding: 0;
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
  box-sizing: border-box;
  line-height: 18px;
/*   padding: 0 20px; */
  height: 70px;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #b9b9b9;
  font-size: 14px;
  text-shadow: 1px 1px 1px #fff;
}
.preform.pfx .progress-bar li span {
  display: block;
  margin: 0 8px;
}
.preform.pfx .progress-bar li:first-of-type {
  border-left: 0;
}
.preform.pfx .progress-bar li:last-of-type {
  border-right: 0;
}
.preform.pfx .progress-bar li span::after {
  display: none;
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 10px;
  text-shadow: none;
  color: white;
  font-size: 11px;
  font-weight: bold;
  background: #666;
  width: 110px;
  height: 22px;
  line-height: 22px;
  content: "";
}
.preform.pfx .progress-bar li.current {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d8c333+0,b9a835+100 */
background: rgb(216,195,51); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(216,195,51,1) 0%, rgba(185,168,53,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(216,195,51,1) 0%,rgba(185,168,53,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(216,195,51,1) 0%,rgba(185,168,53,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8c333', endColorstr='#b9a835',GradientType=0 ); /* IE6-9 */
  color: black;
  font-weight: bold;
  text-shadow: none;
}
.preform.pfx .progress-bar li.completed span,
.preform.pfx .progress-bar li.current span {
  margin-bottom: 30px;
}
.preform.pfx .progress-bar li.completed {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#68ea4b+0,68ea4b+100 */
background: rgb(104,234,75); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(104,234,75,1) 0%, rgba(104,234,75,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(104,234,75,1) 0%,rgba(104,234,75,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(104,234,75,1) 0%,rgba(104,234,75,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68ea4b', endColorstr='#68ea4b',GradientType=0 ); /* IE6-9 */
  font-weight: bold;
  color: #45a330;
}
.preform.pfx .progress-bar li.current span::after {
  display: inline-block;
  background: #cc5300;
  content: "In progress";
}
.preform.pfx .progress-bar li.completed span::after {
  display: block;
  background: #45a330;
  content: "Complete";
}
.preform.pfx .progress-bar li.current:last-of-type {
  color: white;
  background: black;
}
.preform.pfx .progress-bar li.current:last-of-type .stat.ip {
  display: none;
}
.preform.pfx .progress-bar li::after {
  background: transparent;
}
.preform.pfx .progress-bar li.current::after {
  background: transparent;
}
.appf.pfx .progress-bar li.completed::before {
  background: transparent;
}
.appf.pfx .form-step {
  padding: 0;
}
.appf.pfx .form-step.fx section>div:nth-of-type(2n) .element-checkbox-line {
  background: white;
}
.appf.pfx .form-intro.fx {
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  margin-right: 0;
  padding-right: 0;
}
.appf.pfx .form-intro strong {
  font-weight: bold;
}
.appf.pfx .element-checkbox-line > label {
  width: 27px;
  height: 27px;
  min-height: 27px;
  background: transparent;
}
.appf.pfx .element-checkbox-line > label.checked {
  background: transparent;
}
.appf.pfx .element-checkbox-line > label span {
  border: 0;
  background: transparent;
}
.appf.pfx .element-checkbox-line > label span::before {
  width: 16px;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  width: 16px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #337dff;
  background: white;
}
.appf.pfx .element-checkbox-line > label span::after {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background: #337dff;
  bottom: 0;
}
.appf.pfx .element-checkbox-line > .title {
  padding: 0;
/*   height: 27px; */
  line-height: 27px;
}
.preform.appf.pfx .element-checkbox-line {
  margin-bottom: 0;
}
.preform.appf.pfx .element-checkbox-line:nth-of-type(2n) {
  background: white;
}
.preform.appf.pfx .element-checkbox-line > .toggleMore {
  width: 19px;
  height: 19px;
  line-height: 19px;
  margin-right: 6px;
  margin-top: 4px;
  font-size: 14px;
}
.appf.pfx .form-step section h2 {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 0;
  height: 30px;
  line-height: 30px;
  display: block;
}
.preform form h2 {
  display: block;
}
.page-7-right-text {float: right;font-size: 10px; margin: 0 !important; text-align: right;}
.clearfx {clear: both;}

.preform.appf.pfx .notes, .preform.appf .notes li {
  line-height: 24px;
}
.appf.pfx form h2 > span {
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 50px;
}
.appf.pfx form h2 > span.website-checkbox {
  font-size: 12px;
}
.appf.pfx form h2 > span.website-checkbox label.radio {
  margin-left: 30px;
}
.appf.pfx form h2 > span.website-checkbox input[type=radio] {
  display: none;
}
.appf.pfx form h2 > span.website-checkbox input[type=radio] + i {
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
}
.appf.pfx form h2 > span.website-checkbox input[type=radio]:checked + i {
  background: white;
}
.appf.pfx form h2 > span.website-checkbox input[type=radio]:checked + i::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
  bottom: 0;
}
.appf.pfx form p.section-intro {
  margin: 15px 0;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.appf.pfx form p.section-intro label.checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 8px;
  color: black;
}
.appf.pfx form p.section-intro label.checkbox input {
  display: none;
}
.appf.pfx form p.section-intro label.checkbox i {
  display: block;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  margin-left: 12px;
}
.appf.pfx form p.section-intro label.checkbox input:checked + i::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}
.appf .pgf-main-cb {
  border-top: 3px solid #dddddd;
  border-bottom: 3px solid #dddddd;
  background: #eeeeee;
  padding-left: 275px;
  padding-top: 20px;
  padding-bottom: 12px;
}
.appf.pfx .pgf-main-cb.center {
  padding-left: 0;
  padding-bottom: 20px;
  text-align: center;
}
.appf.pfx .pgf-main-cb.center h3 {
  font-size: 16px;
  font-weight: 500;
  color: black;
  background: transparent;
  margin: 0;
}
.appf.pfx h3 sup,
.appf.pfx h2 sup,
.appf.pfx h1 sup {
  display: inline-block;
  vertical-align: top;
  font-size: .5em;
  margin-top: -2px;
}
.appf.pfx .pgf-main-cb p.intro {
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 20px;
}
.appf.pfx .pgf-main-cb .toggle-main label {
  margin-bottom: 8px;
  color: #a0a0a0;
  font-size: 12px;
  font-weight: normal;
}
.appf.pfx .pgf-main-cb .toggle-main {
  margin: 0;
}
.appf.pfx .pgf-main-cb .toggle-main label i {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.appf.pfx .pgf-main-cb .toggle-main label input:checked + i::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}
.appf .column-form.type2.grayed li > div > div {
  background: transparent;
}
.appf .column-form.grayed li > div > div input {
  background: white;
}
.appf .column-form.grayed li > div > div .element-toggle input {
  margin-top: 12px;
  width: 120px;
}
.appf.appnat .column-form.type2,
.appf.pfx .column-form.type2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.row5 {margin-bottom: 0px !important}
.appf.pfx .column-form.type2.grayed {
  background: #dedede;
  padding: 20px 30px;
}
.appf.pfx .column-form.type2>li {
  width: calc(25% - 30px);
  display: block;
  margin-top: 30px;
}
.appf.pfx .column-form.type2.row5>li {
  width: calc(20% - 30px);
}
.appf.pfx .column-form.type2.grayed>li {
  margin-top: 0px;
}
.appf.pfx .column-form.type2>li.sthird {
  width: calc(33.333% - 30px);
}
.appf.pfx .column-form.type2>li.sthird2 {
  width: calc(66.666% - 30px);
}
.appf.pfx .column-form.type2>li.fwidth {
  width: 100%;
  display: flex;
}
.appf.pfx .column-form.type2>li.split5 {
  width: calc(20% - 30px);
}
.appf.pfx .column-form.type2>li.split5 label {
  min-height: 46px;
}
.appf.pfx .column-form.type2>li.fwidth .complex-label {
  width: 330px;
}
.appf.pfx .column-form.type2>li.fwidth .complex-label>label {
  margin-left: 0;
  display: block;
  float: none;
}
.appf.pfx .column-form.type2>li.halfw {
  width: calc(50% - 30px);
}
.appf.pfx .column-form.type2>li.qw {
  width: calc(25% - 0px);
}
.appf.pfx .column-form.type2>li.tqw {
  width: calc(75% - 30px);
}
.appf.pfx .column-form.type2>li.fwidth .field {
  width: calc(100% - 330px);
}
.appf.pfx .column-form.type2 li > div > div {
  text-align: left;
}
.appf.pfx .column-form.type2.bh li.head {
  height: 48px;
  box-sizing: border-box;
  line-height: 48px;
  font-size: 12px;
  padding: 0 12px;
  background: black;
  color: white;
  position: relative;
}
.appf.pfx .column-form.type2.bh li.head.collapsable::after {
  content: "-";
  position: absolute;
  line-height: 16px;
  top: calc(50% - 8px);
  right: 20px;
  color: white;
  font-size: 16px;
}
.appf.pfx .column-form.type2.bh li.head.collapsable.collapsed::after {
  content: "+";
}
.appf.pfx .column-form.type2.bh li.head .remove {
  font-size: 12px;
  display: inline-block;
  margin-left: 12px;
}
.appf.pfx .column-form.type2.bh li.head .remove span {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  line-height: 10px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #e02000;
  margin-right: 6px;
}
.appf.pfx .column-form.type2 li.head {
  width: 100%;
  margin-top: 0;
}
.appf.pfx .column-form.type2 li.head label {
  display: none;
}
.appf.pfx .column-form.type2 li.head label.copy {
  display: inline-block;
}
.appf.pfx .column-form.type2 li > div {
  width: 100%;
  display: block;
}
.appf.pfx .column-form.type2 li > div > div {
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}
.appf.pfx .column-form.type2 li:nth-of-type(2n) > div > div,
.appf.pfx .column-form.type2 li:nth-of-type(2n) > label {
  background: transparent;
}
.appf.pfx .column-form.type2 li > label {
  border: 0;
  padding: 3px 0;
  font-size: 12px;
  font-weight: bold;
}
.appf.pfx .column-form.type2 li > label.dh {
  min-height: 50px;
}
.appf.pfx .column-form.type2 li.large-title > label {
  font-size: 16px;
  font-weight: 500;
}
.appf.pfx .column-form.type2 li div .amount-currency input,
.appf.pfx .column-form.type2 select,
.appf.pfx .column-form.type2 input[type="password"],
.appf.pfx .column-form.type2 input[type="text"] {
  height: 22px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #b2b2b2;
}
.appf.pfx .column-form.type2 input[type="password"] {
  width: 100%;
}
.appf.pfx .column-form.type2 li textarea {
/*   height: 22px; */
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #b2b2b2;
}
.appf.pfx .column-form.type2 li div .amount-currency {
  padding: 0;
}
.appf.pfx .column-form.type2 li.head + li > label::before {
  display: none;
}
.appf.pfx .column-form.type2 li.head + li > div > div::before {
  display: none;
}
.appf.pfx .column-form.type2 li .pfx-info {
  color: #939393;
  font-size: 12px;
  display: block;
  clear: left;
}
.appf.pfx .column-form.type2 li div.combo-select {
  padding: 0;
}
.appf.pfx .column-form.type2 li div.combo-select select {
  width: 30%;
}
.appf.pfx .column-form.type2 li div.combo-select input {
  width: 70%;
}
.appf.pfx .column-form.type2 select {
  padding: 0;
}
.appf.pfx .column-form.type2 li div .element-toggle label {
  width: auto;
  font-size: 12px;
  margin-right: 12px;
  font-weight: normal;
  line-height: 20px;
  margin-top: 4px;
}
.appf.pfx .column-form.type2.grayed li div .element-toggle label {
  margin-left: 0;
  width: 60px;
}
.appf.pfx .column-form.type2 li div .element-toggle label:first-of-type {
  margin-left: 0;
}
.appf.pfx .column-form.type2 li div .element-toggle label > span {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  margin-top: -2px;
}
.appf.pfx .column-form.type2 li div .element-toggle label.checked > span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}
.appf.pfx .column-form.type2 li div .element-toggle label > input[disabled] + span {
  border-color: #999;
}
.appf.pfx .column-form.type2 li div .amount-currency select {
  width: 25%;
  margin-left: 12px;
}
.appf.pfx .column-form.type2 .head > div > div {
  font-size: 12px;
  padding: 0 12px;
  height: 47px;
  line-height: 47px;
  box-sizing: border-box;
  text-align: left;
}
.appf.pfx .column-form.type2 li.head div label {
  float: none;
  font-size: 10px;
  font-weight: normal;
  margin-left: 12px;
}
.appf.pfx .column-form.type2 .element-toggle>.opts {
  display: flex;
}
.appf.pfx .add-dir a {
  display: inline-block;
  height: 33px;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  line-height: 31px;
  position: relative;
  padding: 0 33px 0 13px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.appf.pfx .add-dir a span {
  display: block;
  position: absolute;
  right: 3px;
  top: 2px;
  width: 25px;
  height: 25px;
  border-radius: 33px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  background: #91e863;
  color: #1b4f00;
}
.appf.pfx .add-dir.adx {
  text-align: center;
  border-top: 3px solid #ddd;
  padding-top: 20px;
}
.appf .pgf-main-cb.type2 {
  display: flex;
}
.appf .pgf-main-cb.type2 .intro {
  margin-bottom: 0;
  margin-right: 12px;
}
.appf .pgf-main-cb.type2 .toggle-main {
  display: flex;
}
.appf .preq-form .pgf-main-cb.type2 {
  margin-bottom: 30px;
}
.appf .preq-form .pgf-main-cb.type2 p.intro {
  margin: 3px 10px 0 0;
  font-weight: 500;
  font-size: 16px;
}
.appf .preq-form .pgf-main-cb.type2 .toggle-main {
  margin: 0;
}
.appf .preq-form .pgf-main-cb.type2 .toggle-main label {
  background: transparent;
  margin-bottom: 8px;
  color: #a0a0a0;
  font-size: 12px;
  font-weight: normal;
}
.appf .preq-form .pgf-main-cb .toggle-main label input:checked + i::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background: #337dff;
  content: "";
  position: absolute;
}
.appf .preq-form .pgf-main-cb.type2 .toggle-main label i {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.appf .pgf-main-cb.type2 .toggle-main li {
  margin-bottom: 0;
  margin-right: 12px;
}
.appf .pgf-main-cb.type2 .toggle-main li.disabled {
  opacity: .5;
}
.appf .pgf-main-cb.type2 li div.business-length {
  
}
.appf.pfx .type2 li div.business-length input {
  margin-left: 0;
  width: 45px;
}
.appf.pfx .type2 li div.business-length label {
  font-size: 12px;
  margin: 0 12px 0 8px;
}
.appf.pfx .column-form.type2 li div.slin label {
  float: left;
}
.appf.pfx .ext-h4 h4 {
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 3px;
}
.appf.pfx .ext-h4 .subtitle {
  font-weight: normal;
  font-size: 12px;
  color: #999;
}
.appf.pfx .currency-select {
  width: 32%;
  display: block;
}
.appf.pfx .currency-select .valiator {
  text-align: right;
  margin-left: auto;
}
.appf.pfx .currency-select select {
  width: 80%;
  display: block;
  margin-left: auto;
}
.appf.pfx .currency-select label {
  white-space: nowrap;
  color: #999;
  font-weight: normal;
  font-size: 12px;
  margin-left: 0;
  display: block;
  text-align: right;
}

.appf.pfx .column-form.type3 {
  margin-bottom: 20px;
}
.appf.pfx .column-form.type3 li.head {
  background: black;
  color: white;
}
.appf.pfx .column-form.type3 li.head label {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.appf .column-form.type3 li:first-of-type > div > div {
  border-top: none;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 20px 10px;
}
.appf .column-form.type3 li.head + li > label::before,
.appf .column-form.type3 li.head + li > div > div::before {
  display: none;
}
.appf .column-form.type3 li > label {
  font-size: 12px;
  border-right: 0;
}
.appf .column-form.type3 li > div {
  border-right: 1px solid #dedede;
}
.appf .column-form.type3 li > div > div {
  margin-left: 0;
  border: none;
  padding: 5px;
}
.appf .column-form.type3 li > div > div input {
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
}
.appf .column-form.type3 li:last-of-type {
  padding-bottom: 5px;
  position: relative;
}
.appf .column-form.type3 li:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: #dedede;
}
.appf.pfx .column-form.type2 li div label > span {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  margin-top: -2px;
}
.appf.pfx .column-form.type2 li div label > input[disabled] + span {
  border-color: #ccc;
}
.appf.pfx .column-form.type2 li div label.checked > span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background: #337dff;
  content: "";
  position: absolute;
}
.appf.pfx .column-form.type2 li div label {
  width: auto;
  font-size: 12px;
  margin-right: 12px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 4px;
}
.appf.pfx .column-form.type2 li div.disabled label {
  opacity: .5;
}
.appf .column-form li.half-split .multiselect li {
  width: calc(50% - 6px);
  flex-wrap: wrap;
  margin-bottom: 2px;
  height: auto;
}
.appf .column-form li.split-tri .multiselect li {
  width: calc(33% - 6px);
  flex-wrap: wrap;
  margin-bottom: 2px;
  height: auto;
}
.appf.pfx .column-form li .multiselect li .validator input {
  height: 22px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #b2b2b2;
  margin-left: 0;
  width: 100%;
}
.appf.pfx .column-form li .multiselect li .validator input[disabled] {
  visibility: hidden;
}
.appf.pfx .column-form.type2 li .multiselect div label {
  margin-top: 0;
}
.appf.pfx .column-form.type2 li label > em {
  margin-right: 30px;
  background: black;
  font-size: 16px;
  font-weight: normal;
}
.appf.pfx .column-form li.fwx .multipercent > div {
  width: calc(16.66% - 10px);
}
.appf.pfx .column-form.type2 > li.fwidth.fwx .field {
  width: calc(100% - 250px);
}
.appf.pfx .column-form li .multipercent > div > span.pfx-info {
  width: 100%;
}
.appf.pfx .column-form li .multipercent > div > input + span {
  margin-left: -14px;
  margin-top: -2px;
}
.appf.pfx .column-form li .multirow .multiselect {
  display: block;
}
.appf.pfx .column-form.type2 > li.tqw.singlerow {
  width: calc(80% - 30px);
  display: flex;
}
.appf.pfx .column-form.type2 li.singlerow > div {
  width: 75%;
}
.appf.pfx .column-form.type2 li.singlerow > label {
  width: 20%;
}
.appf.pfx .column-form.type2 li.singlerow label > em {
  float: none;
}
.appf.pfx .footnotes {
  font-size: 12px;
  color: #787878;
}
.appf.pfx .bank-account-box > a.remove {
  right: 0;
  background-color: #cc5300;
}
.appf.pfx form h3.list-heading label {
  float: none;
}
.appf.pfx form h3.list-heading {
  background: black;
}
.appf.pfx .last-step-head {
  margin: 0 0 30px 0;
}
.appf.pfx .form-step section .last-step-head h2 {
  border-bottom: 3px solid #dddddd;
  margin-bottom: 24px;
}
.appf.pfx .last-step .validator .element-checkbox-line {
  margin-bottom: 6px;
  height: auto;
}
.appf.pfx .last-step .validator:nth-of-type(2n) .element-checkbox-line {
  background: white;
}
.appf.pfx .last-step .file-obj {
  border: 1px solid #dadada;
  padding: 1px 2px;
  line-height: 20px;
  margin-right: 5px;
  background: #f3f3f3;
}
.appf.pfx .last-step .element-checkbox-line > label span::after {
  width: 26px;
  height: 30px;
  text-align: center;
  line-height: 18px;
  text-align: center;
  background: url("../img/check.png") no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -3px;
  left: 0;
  top: auto;
}
.appf.pfx .last-step .element-checkbox-line > .title {
  height: auto;
}
.appf.pfx .last-step .element-checkbox-line label,
.appf.pfx .last-step .element-checkbox-line .toggleMore {
  flex-shrink: 0;
}
.appf.pfx .element-checkbox-line > .title .more {
  font-size: 11px;
  color: #848484;
  padding: 6px 0;
}
.appf.pfx .buttons .btn-large,
.appf.pfx .buttons a.btn.btn-large {
  border-radius: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 80px;
  min-width: 150px;
  padding: 0;
}
.appf.pfx .buttons a.btn.btn-large.btn-warn {
  background: #eab100;
  color: black;
}
.appf.pfx .buttons {
  justify-content: space-between;
}
.appf.pfx .buttons .btn-large:only-child {
  margin-left: auto;
}
.appf.pfx .buttons .btn-back {
  background: black;
  margin-right: 0;
}
.appf.pfx .buttons .btn-success {
  background: rgb(26,192,0);
  background: -moz-linear-gradient(top, rgba(26,192,0,1) 0%, rgba(20,149,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(26,192,0,1) 0%,rgba(20,149,0,1) 100%);
  background: linear-gradient(to bottom, rgba(26,192,0,1) 0%, rgba(20,149,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ac000', endColorstr='#149500',GradientType=0 );
}
.appf.pfx .buttons .btn-back::before {
  display: none;
}
.appf.pfx .buttons .btn.btn-next::after {
  display: none;
}
/*.appf.pfx .column-form.type2::after {
  content: "";
  flex: auto;
}*/
.appf.pfx .notes li::before {
  margin-right: 6px;
}
.appf.pfx .column-form.type2 li div label.copy span {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #000;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.appf.pfx .column-form.type2 li div label.copy input:checked + span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}
.appf.pfx .column-form.type2 li > div.ve.ve2 textarea {
  border: 1px solid #bebebe;
}
.appf.pfx .form-step section h2.bline {
  border-bottom: 3px solid #ddd;
  margin-bottom: 24px;
}
.appf.pfx .bank-accounts > div:first-of-type .remove {
  display: none;
}
.bn-con {
  font-style: normal;
}
.preform.pfx .preq-form li .validator.ag-multi .multiselect {
  display: flex;
}
.preform.pfx .preq-form li .validator.ag-multi .multiselect li {
  width: auto;
  flex-grow: 0;
  font-weight: 400;
}
.preform.pfx .preq-form li .validator.ag-multi label span {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #000;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.preform.pfx .preq-form li .validator.ag-multi label input {
  display: none;
}
.preform.pfx .preq-form li .validator.ag-multi label input:checked + span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}
.appf.pfx .column-form li div.element-toggle {
  overflow: hidden;
}
.appf.pfx .column-form li .complex div.element-toggle {
  display: flex;
  font-weight: bold;
  font-size: 12px;
}
.appf.pfx .column-form li .complex div.element-toggle label {
  font-weight: bold;
  font-size: 12px;
}
.appf.pfx .column-form li .complex div.element-toggle>.opts {
  width: 100px;
}
.appf.pfx .column-form li .complex div.element-toggle>.opts {
  flex-direction: column;
}
.appf.pfx .column-form li .complex div.element-toggle>:last-child {
  flex-grow: 1;
}
.appf.pfx .column-form li .complex div.element-toggle>:last-child input[type=text] {
  width: 100px;
  display: inline-block;
}
.clearfx {
  clear: both;
}

.preform.appf.pfx-simulation .temp-link {
  margin-bottom: 0;
}
.preform.pfx.pfx-simulation .buttons {
  display: none;
}
.preform.pfx.pfx-simulation form.form-step:last-of-type .buttons {
  display: flex;
}
.appf.pfx .quest .column-form.type2 li.other {
  max-width: 190px;
}
.appf.pfx .quest .column-form.type2 li.other .validator {
  font-size: 12px;
  font-weight: bold;
}
.appf.pfx .intake h3 span {
  margin-left: 40px;
  font-weight: 500;
}
.appf.pfx .intake h3 span em {
  margin-left: 20px;
}
.appf.pfx .intake .intake-field {
  display: flex;
}
.appf.pfx .intake .intake-field input {
  font-weight: bold;
}
.appf.pfx .intake .intake-field.split input {
  width: 42%;
  margin: 0 1%;
}
.appf.pfx .column-form.type2 .intake-title {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -24px;
  background: #eee;
  padding: 3px 8px;
}

.appf.pfx .column-form.type2 li > div.conditional {
  display: flex;
  align-items: center;
}
.appf.pfx .column-form.type2 li > div.conditional .element-toggle {
  width: 30px;
}
.appf.pfx .column-form.type2 li > div.conditional span {
  margin: 0 10px 0px;
  font-size: 12px;
  font-weight: bold;
}
.appf.pfx .column-form.type2 li > div.conditional input[type=text] {
  flex-grow: 1;
  width: 0;
}

.appf.pfx .column-form li .complex div.element-toggle.horiz>.opts {
  width: 120px;
  flex-direction: row;
}

.disc-stat .element-toggle {
  margin-bottom: 10px;
}
.disc-stat .element-toggle label > span:first-child {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #337dff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
  margin-top: -2px;
}
.disc-stat .element-toggle label.checked > span:first-child::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background: #337dff;
  content: "";
  position: absolute;
}
.disc-stat .element-toggle label > input {
  display: none;
}
.disc-stat .element-toggle label > span:last-child {
/*   font-weight: bold; */
  font-size: 12px;
}
.appf.pfx .column-form.type2 .terminals-add {
  color: #124001;
  background: #91e864;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
}
.appf.pfx .column-form.type2 .terminals-del {
  color: white;
  background: #E01001;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
}
.appf .column-form li .multiselect {
  align-items: flex-start;
}
.appf .column-form.type2 li .multiselect {
  align-items: center;
}
.lab2h li label,
.lab2h .field label {
  min-height: 40px;
}
.appf.pfx .column-form.cf-3sp>li {
  width: calc(33% - 30px);
}
.appf .column-form li .element-toggle .multiselect li label {
  margin-top: 0;
}
.appf.pfx .column-form.type2 > li.extw {
  width: calc(27% - 30px);
}
.appf.pfx .column-form.type2 > li.extw + li {
  width: calc(23% - 30px);
}
.preform .mc-setup p.form-intro {
  
}
.preform .mc-setup p.form-intro strong {
  font-weight: 500;
  font-size: 16px;
}
.preform .mc-setup form h2 {
  font-size: 12px;
  background: black;
  border: 0;
  color: white;
  padding: 0 22px;
}
.preform .mc-setup form h2 em {
  font-size: 12px;
  font-weight: normal;
}
.preform .mc-setup .preq-form li > label, 
.preform .mc-setup .preq-form li > div {
  width: auto;
  display: block;
  font-size: 12px;
}
.preform .mc-setup .preq-form li.compact > label,
.preform .mc-setup .preq-form li.compact > div {
  display: inline-block;
}
.preform .mc-setup .preq-form li > label {
  min-width: 200px;
  font-weight: bold;
}
.preform .mc-setup .preq-form ul.preform-address li > label {
  min-width: 0;
}
.preform .mc-setup table {
  width: 80%;
  font-size: 12px;
  line-height: 18px;
  margin-left: -10px;
}
.preform .mc-setup table td,
.preform .mc-setup table th {
  padding: 2px 10px;
}
.preform.appf .mc-setup form p.intro {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
}
.preform.appf .mc-setup form p.intro strong {
  font-weight: bold;
}
.preform.appf .mc-setup form p.intro em {
  text-decoration: underline;
}
.preform .mc-setup p.form-intro {
  margin-right: 0;
  padding-right: 30px;
}
.preform .mc-setup .buttons .btn-large {
  width: 100%;
  box-sizing: border-box;
}
.mc-setup input[type="checkbox"] {
  display: none;
}
.mc-setup input[type="checkbox"]+span {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  border: 1px solid #337dff;
  border-radius: 4px;
}
.mc-setup input[type="checkbox"]:checked+span::before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background: #337dff;
  border-radius: 2px;
}
.mc-setup .ipinput {
  display: flex !important;
  width: 100% !important;
}
.mc-setup .ipinput label {
  flex-shrink: 0;
}
.preform .mc-setup .preq-form li.ipinput > div {
  flex-grow: 1;
  display: block;
  margin-left: 24px;
}
.preform .mc-setup .preq-form .preform-auto.preform-nogrow {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
}
.preform .mc-setup .preq-form .preform-auto.preform-nogrow li {
  width: auto;
  margin-right: 20px;
}
.preform .mc-setup .preq-form .preform-nogrow {
  justify-content: flex-start;
}
.preform .mc-setup .preq-form .preform-nogrow.preform-line {
  white-space: nowrap;
}
.preform .mc-setup .preq-form .preform-nogrow>li {
  width: 15%;
  flex-grow: 0;
}
.preform .mc-setup .preq-form .preform-nogrow li > label {
  min-width: 0;
}
.preform .mc-setup .sub-select .multiselect {
  display: flex;
  margin-left: 100px;
}
.preform .mc-setup .sub-select .multiselect label {
  min-width: 80px;
}
.fi-split {
  display: flex;
}
.fi-split>div {
  min-width: 300px;
}
.preform.pfx .preq-form li .validator.payment-multi .multiselect {
  display: block;
}
.preform.pfx .preq-form li .validator.payment-multi .multiselect li {
  width: auto;
  flex-grow: 0;
  font-weight: 400;
}
.preform.pfx .preq-form li .validator.payment-multi label span {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #000;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.preform.pfx .preq-form li .validator.payment-multi label input {
  display: none;
}
.preform.pfx .preq-form li .validator.payment-multi label input:checked + span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}

.preform.pfx .preq-form li .validator.payment-multi ul li{
  margin: 0 2% !important;
}

.appview-list li .validator.payment-multi .multiselect {
  display: block;
}
.appview-list li .validator.payment-multi .multiselect li {
  width: auto;
  flex-grow: 0;
  font-weight: 400;
}
.appview-list li .validator.payment-multi label span {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  background: white;
  border: 1px solid #000;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  position: relative;
}
.appview-list li .validator.payment-multi label input {
  display: none;
}
.appview-list li .validator.payment-multi label input:checked + span::before {
  width: 10px;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 2px;
  background:#337dff;
/*   bottom: 0; */
  content: "";
  position: absolute;
}

.appview-list li .validator.payment-multi ul li{
  margin: 0 2% !important;
}