.setupcl {
  padding-bottom: 20px;
  ul {
    li {
      display: flex;
      align-items: center;
      label {
        flex-grow: 1;
        margin-left: 10px;
      }
      div.actions {
        justify-content: flex-end;
        display: flex;
        background: none;
      }
      border-bottom: 1px solid #ccc;
      padding-bottom: 4px;
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  a.button {
    display: block;
    background: #357aba;
    padding: 4px 8px;
    color: white;
    margin-right: 4px;
    box-shadow: 0px 0px 0px 1px white;
    &.disabled {
      cursor: default;
      background: #999;
    }
  }
  .done {
    background: #1f9400;
    color: white;
    margin-right: 4px;
    padding: 0px 8px;
    font-size: 14px;
    &::after {
      content: "✓";
    }
  }
  a.button.done {
    background: #1f9400;
    font-size: 12px;
    &::after {
      margin-left: 8px;
    }
  }
}
