.boarding-container {
  display: flex;
  aside.boarding-steps {
    width: 200px;
    flex-shrink: 0;
    flex-grow: 0;
    ul {
      padding: 15px;
      li {
        border-top: 1px solid #333;
        &:first-child {
          border-top: 0px none;
        }
        a {
          display: block;
          padding: 5px 5px 5px 25px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 5px;
            top: calc(50% - 8px);
            width: 16px;
            height: 16px;
            border-radius: 8px;
          }
        }
        &.available a::after { background: rgba(50, 255, 50, 0.5); }
        &.current a::after { background: #32ff32; }
      }
    }
  }
  section.boarding-main {
    padding: 15px;
    flex-grow: 1;
    >div {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      >* {
        width: 100%;
      }
      .actions {
        margin-top: auto;
        padding-top: 15px;
        display: flex;
        background: none;
        justify-content: center;
        >* {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
        button {
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          margin-right: 8px;
          color: black;
          font-size: 16px;
          display: block;
          padding: 6px 20px;
          box-sizing: border-box;
          border: 1px solid #b3b3b3;
          border-radius: 6px;
          background: #e4e4e4;
          background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
          &:not(:disabled) {
            &:hover, &:focus {
              background: #e9f2ff;
              background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
              border-color: #6192fc;
              color: #6192fc;
            }
          }
          &:disabled {
            opacity: .5;
          }
        }
      }
    }
  }
}

.content-table.file-list .partner-boarding-doc-actions {
  width: 25%;
  input {
    width: 100%;
  }
}

.view-profile .merchant-panel .data-content .boarding-main .action-sub-buttons {
  padding: 10px;
  .btn {
    cursor: pointer;
    margin-right: 8px;
    color: black;
    font-size: 16px;
    display: inline-block;
    padding: 6px 20px;
    box-sizing: border-box;
    border: 1px solid #b3b3b3;
    border-radius: 6px;
    background: #e4e4e4;
    background: linear-gradient(to bottom, #e4e4e4 0%, #cfcfcf 100%);
    &:hover, &:focus {
      background: #e9f2ff;
      background: linear-gradient(to bottom, #e9f2ff 0%, #deebff 100%);
      border-color: #6192fc;
      color: #6192fc;
    }
  }
  display: block;
  + .content-table.file-list {
    margin: 0;
    width: 100%;
  }
}

.ncontent.appf.in-popup {
  margin: 0 auto;
  //padding: 0;
  box-shadow: none;
  border: none;
  //background: none;

  .setup-box .start-build ul {
    margin-top: 20px;
  }
}




.form-step .field.mutiselect-toggle {

  label {
    border-color: transparent !important;
    margin-right: 12px;
  }
  label input[type=checkbox] {
    position: absolute;
    left: -10000px;
  }

  label span {
    margin-left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid #337dff;
    background: white;
    position: relative;
  }
  label span::before {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 2px;
    content: "";
    display: block;
  }
  label.checked span::before {
    background: #337dff;
  }
}


