$--color-primary: #13ce67;
$--font-path: '../fonts';

@import '~element-ui/packages/theme-chalk/src/index';


.flex {
  display: flex;
  .half {
    width: 50%;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.mr-5 {
  margin-right: 5px;
}

.plain-content {
  p {
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul {
    padding-left: 20px;
    li {
      list-style: disc;
    }
    margin-bottom: 1em;
  }
  ol {
    padding-left: 20px;
    li {
      list-style: decimal;
    }
    margin-bottom: 1em;
  }
}

.mnb-field {
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #768594;
  }
  .mnb-input {
    outline: none;
    border: 1px solid #99aabb;
    height: 30px;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    width: 100%;
    max-width: unset;
  }
}

.multi-dropdown {

  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: white;
  border: 1px solid #bec9d4;
  line-height: 46px;
  padding: 0 12px;
  font-size: 15px;
  color: #41566c;
  font-weight: bold;
  outline: none;
  position: relative;
  .value {
    cursor: default;
    display: block;
    box-sizing: border-box;
    padding: 2px 4px;
    position: relative;
    height: 46px;
    line-height: 42px;
    white-space: nowrap;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
      background-image: url("../img/arrowd1.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  >ul {
    line-height: 24px;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: -1px;
    border: 1px solid #ccc;
    background: white;
    min-width: 280px;
    li label {
      padding: 2px 4px;
      display: flex;
      align-items: center;
      input {
        left: 0;
        top: 0;
        height: 20px;
      }
      input + span {
        width: 0;
      }
      input + span + span {
        margin-left: 5px;
        width: 0;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    max-height: 300px;
    overflow: auto;
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.content-filters .multi-dropdown {
  display: block;
  font-size: 13px;
  font-weight: bold;
  color: #63717d;
  padding: 0 28px 0 8px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #99aabb;
  border-radius: 6px;
  height: 29px;
  appearance: none;
  background-color: #fff;
  background-image: url("../img/arrowd1.svg");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  max-width: 500px;
  .value {
    line-height: 27px;
    &::after {
      display: none;
    }
  }
  input {
    appearance: auto;
    -webkit-appearance: auto;
    width: 16px;
  }
}

.dropdown-button {
  display: inline-block;
  position: relative;
  button {
    outline: none;
  }
  .dropdown-options {
    position: absolute;
    top: 45px;
    right: 10px;
    background-color: #fff;
    z-index: 99;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    li {
      padding: 5px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      cursor: pointer;
      &:hover {
        color: #0480c6;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.el-drawer__wrapper {
  .el-drawer__header {
    font-size: 20px;
  }

  .el-drawer__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 76px);
    .content {
      height: 100%;
      padding: 20px;
      overflow-y: auto;
      .el-form {
        flex-grow: 1;
      }
    }
  }

  .drawer__footer {
    display: flex;
    padding-top: 20px;
    padding: 20px;
    .button {
      width: 100%;
    }
  }
}

.content-form {
  h4 {
    color: #616f7b;
    font-size: 16px;
    font-weight: bold;
    line-height: 34px;
    margin: 18px 0 26px;
    border-bottom: 1px solid #99aabb;
    span {
      color: #99aabb;
    }
  }
  >p {
    margin: 1em 0;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .date {
      display: flex;
      .date-item {
        width: 33%;
        position: relative;
        margin: 0;
        &.sm {
          width: 25%
        }
        &.lg {
          width: 41%
        }
      }
    }
    div {
      position: relative;
      // height: 48px;
      margin-bottom: 18px;
      &.autoh {
        @media screen and (max-width: 760px) {
          height: auto;
        }
      }

      label {
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        height: 26px;
        line-height: 26px;
        top: 11px;
        left: 1px;
        transition: .3s;
        z-index: 1;
        font-size: 15px;
        color: #41566c;
        padding: 0 12px;
        em {
          color: #d92930;
        }
        &::before {
          z-index: -1;
          background: transparent;
          width: 400px;
          height: 24px;
          content: "";
          position: absolute;
          top: 13px;
          left: 0;
          border-radius: 24px 0 0 0;
        }
      }
      &.filled {
        label {
          font-size: 12px;
          top: -13px;
          left: 0;
          &::before {
            background: white;
          }
        }
      }
      &.disabled {
        label {
          color: #999;
        }
      }
      &.focused {
        input, .select {
          border-color: #1f2d4e;
        }
      }
      input, .select {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        background: white;
        border: 1px solid #bec9d4;
        line-height: 46px;
        padding: 0 12px;
        font-size: 15px;
        color: #41566c;
        font-weight: bold;
        outline: none;
        &.error {
          border-color: red;
        }
      }
      .select.disabled {
        opacity: 0.7 !important;
        border-color: rgba(118, 118, 118, 0.3);
      }
      textarea {
        display: block;
        box-sizing: border-box;
        width: 100%;
        min-height: 70px;
        border-radius: 24px;
        background: white;
        border: 1px solid #bec9d4;
        line-height: 20px;
        padding: 14px 12px;
        font-size: 15px;
        color: #41566c;
        font-weight: bold;
        outline: none;
        resize: none;
        &.error {
          border-color: red;
        }
      }
      .select {
        position: relative;
        margin: 0;
        select {
          -webkit-appearance: none;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 46px;
          opacity: 0;
          optgroup[label] {
            color: gray;
          }
          option {
            color: #000;
          }
        }
        &::before {
          content: "";
          width: 12px;
          height: 8px;
          position: absolute;
          top: calc(50% - 4px);
          right: 12px;
          background: url("../img/arrowd1.svg") no-repeat 50% 50%;
          background-size: contain;
        }
        &.disabled::before {
          opacity: .6;
        }
      }
      .error-message {
        display: block;
        padding: 0 5px;
        box-sizing: border-box;
        margin-top: 5px;
        color: red;
        span {
          display: block;
        }
      }
    }
    &.form-split {
      >div {
        width: calc(50% - 18px);
        @media screen and (max-width: 760px) {
          width: 100%;
        }
      }
    }
    &.form-corporate {
      >div {
        width: calc(25% - 5px);
        @media screen and (max-width: 760px) {
          width: 100%;
        }
      }
    }
    &.form-three {
      >div {
        width: calc(33.33% - 18px);
        @media screen and (max-width: 760px) {
          width: 100%;
        }
      }
    }
    &.form-fullw {
      >div {
        width: 100%;
      }
    }
    &.form-center {
      justify-content: center;
      >div {
        width: 100%;
        max-width: 380px;
      }
    }
  }
  .txtc {
    color: #696969;
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
  }
  .check {
    font-size: 14px;
    input[type=checkbox] {
      display: none;
    }
    input+span {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 17px;
      box-sizing: border-box;
      border: 1px solid #99aabb;
      margin-right: 5px;
      margin-top: -1px;
      border-radius: 3px;
    }
    input:checked+span {
      background: url("../img/check.svg") no-repeat 50% 50%;
      background-size: 12px 12px;
    }
    &.error {
      input + span {
        border-color: red;
      }
    }
  }
  a {
    color: #139ada;
    text-decoration: underline;
    @media screen and (max-width: 760px) {
      margin-left: 10px;
    }
  }
  .cmarks {
    display: flex;
    justify-content: center;
    margin: 0px 0 20px;
    @media screen and (max-width: 760px) {
      flex-wrap: wrap;
      li {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    li {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  button {
    display: block;
    border: 0;
    outline: none;
    height: 46px;
    width: 100%;
    cursor: pointer;
    border-radius: 23px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#29c1c5+0,18bc9c+100 */
    background: #008A00;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(86deg,var(--site-button-color-left),var(--site-button-color-left) 20%,var(--site-button-color-right)); /* todo */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#29c1c5", endColorstr="#18bc9c",GradientType=1 );
    /* IE6-9 */
    color: white;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: all ease .3s;
    &:hover:not(:disabled) {
      transform: scale(1.1);
    }
  }
}

.form-box {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 700px;
}

.popup.open {
  visibility: visible;
}

.popup p {
  margin: 0;
  padding: 0;
}

.upload-input button {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
  width: auto;
  padding: 0 30px;
  height: 36px;
}
