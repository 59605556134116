.panel {
  position: relative;
  box-sizing: border-box;
  background: white;
  max-width: calc(100% - 50px);
  width: 1100px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.05);
  border-radius: 4px;
  border: 1px solid rgba(107, 107, 107, .23);
  margin: 0px auto;
}
.panel .panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0ef;
}
.panel .panel-title h2 {
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
}
.panel .panel-title .panel-actions {
  width: auto;
}
.panel .body {
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}
.panel .body.no-padding {
  padding: 0;
}
.panel .panel-footer {
  padding: 15px;
  box-sizing: border-box;
  border-top: 1px solid #f0f0ef;
}