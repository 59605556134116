.api-log-pop {
  .pop-inner {
    max-width: 600px;
    width: 90vw;
  }
  .log-body {
    font-size: 14px;
    max-height: 400px;
    overflow: auto;
    padding: 5px;
    .head-details {
      padding: 10px 0;
      >div {
        display: flex;
        align-items: center;
      }
      span {
        font-family: "Roboto Mono", Consolas, Menlo, Courier, monospace;
        overflow-wrap: break-word;
        padding: 2px 0;
      }
    }
  }
  label {
    display: inline-block;
    width: 110px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: bold;
  }
  .jv-container.jv-light {
    font-family: "Roboto Mono", Consolas, Menlo, Courier, monospace;
  }
  .jv-container .jv-code {
    padding: 10px 0px;
  }
}
