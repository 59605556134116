.ptx+.buttons .btn-large {
  height: 42px;
  line-height: 42px;
  text-transform: none;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 8px;
}
.ptx+.buttons .btn-large.btn-primary {
  background: #1f9400;
  min-width: 280px;
}
.ptx+.buttons .btn-large.btn-danger {
  background: #ff0707;
  min-width: 170px;
}
.ptx .content-table th {
  font-size: 12px;
  font-weight: 300;
/*   text-transform: uppercase; */
  height: 27px;
  
}
.ptx .content-table td {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  padding: 4px;
}
.content-table tr.nobg:nth-of-type(2n+1) td {
  background: transparent;
}
.ptx .content-table td .move-up {
  position: absolute;
  top: -26px;
  left: 0;
}
.ptx .content-table input[type="text"] {
  background: white;
}
.ptx {
  border: 0;
}
.ptx .content-table .spacer {
  height: 6px;
}
