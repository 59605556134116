body.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 100%;
  background: #eee;
  font-family: "Montserrat", sans-serif;
}

.login-container {
  margin: auto;
  padding: 100px 10px;
}

.login-content {
  background: white;
  border: 2px solid #dadada;
  width: 440px;
  box-sizing: border-box;
  padding: 75px 65px;
  text-align: center;
}
@media screen and (max-width: 460px) {
  body.login-page {
    display: block;
  }
  .login-content {
    width: 100%;
    padding: 17% 15%;
  }
}
.login-content .logo {
  display: block;
  margin: 0 auto 35px;
  width: 230px;
  height: 97px;
  max-width: 100%;
  object-fit: contain;
}

.login-content h2 {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
  color: black;
}
.login-content h2 strong {
  font-weight: 700;
  color: #4e8bff;
}
.login-content h2 em {
  text-transform: uppercase;
}
.login-content label {
  font-size: 12px;
  font-weight: 400;
  color: #5c5c5c;
}
.login-content form {
  margin-top: 50px;
  max-width: 320px;
}
.login-content form input {
  display: block;
  width: 100%;
  height: 45px;
  border: 2px solid #dadada;
  -webkit-apperance: none;
  margin-bottom: 12px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
}
.login-content form input:focus {
  background: #dbe9f8;
}
.login-content form button {
  display: block;
  width: 100%;
  margin-top: 20px;
  border: 0;
  height: 48px;
  background: #4e8bff;
  font-size: 24px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}
.login-content .error-msg {
  color: red;
  margin: 20px 0 0;
  display: none;
}

.login-options {
  margin-top: 70px;
}
.login-options ul li {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  padding: 5px 0;
}
.login-options ul li a {
  text-decoration: underline;
  color: #4e8bff;
}


.popup.usermgmt .consent {
  width: 370px;
  margin: auto;
}
.popup.usermgmt .consent ul {
}
.popup.usermgmt .consent li label {
  display: block;
}
.popup.usermgmt .consent li input {
  vertical-align: middle;
}
.popup.usermgmt .consent li a {
  
}

/* ADDED */

body.login-page {
  background: white;
}

.login-content {
  border-color: white;
  width: 450px;
}

.login-content form {
  max-width: none;
}

.login-content .logo {
  width: 268px;
}
.login-content h2,
.login-content>label {
  display: none;
}
.login-content form input {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid #AAA;
  text-align: left;
  font-size: 16px;
}
.login-content form input:focus {
  background: none;
}
.login-content form button {
  width: 37%;
  margin-left: auto;
  margin-right: auto;
  
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ccc;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: #fff;
  cursor: pointer;
  line-height: 38px;
  color: black;
  font-size: 19px;
  padding: 10px 0px;
  height: auto;
  outline: none;
}

.login-content form button:focus {
  box-shadow: 0 0 0 3px rgba(38,143,255,.5);
}
.login-content form button:active {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

::-moz-focus-inner {border:0;}

.login-msg {
/*   text-align: left; */
}
.login-msg h1 {
  margin: 30px 0 20px;
  font-weight: bold;
}
