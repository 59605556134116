.error-popup {
  background: white;
  padding: 15px 20px;
  h2 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  button {
    border-radius: 0;
    text-align: center;
    line-height: 54px;
    font-size: 18px;
    color: white;
    background: #357aba;
    border: 0;
    height: 54px;
    min-width: 330px;
    padding: 0 20px;
    text-transform: uppercase;
    margin: 30px auto 0;
  }
}
