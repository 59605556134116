.calendar {
  width: 100%;
  border-collapse: collapse;
}
.calendar tr th {
  text-align: center;
  width: calc(100% / 7);
  padding-bottom: 10px;
}
.calendar tr td {
  padding: 5px;
  width: calc(100% / 7);
  height: 100px;
  border: 1px solid rgba(0, 0, 0, .1);
  vertical-align: top;
}
.calendar tr td .day {
  color: #999;
  padding: 2px 10px;
}
.calendar tr td .day.today {
  background-color: #4299e1;
  border-radius: 2px;
  color: #fff;
}

.calendar tr td .event {
  display: inline-block;
  border-radius: 2px;
  padding: 3px 6px;
  font-size: 13px;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 2px;
}
.event-blue {
  background-color: #4299e1;
}
.event-red {
  background-color: #ce1241;
}
.event-green {
  background-color: #0ec554;
}
.event-orange {
  background-color: #ee8c0c;
}
.event-yellow {
  background-color: #ffe704;
  color: #666;
}

.calendar tr td.selectable {
  transition: all ease .2s;
}
.calendar tr td.selectable:hover {
  background-color: #ebf2f7;
  cursor: pointer;
}
.calendar tr td.selected {
  background-color: #f1f5f8;
  border-bottom-color: #4299e1;
  border-bottom-width: 3px;
}
.calendar-selectd-date {
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  width: 190px;
  margin: 0 5px;
}
.calendar-selectd-date select {
  float: right;
}
.form .form-group {
  display: block;
  margin-bottom: 10px;
}
.form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.inline-form-group .form-group {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.event-list h1 {
  font-size: 22px;
}
ul.list {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, .1);
}
ul.list li.list-item {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 5px;
  transition: all ease .2s;
}
ul.list li.list-item .circle {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

ul.list li.list-item:hover {
  background-color: #ebf2f7;
  cursor: pointer;
}
ul.list li.list-item.selected {
  background-color: #f1f5f8;
  border-bottom-color: #4299e1;
  border-bottom-width: 3px;
}