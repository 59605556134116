section.shaded {
  position: relative;
  margin: -20px;
  padding: 20px;
  position: relative;
}
.preform .preq-form section.shaded .preform-sp3 li .validator,
.preform .preq-form section.shaded .preform-sp3 li label {
  background: none;
}
.preform .preq-form section.shaded .validator.valid::after {
  display: none;
}
section.shaded .shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(192,192,192,0.5);
}
section.shaded .shade-message {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
}
section.shaded .shade-message.shade-high {
  align-items: flex-start;
  padding-top: 100px;
}

.appf.pfx label.checkbox {
  border: 0 none;
  background: transparent;
  font-weight: 300;
  font-size: 13px;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}
.appf.pfx label.checkbox > input {
  display: none;
}
.appf.pfx label.checkbox > span {
	margin-right: 8px;
	display: inline-block;
	vertical-align: middle;
	background: white;
	border: 1px solid #337dff;
	border-radius: 3px;
	width: 16px;
	height: 16px;
	box-sizing: border-box;
	position: relative;
	margin-top: -2px;
}
.appf.pfx label.checkbox.checked > span::before {
	width: 10px;
	top: calc(50% - 5px);
	left: calc(50% - 5px);
	height: 10px;
	border-radius: 2px;
	background: #337dff;
	content: "";
	position: absolute;
}
.appf.pfx .column-form.type2 .intake-title label {
	font-size: 14px;
	font-weight: 500;
  color: black;
}
