.validator {
  position: relative;
}
.validator.error {
/*   background: #fcc; */
/*   box-shadow: 0px 0px 3px 1px #fcc; */
}
.validator.error::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  mix-blend-mode: multiply;
  background: #ffb8b3;
}
.validator.valid::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  mix-blend-mode: multiply;
  background: #7af567;
}
.validator>span.error {
  position: absolute;
  bottom: calc(100% + 1px);
  right: -1px;
  background: #a00;
  border: 1px solid #800;
  color: black;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  font-size: 12px;
  color: white;
  max-width: 100%;
  padding: 2px 5px;
  visibility: hidden;
}

.validator.focused>span.error {
  visibility: visible;
}
