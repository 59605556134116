html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

.page-container {
  min-height: 100%;
  background: #f8f9fa;
}
.main-sidebar {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 330px;
  background: white;
  border-right: 1px solid #e9ecef;
  z-index: 1;
  overflow: hidden;
  transition: width .5s;
}
.main-sidebar.collapsed {
  width: 70px;
}
.main-sidebar-scroll {
  box-sizing: border-box;
  height: 100%;
  width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.main-sidebar.collapsed .main-sidebar-scroll {
  width: 90px;
}

.main-sidebar-container {
  height: 100%;
  box-sizing: border-box;
  width: 330px;
  max-width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main-sidebar.collapsed .main-sidebar-container {
  width: 70px;
}

.main-content {
  margin-left: 330px;
  transition: margin-left .5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.main-content.wide {
  margin-left: 70px;
}
.collapsed .main-sidebar {
  width: 0;
}
.collapsed .main-content {
  margin-left: 0;
}

footer {
  padding: 30px 0;
  text-align: center;
}
footer .logo {
  position: relative;
  margin: 10px auto 0;
  width: 150px;
  height: 65px;
}
footer ul {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
footer ul li {
  padding: 0 15px;
  height: 16px;
  color: #6b6b6b;
  font-size: 18px;
  font-weight: 300;
  border-right: 1px solid #ccc;
  line-height: 16px;
}
footer ul li.emph {
  color: #4e8bff;
}
footer ul li:last-of-type {
  border-right: 0px none;
}
footer p.copy {
  color: #6b6b6b;
  font-size: 14px;
  font-weight: 300;
}
footer .logo a {
  display: block;
  width: 144px;
  height: 50px;
  overflow: hidden;
  text-indent: -10000px;
  background: url("../img/foot-logo.png") no-repeat 50% 50%;
  background-size: contain;
}



.notifications {
  position: fixed;
  right: 50%;
  top: 0;
  transform: translateX(50%);
  visibility: hidden;
  z-index: 9999;
}
.notifications.visible {
  visibility: visible;
}
.notifications li {
  margin-top: 10px;
  opacity: 1;
  transition: .5s;
  min-width: 300px;
  max-width: 600px;
  width: 80%;
  padding: 5px 10px;
  box-sizing: border-box;
  line-height: 20px;
  border-radius: 3px;  
  text-align: center;
  color: white;
  border: 1px solid rgba(255,255,255,0.3);
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  animation-name: notify-in;
  animation-duration: .5s;
}
@keyframes "notify-in" {
  from {
    margin-top: -30px;
    opacity: 0;
  }
  to {
    margin-top: 10px;
    opacity: 1;
  }
}
.notifications li.dismissed {
  opacity: 0;
  margin-top: -30px;
}
.notifications li.success {
  background-color: #f0f9eb;
  color: #67c23a;
}
.notifications li.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
}
.notifications li.error {
  background-color: #fef0f0;
  color: #f56c6c;
}


.file-obj {
  display: flex;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;
}
.file-obj a:first-child {
  flex-grow: 1;
  display: block;
  height: 20px;
  line-height: 20px;
  text-align: left;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
}
.file-obj a.btn-remove,
.file-obj a.btn-upload {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.file-obj a.btn-upload input {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3em;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  cursor: pointer;
}
.file-obj a.btn-upload {
  text-indent: -10000px;
  background: url("../img/upload.svg") no-repeat 50% 50%;
  background-size: 10px 12px;
}
.file-obj a.btn-remove {
  color: #e00000;
}


select {
  height: 28px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0 8px;
  background: white;
  box-sizing: border-box;
}

.amadel-logo-wrapper {
  height: 100px;
  background: url("../img/amadel-logo.png") no-repeat;
  background-position: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.last-login {
  text-align: center;
  margin-top: auto;
  margin-bottom: 10px;
}