.content-table {
  width: 100%;
}
.content-table-container {
  clear: both;
  border: 1px solid #b3b3b3;
  overflow: hidden;
  margin-bottom: 30px;
}
.content-table th {
  background: black;
  color: white;
  height: 44px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.content-table th.spacer {
  height: 11px;
  background: white;
}
.content-table.dload th {
  background: white;
  color: #303030;
  font-weight: normal;
  border-top: 1px solid transparent;
}
.content-table.dload th:first-of-type {
  text-align: left;
  padding-left: 30px;
}
.content-table input,
.content-table select {
  border: 1px solid rgba(0,0,0,0.1);
  background: transparent;
  width: 100%;
  text-align: center;
  height: 28px;
  box-sizing: border-box;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0 4px;
}
.content-table input[type=checkbox] {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  width: auto;
  height: auto;
}
.content-table .multiselect {
  white-space: nowrap;
  display: flex;
  font-size: 12px;
}
.content-table .multiselect li {
  margin-right: 5px;
}
.content-table .multiselect li:last-child {
  margin-right: 0;
}
.content-table input:focus,
.content-table select:focus,
.content-table input:hover,
.content-table select:hover {
  border: 1px solid rgba(0,0,0,0.1);
}
.content-table td {
  background: white;
  text-align: center;
  padding: 0 6px;
  height: 32px;
}
.content-table td.text-left {
  text-align: left;
}
.content-table tr:nth-of-type(2n+1) td {
  background: #e5e5e5;
}
.content-table tr:last-of-type td {
  border-bottom: 2px solid black;
}
.content-table td:first-of-type {
  border-left: 2px solid white;
}
.content-table td:last-of-type {
  border-right: 1px solid white;
}
.content-table .main-col {
  font-weight: bold;
}
.content-table .combo-select {
  position: relative;
  height: 32px;
  background: url("../img/arrowd.png") no-repeat calc(100% - 5px) 50%;
}
.content-table .combo-select select {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.content-table .combo-select input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 20px);
  display: block;
  height: 32px;
}
.content-table .remove {
  color: #e00000;
}


.content-table-container.resources {
  margin-top: 50px;
  border: 1px solid #e4e4e4;
  box-shadow: 2px 4px 6px rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow: hidden;
}
.content-table.resources td {
  padding-top: 4px;
  padding-bottom: 4px;
}
.content-table.resources .inend td {
  height: 0px;
}
.content-table.resources td:first-of-type {
  border-left: 0px none;
  padding-left: 30px;
  color: #777;
}
.content-table.resources td:last-of-type {
  border-right: 0px none;
}

.content-table.resources tr:last-of-type td {
  border-bottom: 0px none;
}
.content-table-container.dload {
  border-color: transparent;
  box-shadow: none;
}

.content-table .btn.btn-default {
  display: inline-block;
  margin: 2px 0;
  color: white;
  text-transform: uppercase;
  background: #367abb;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  font-size: 12px;
}
.content-table.dload .remove {
  font-weight: bold;
  color: red;
}
.content-table.dload .fname {
  font-weight: bold;
  color: #303030;
}
.content-table.dload .username {
  font-weight: bold;
  color: #357aba;
}

.content-table.usermgmt {
  
}
.content-table.usermgmt th:first-of-type {
  text-align: left;
  padding: 0 6px;
}
.content-table.usermgmt td:last-of-type {
  text-align: right;
}
.content-table.usermgmt .block {
  font-weight: bold;
  color: #357aba;
  margin-right: 15px;
}
.content-table.usermgmt .edit {
  font-weight: bold;
  color: #357aba;
  margin-right: 15px;
}
.content-table.usermgmt .remove {
  font-weight: bold;
  color: #e00000;
}
.toolbar .btn.btn-primary {
  background: #357aba;
/*   background: #1f9400; */
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 2px 15px;
  margin-left: 10px;
}
.btn.btn-primary.new-user,
.toolbar .btn.btn-primary.new-user {
  background: #357aba;
  background: #1f9400;
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  padding: 2px 15px;
}


.tb-btn {
  color: white !important;
  text-transform: uppercase;
  display: inline-block;
  background: #5e9d44;
  padding: 2px 20px;
  margin-top: 2px;
  border-radius: 4px;
}


.content-table.pricing-table th:first-child {
  text-align: left;
  padding: 0 6px;
}
