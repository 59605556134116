.support-box {
  background: white;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 3px 9px rgba(0,0,0,.1);
  font-family: "Montserrat", sans-serif;
}
.supp-head {
  position: relative;
  padding: 48px 70px 32px;
  color: white;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4682e7+0,3c9835+100 */
  background: rgb(70,130,231); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(70,130,231,1) 0%, rgba(60,152,53,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(70,130,231,1) 0%,rgba(60,152,53,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(70,130,231,1) 0%,rgba(60,152,53,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4682e7', endColorstr='#3c9835',GradientType=1 ); /* IE6-9 */
  overflow: hidden;
  background: transparent;
}
.supp-head::before {
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4682e7+0,3c9835+100 */
  background: rgb(70,130,231); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(70,130,231,1) 0%, rgba(60,152,53,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(70,130,231,1) 0%,rgba(60,152,53,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(70,130,231,1) 0%,rgba(60,152,53,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4682e7', endColorstr='#3c9835',GradientType=1 ); /* IE6-9 */
  position: absolute;
  display: block;
  top: -100px;
  left: -100px;
  right: -100px;
  bottom: -100px;
  transform-origin: 50% 50%;
  transform: rotate(10deg);
}
.supp-head>div {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.sup-con {
  width: 380px;
}
.sup-con h2 {
  position: relative;
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 13px;
  margin-bottom: 12px;
}
.sup-con h2::before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  content: "";
  background: white;
  width: 94px;
}
.sup-con p {
  line-height: 26px;
  font-size: 14px;
  font-weight: 500;
}
.sup-con p strong {
  font-weight: bold;
}
.sup-ico {
  width: 220px;
  text-align: center;
}
.sup-ico i {
  display: block;
  height: 70px;
  background-repeat: no-repeat;
  width: 100%;
  background-position: 50% 0%;
/*   background-size: contain; */
  margin-bottom: 12px;
}
.sup-ico i.phone {
  background-image: url("../img/ico-phone.png");
}
.sup-ico i.mail {
  background-image: url("../img/ico-mail.png");
}
.sup-ico p {
  line-height: 25px;
  font-weight: 500;
  font-size: 18px;
}
.sup-ico p:first-of-type {
  font-size: 21px;
}
.sup-cont {
  padding: 50px 60px;
}
.sup-tabs {
  display: flex;
  border: 1px solid #c7c7c7;
  background: #e0e0e0;
  border-radius: 5px;
}
.sup-tabs li {
  height: 63px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px white;
  line-height: 43px;
  position: relative;
}
.sup-tabs li::before, .sup-tabs li::after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  content: "";
}
.sup-tabs li::before {
  right: 0;
  background: #c7c7c7;
}
.sup-tabs li::after {
  right: -1px;
  background: #fff;
}
.sup-tabs li a {
  display: block;
  padding: 10px 34px;
}
.sup-tabs li.active {
  background: #4e8bff;
  color: white;
  text-shadow: 0px 2px 0px rgba(0,0,0,.26);
  border: 1px solid #4e8bff;
  margin: -1px;
  z-index: 1;
}
.sup-tabs li:first-of-type {
  border-radius: 5px 0 0 5px;
}
.sup-tabs li.active::before, .sup-tabs li.active::after {
  display: none;
}
.sup-tabs li.active a::after {
  position: absolute;
  content: "";
  bottom: -8px;
  border-top: 7px solid #4e8bff;
  left: calc(50% - 6px);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.sup-table {
  margin-top: 26px;
}
.sup-table table {
  width: 100%;
}
.sup-table table th {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  height: 44px;
}
.sup-table table td {
  height: 44px;
  border-bottom: 6px solid transparent;
}
.sup-table table td span {
  background: #ebebeb;
  display: block;
  width: 100%;
  height: 44px;
  line-height: 44px;
  color: #757575;
  font-size: 14px;
  font-weight: 500px;
}
.sup-table table td:first-of-type span, .sup-table table th:first-of-type {
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
}
.sup-table table td:last-of-type {
  width: 100px;
}
.sup-table table td:last-of-type span {
  border-radius: 0 5px 5px 0;
}
.table-btn {
  display: block;
  background: #53ad3b;
  color: white;
  font-weight: bold;
  width: 100px;
  text-align: center;
  border-radius: 0 5px 5px 0;
}
.sup-table .topts {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 2px solid #c7c7c7;
  margin-left: 4px;
  margin-right: 4px;
}
.sup-table .tap .topts.approve {
  background-image: url("../img/table-approve.png");
}
.sup-table .tde .topts.deny {
  background-image: url("../img/table-deny.png");
}
.chat-con {
  background: #ebebeb;
  border-radius: 5px;
  padding: 20px;
}
.chat-info {
  display: flex;
  padding-bottom: 12px;
  border-bottom: 2px solid #c7c7c7;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.chat-info li {
  font-size: 12px;
  color: #666;
  margin-right: 16px;
}
.chat-info li label {
  font-weight: 500;
  margin-right: 8px
}
.chat-info li em {
  color: black;
}
.cmessages {
  padding: 12px 0;
  overflow: auto;
  max-height: 350px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.cmessages li {
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0,0,0,.1);
}
.cmessages li::after {
  content: "";
  position: absolute;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.cmessages li p {
  margin-bottom: 12px;
}
.cmessages li p:last-child {
  margin-bottom: 0;
}
.cmessages li.out {
  background: #4e8bff;
  color: white;
  margin-left: 25%;
}
.cmessages li.out::after {
  border-top: 7px solid #4e8bff;
  right: 20px;
}
.cmessages li.in {
  background: white;
  color: #666;
  margin-right: 25%;
}
.cmessages li.in::after {
  border-top: 7px solid white;
  left: 20px;
}
.cmessages li date {
  display: block;
  font-weight: bold;
  opacity: .7;
  text-align: right;
  font-size: 12px;
}
.cmessages li.sta {
  background: rgba(0,0,0,.5);
  color: #ddd;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
  margin-bottom: 12px;
}
.cmessages li.sta strong {
  color: white;
  font-weight: bold;
}
.cmessages li.sta em {
  color: white;
}
.cmessages li .user {
  position: absolute;
  bottom: -30px;
  background: #888;
  color: white;
  border-radius: 3px;
  padding: 0 12px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
}
.cmessages li.in .user {
  left: 0;
}
.cmessages li.out .user {
  right: 0;
}
.chat-form {
  margin: 0px -20px -20px -20px;
  padding: 20px 20px 20px;
  background: #ddd;
  border-top: 2px solid #c7c7c7;
  border-radius: 0px 0px 5px 5px;
}
.chat-form form {
  display: flex;
  justify-content: space-between;
}
.chat-form form textarea {
  border: 0;
  -webkit-apperance: none;
  outline: none;
  background: white;
  resize: resize-y;
  width: calc(100% - 200px);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px;
  min-height: 120px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.chat-form .chf-act {
  width: 180px;
}
.chat-form .chf-act button.main {
  display: block;
  margin-bottom: 12px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background: #53ad3b;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  height: 36px;
  text-transform: uppercase;
  transition: .3s;
}
.chat-form .chf-act button {
  display: block;
  margin-bottom: 8px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background: #777;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  height: 36px;
  text-transform: uppercase;
  transition: .3s;
}
.chat-form .chf-act button:hover {
  background: #666;
}
.chat-form .chf-act button.main:hover {
  background: #489026;
}
.chat-form .chf-act button.main::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url("../img/send.png") no-repeat 50% 50%;
  background-size: contain;
  margin-top: -2px;
  filter: invert(1);
}
.chat-form .chf-act button.main {
  display: block;
  margin-bottom: 12px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background: #53ad3b;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  height: 36px;
  text-transform: uppercase;
}
.chat-form .ch-act a {
  display: block;
  padding: 0 24px;
  border: 0;
  border-radius: 5px;
  background: #666;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  transition: .3s;
  margin-right: 12px;
  line-height: 30px;
}
.chat-form .ch-act a:hover {
  background: #555;
}
.ch-act {
  margin: 12px 0 0;
  display: flex;
  align-items: center;
}
.ch-act label {
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
}
.sup-ticket {
  display: flex;
  align-items: space-between;
  font-family: "Montserrat", sans-serif;
  padding: 55px 60px;
  align-items: flex-start;
}
.sup-ticket h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.sup-ticket form {
  flex-grow: 1;
}
.sup-ticket form .sf-row2 {
  display: flex;
  justify-content: space-between;
}
.sup-ticket form .sf-row2 .sf-row {
  width: 48%;
}
.sup-ticket form .sf-row {
  margin-top: 22px;
}
.sup-ticket form label {
  font-size: 14px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}
.sup-ticket form select,
.sup-ticket form input {
  background: white;
  box-sizing: border-box;
  height: 43px;
  border: 1px solid #b1b1b1;
  padding: 0 12px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
}
.sup-ticket form textarea {
  box-sizing: border-box;
  min-height: 180px;
  resize: resize-y;
  border: 1px solid #b1b1b1;
  padding: 6px 12px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
}
.sup-ticket form button {
  height: 42px;
  width: 242px;
  border: 0;
  border-radius: 3px;
  color: white;
  background: #1f9400;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.sup-ticket .faq-box {
  width: 360px;
  flex-shrink: 0;
  margin-left: 50px;
  padding: 40px 35px 35px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.sup-ticket .faq-box::before {
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3c9835+0,4682e7+100 */
  background: rgb(60,152,53); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(60,152,53,1) 0%, rgba(70,130,231,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(60,152,53,1) 0%,rgba(70,130,231,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(60,152,53,1) 0%,rgba(70,130,231,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c9835', endColorstr='#4682e7',GradientType=1 ); /* IE6-9 */
  position: absolute;
  display: block;
  top: -50px;
  left: -50px;
  right: -50px;
  bottom: -50px;
  transform-origin: 50% 50%;
  transform: rotate(10deg);
}
.sup-ticket .faq-box>div {
  text-align: center;
  color: white;
  position: relative;
}
.sup-ticket .faq-box .fq-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 24px;
}
.sup-ticket .faq-box .fq-btn {
  display: inline-block;
  width: 190px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background: #69ac5e;
  border-radius: 4px;
}
