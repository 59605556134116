.check-form .element-toggle {
  font-size: 13px;
}
.check-form .element-toggle input[type="radio"] {
  display: none;
}
.check-form .element-toggle label {
  margin-right: 10px;
}
.check-form .element-toggle span {
  display: inline-block;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background: white;
  vertical-align: middle;
  margin-right: 4px;
  border: 1px solid #d1d1d1;
  position: relative;
}
.check-form .element-toggle .checked span::before {
  content: "";
  width: 9px;
  height: 9px;
  top: 3px;
  left: 3px;
  display: block;
  position: absolute;
  background: black;
}

.web-check-details .check-result,
.check-form .check-field {
  display: flex;
  background: white;
  border: 1px solid #ccc;
  padding: 4px 8px;
  margin-top: 5px;
}
.web-check-details .check-result {
  flex-wrap: wrap;
}
.web-check-details .check-result>.val {
  width: 30%;
  text-align: center;
  font-weight: bold;
}
.web-check-details .check-result>.note {
  width: 100%;
  margin: 5px -8px -4px;
  padding: 5px 8px 4px;
  background: #eee;
  font-weight: bold;
}
.web-check-details .check-result>label,
.check-form .check-field>label {
  width: 65%;
  margin-right: 5%;
  font-weight: bold;
}
.web-check-details .check-result>label span,
.check-form .check-field>label span {
  font-weight: normal;
}

.check-form .check-desc {
  margin-top: -1px;
}
.check-form .check-desc textarea {
  background: white;
  border: 1px solid #ccc;
  padding: 4px 8px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
}
.check-form .actions {
  margin-top: 15px;
}

.status-success,
.status-error {
  margin: 20px -5px 0;
  padding: 5px 5px;
}

.status-success {
  background: #48cc48;
}

.status-error {
  background: #ff4848;
}
