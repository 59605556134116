.kycg2 {
  padding: 10px;
  h4 {
    font-weight: bold;
    margin-top: 1em;
  }
  ul {
    margin-top: 0.3em;
    &.messagesx {
      li {
        margin-top: 0.3em;
        border-left: 3px solid black;
        padding-left: 6px;
        &.info { border-color: #007bff }
        &.warn { border-color: #ffc107 }
        &.ok {   border-color: #28a745 }
        &.crit { border-color: #dc3545 }
      }
    }
  }
}
