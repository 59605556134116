.t2precheck {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.t2precheck .t2sect {
  width: calc(50% - 15px);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}
.t2precheck .t2box {
  padding: 0 3px 3px;
  background: white;
  width: 100%;
  box-shadow: 1px 1px 3px rgba(0,0,0,.1);
  margin-top: 8px;
  opacity: 0;
}
.t2precheck .t2box.visible {
  opacity: 1;
  transition: .3s;
}
.t2precheck .t2box ul h4,
.t2precheck .t2box ul li {
  opacity: 0;
}
.t2precheck .t2box ul h4.visible,
.t2precheck .t2box ul li.visible {
  opacity: 1;
  transition: .3s;
}
.t2precheck .t2box:first-child {
  margin-top: 0;
}
.t2precheck .t2box:last-child {
  flex-grow: 1;
}
.t2precheck .t2box h3 {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: bold;
  background: black;
  color: white;
  padding: 2px;
  margin: 0 -2px;
}
.t2precheck .t2box .empty {
  background: yellow;
}
.t2precheck .t2box label {
  color: #999;
}
