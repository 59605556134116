.provider-form {
  display: flex;
  flex-wrap: wrap;
}

.provider-form .input-group {
  margin: 10px;
}

.provider-form .input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  width: 100%;
}

.services {
  display: flex;
  .input-group {
    margin: 10px;
    .element-toggle input{
      vertical-align: middle;
    }
  }
}

.services-text {
  font-size: 18px;
  font-weight: bold;
}

.services-subtitle {
  font-weight: bold;
}

.services-input-group {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: space-between;
  align-items: center;
}

.services-input-group label {
  margin-right: 10px;
}

.services-input-group .switch {
  width: 60%;
}

.settings-text {
  font-size: 20px;
  font-weight: bold;
}

.settings-subtitle {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.representative-signature {
  border-radius: 3px;
  padding: 10px;
  background: white;
  margin: 0px 0 8px;
  max-width: 130px;
  box-sizing: border-box;
  border: 2px solid #ada;
  display: inline-block;
}

.new-btn {
  text-align: right;
}

.f2split .validator {
  width: 66%;

  input {
    width: 100%;
  }

  select {
    width: 100%;
  }
}

.system-information .validator {
  width: 100%;

  input {
    width: 100%;
  }
}

.partner-form {
  display: flex; 
  align-items: flex-start;
  padding: 50px;
  min-height: 200px;
}

.partner-form .partner-item {
  margin-right: 20px;
}

.partner-item a {
  display: block;
  line-height: 30px !important;
  height: 30px !important;
}

.partner-item button {
  display: block;
  line-height: 30px !important;
  height: 30px !important;
}

.partner-item input {
  width: 100%;
}

.partner-input {
  margin-top: 10px;
}

.partner-input input {
  width: 100%;
}

.auto-height {
  height: auto !important;
  align-items: center;
}

