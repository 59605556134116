.comset {
  border-radius: 5px;
  overflow: hidden;
}
.comset .heading {
  color: white;
  background: black;
  line-height: 23px;
  padding: 10px 18px;
  font-size: 16px;
}
.cm-split {
  display: flex;
  font-size: 14px;
}
.cm-split>div {
  width: 50%;
}
.comset .head {
  background: #4f85e4;
  color: white;
  padding: 10px 12px;
  line-height: 20px;
}
.comset .cm-table {
  background: white;
  color: #868686;
  font-size: 12px;
}
.comset .cm-table>div {
  line-height: 26px;
  padding: 5px 10px;
  display: flex;
}
.comset .cm-table .cmr-split {
  justify-content: space-between;
}
.comset .cm-table .cmr-split>div {
  width: 49%;
}
.comset .cm-table .cm-row {
  display: flex;
}
.comset .cm-table .cm-badd {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.comset .cm-table .cm-badd>input {
  width: 45px;
}
.comset .cm-table .cm-badd>input,
.comset .cm-table .cm-row>input {
  flex-grow: 1;
  border: none;
  background: yellow;
  background: transparent;
  height: 26px;
  display: block;
  min-width: 30px;
}
.comset .cm-table .cm-row>label {
  flex-shrink: 0;
  display: block;
  margin-right: 8px;
}
.comset .cm-table .cm-row label input {
  display: none;
}
.comset .cm-table .cm-row label span {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid #337dff;
  margin-top: -2px;
  margin-right: 8px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.comset .cm-table .cm-row label span.spacer {
  border-color: transparent;
}
.comset .cm-table .cm-row label input[type="radio"]:checked+span::before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background: #337dff;
  border-radius: 1px;
}
.comset .cm-table .cm-row label input[type="checkbox"]:checked+span::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 6px;
  border-bottom: 2px solid #009025;
  border-left: 2px solid #009025;
  box-sizing: border-box;
  left: 2px;
  top: 3px;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
}
.comset .cm-table>div:nth-of-type(2n+1) {
  background: #ececec;
}
.comset .select {
  position: relative;
  height: 24px;
  border: 1px solid #c7c7c7;
  background: #f4f4f4;
  line-height: 22px;
/*   min-width: 72px; */
  box-sizing: border-box;
}
.comset .select span {
  display: block;
  width: calc(100% - 22px);
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 3px;
}
.comset .select i {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 20px;
  width: 20px;
  background: #4f85e4;
}
.comset .select i::before {
  content: "";
  position: absolute;
  border-top: 3px solid white;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  top: calc(50% - 1px);
  left: calc(50% - 3px);
}
.comset .select select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.comset .cm-table .cm-row.tz .select {
  width: 76px;
}
.comset .cm-table .cm-row.bh .select span,
.comset .cm-table .cm-row.tz .select span {
  text-align: center;
}
.comset .cm-table .cm-row.bh>label {
  width: 92px;
}
.comset .cm-table .cm-row.bh .select {
  width: 50px;
}
.comset .logo-upload {
  flex-grow: 1;
  display: flex;
}
.comset .logo-upload .img {
  margin: 0 12px;
}
.comset .logo-upload .img img {
  max-height: 30px;
}
.comset .logo-upload .up {
  position: relative;
  width: 30px;
  height: 20px;
  margin-top: 5px;
  background: #4f85e4;
}
.comset .logo-upload .up::before {
  background: url("../img/upload.svg") no-repeat 50% 50%;
  background-size: contain;
  filter: brightness(0) invert(1);
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
}
.comset .logo-upload .up input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: hand;
}
.comset .cp-table {
  display: flex;
  flex-direction: column;
  color: #7e7e7e;
}
.comset .cp-table .cpt-row {
  height: 138px;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid #8b8b8b;
  border-top: 0px none;
  border-right: 0px none;
}
.comset .cp-table .cpt-row:last-of-type {
  border-bottom: 0px none;
  height: 139px;
}
.comset .cp-table .cpt-row .toggle {
  width: 70px;
  box-sizing: border-box;
  padding-top: 21px;
  text-align: center;
  border-right: 1px solid #8b8b8b;
  position: relative;
  flex-shrink: 0;
}
.comset .cp-table .cpt-row .toggle input {
  display: none;
}
.comset .cp-table .cpt-row .toggle label {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}
.comset .cp-table .cpt-row .toggle label span {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid #337dff;
  margin-top: -2px;
  margin-right: 8px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.comset .cp-table .cpt-row .toggle input[type="checkbox"]:checked+span::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 6px;
  border-bottom: 2px solid #009025;
  border-left: 2px solid #009025;
  box-sizing: border-box;
  left: 2px;
  top: 3px;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
}
.comset .cp-table .cpt-row .cptr-content {
  flex-grow: 1;
  padding: 10px 12px;
  background: #ececec;
}
.comset .cp-table .cpt-row .cptr-content textarea {
  resize: none;
  height: 90px;
  min-height: 90px;
}
.comset .cm-submit {
  background: #bfbfbf;
  padding: 10px;
  text-align: right;
}
.comset .cm-submit button {
  -webkit-apperance: none;
  border: 0 none;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  width: 330px;
  height: 54px;
  background: #357aba;
}
